import React, { useEffect, useState } from "react";
// import CryptoJS from "crypto-js";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
// import { setCompanyDetails } from "../../redux/actions/company.actions";
import RestaurantSidebar from "../layouts/RestaurantSidebar";
import webServices from "../../services/webServices";

// const secretKey = 'your-256-bit-secret-key';

function DesiredServices({
  companyDetails,
  handleSubmit,
  loading,
  loadingDetails,
  stepValue,
  setStep
}) {
  const [perHotel, setPerHotel] = useState(0);
  const [maxPerMonth, setMaxPerMonth] = useState(0);
  const [onlineReservationPrice, setOnlineReservationPrice] = useState(0);
  const [lightSpeedPosId, setLightSpeedPosId] = useState("");

  const [checkboxes, setCheckboxes] = useState({
    featuredListing: true,
    onlineReservations: false,
    onlineOrdering: false,
  });

  // const encryptId = (id) => {
  //   const key = CryptoJS.enc.Utf8.parse(secretKey);
  //   const iv = CryptoJS.lib.WordArray.random(16); // Generate a random IV
  //   const encrypted = CryptoJS.AES.encrypt(id.toString(), key, { iv: iv });

  //   // Combine IV and encrypted string (IV needs to be passed along for decryption)
  //   const combined = iv.concat(encrypted.ciphertext);

  //   // Encode to base64 for transmission
  //   return CryptoJS.enc.Base64.stringify(combined);
  // };

  useEffect(() => {
    const { desired_services } = companyDetails || {};
    setCheckboxes({
      ...checkboxes,
      onlineReservations: desired_services?.onlineReservations,
      onlineOrdering: desired_services?.onlineOrdering,
    });
  }, [companyDetails]);

  useEffect(() => {
    async function fetchData() {
      // get plans
      const resp = await webServices.getPlans({
        type: "restaurant",
      });
      if (resp?.type === "success") {
        setPerHotel(resp?.payload?.["Per Hotel"].amount || 0);
        setMaxPerMonth(resp?.payload?.["Max Hotel Price"].amount || 0);
        setOnlineReservationPrice(
          resp?.payload?.["Online Reservation"].amount || 0
        );
      }
    }
    fetchData();
  }, []);

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setCheckboxes({ ...checkboxes, [name]: checked });
  };

  const handleSubmitForm = async (event) => {
    event.preventDefault();
    const isAtLeastOneSelected = Object.values(checkboxes).some(
      (checked) => checked
    );
    if (isAtLeastOneSelected) {
      const data = {
        profileLavel:
          companyDetails?.profile_lavel > 1 ? companyDetails?.profile_lavel : 1,
        desiredServices: checkboxes,
        lightSpeedPosId: lightSpeedPosId,
      };
      handleSubmit(data, stepValue);
    } else {
      toast.dismiss();
      toast.error("Please select at least one desired option");
    }
  };

  // const decryptId = (ciphertext) => {
  //   const bytes = CryptoJS.AES.decrypt(ciphertext, secretKey);
  //   return bytes.toString(CryptoJS.enc.Utf8);
  // };

  return (
    <>
      <div className="restaurant-desired-services py-4">
        <div className="container">
          <div className="row">
            <div className="col-md-4 col-lg-3 mb-4">
              <RestaurantSidebar
                loadingDetails={loadingDetails}
                setStep={setStep}
              />
            </div>
            <div className="col-md-8 col-lg-9">
              <div className="common-info-card">
                <div className="heading-title">
                  Select Your Desired Services:
                </div>
                <div className="details-wrapper">
                  <div className="fs-18 mb-3">Welcome Restaurant Owner</div>
                  <p className="mb-3">
                    Touch-iT has been specifically designed to drive customers
                    to your restaurant for a fraction of the cost of other
                    platforms.
                  </p>
                  <p className="fs-18 mb-3">
                    Please select your desired options from the list below -
                  </p>
                  <div action="">
                    <div className="row">
                      <div className="col-12 d-flex gap-3 mb-3">
                        <input
                          type="checkbox"
                          className="custom-radio1"
                          name="featuredListing"
                          id="africanCuisine"
                          value="featuredListing"
                          checked={checkboxes.featuredListing}
                          onChange={handleCheckboxChange}
                          disabled
                        />
                        <label htmlFor="africanCuisine">
                          <div className="fs-20 fw-semibold text-custom-primary">
                            Featured Listing:
                          </div>
                          <div>
                            <i>
                              For just ${perHotel} per hotel per month, you will
                              receive a featured listing on our Touch-It
                              Interactive Kiosks located in hotel foyers.
                              (Capped at ${maxPerMonth} per month)
                            </i>
                          </div>
                        </label>
                      </div>
                      <div className="col-12 d-flex gap-3 mb-3">
                        <input
                          type="checkbox"
                          className="custom-radio1"
                          name="onlineReservations"
                          id="frenchCuisine"
                          value="onlineReservations"
                          checked={checkboxes.onlineReservations}
                          onChange={handleCheckboxChange}
                        />
                        <label htmlFor="frenchCuisine">
                          <div className="fs-20 fw-semibold text-custom-primary">
                            Online Reservations:
                          </div>
                          <div>
                            <i>
                              For just ${onlineReservationPrice} per month you
                              will receive online reservations through our
                              exclusive partnership with SevenRooms Secure
                              Booking Platform.
                            </i>
                          </div>
                        </label>
                      </div>
                      <div className="col-12 d-flex gap-3">
                        <input
                          disabled
                          type="checkbox"
                          className="custom-radio1"
                          name="onlineOrdering"
                          id="japaneseCuisine"
                          value="onlineOrdering"
                          checked={checkboxes.onlineOrdering}
                          onChange={handleCheckboxChange}
                        />
                        <label htmlFor="japaneseCuisine">
                          <div className="fs-20 fw-semibold text-custom-primary">
                            Online Ordering:
                          </div>
                          <div>
                            <i>
                              Instead of paying 30% fees, receive both online
                              delivery and pickup orders through Lightspeed’s
                              Secure POS System for just 15%.
                            </i>
                          </div>
                        </label>
                      </div>
                    </div>

                    {checkboxes.onlineOrdering && (
                      <div
                        className="field-wrapper py-4"
                        style={{ maxWidth: "518px", marginLeft: "40px" }}
                      >
                        <div className="row d-flex flex-wrap align-items-center mb-4">
                          <div className="col-12 col-md-5">
                            <label
                              htmlFor="preferredSupplier"
                              className="fs-20 fw-semibold text-custom-primary mb-2 mb-lg-0"
                            >
                              Lightspeed POS ID:
                            </label>
                          </div>
                          <div className="col-12 col-md-6">
                            <input
                              type="text"
                              className="form-control"
                              id="preferredSupplier"
                              autoComplete="off"
                              onChange={(e) =>
                                setLightSpeedPosId(e.target.value)
                              }
                            />
                          </div>
                        </div>

                        <div className="wrapper">
                          <p className="mb-1">
                            <i>
                              By entering your Lightspeed POS information above
                              we are able to -
                            </i>
                          </p>
                          <p className="mb-1">
                            <i>1. Display your menu to potential customers.</i>
                          </p>
                          <p className="mb-1">
                            <i>
                              2. Send online orders directly to your Lightspeed
                              POS System.
                            </i>
                          </p>
                        </div>
                      </div>
                    )}

                    <div className="btn-wrapper text-center my-2">
                      <button
                        type="submit"
                        className="btn btn-custom"
                        onClick={handleSubmitForm}
                        disabled={loading || false}
                      >
                        {loading ? "Loading..." : "Submit"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DesiredServices;
