import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Select from "react-select";
import { State } from "country-state-city";
import { useLocation, useNavigate } from "react-router-dom";
import AdminSidebar from "../../../layouts/AdminSidebar";
import webServices from "../../../../services/webServices";
import AppConstants from "../../../../appConstants";
import LocationDetailsPage from "../../../common/LocationDetailsPage";

function UpdateAdminLocationDetails() {
  const navigate = useNavigate();
  const location = useLocation();
  const { activityId } = location.state || {};
  const [loading, setLoading] = useState(false);
  const [companyDetails, setCompanyDetails] = useState(false);

  useEffect(() => {
    if (!activityId) {
      navigate(AppConstants.routes.admin.activityEditListing);
    }

    async function fetchData() {
      const resp = await webServices.getTour(activityId);
      if (resp.type === "success") {
        setCompanyDetails(resp?.payload);
      }
    }
    fetchData();
  }, [activityId]);

  if (!activityId) {
    return null;
  }

  const handleSubmit = async (data, _) => {
    setLoading(true);
    const resp = await webServices.createTour(data);
    if (resp.type === "success") {
      setLoading(false);
      toast.success(`Updated successfully`);
      navigate(AppConstants.routes.admin.activityList);
    }
    setLoading(false);
  };

  return (
    <>
      <LocationDetailsPage
        SideBar={AdminSidebar}
        details={companyDetails}
        btnLabel="Update"
        handleSubmit={handleSubmit}
        loading={loading}
      />
    </>
  );
}

export default UpdateAdminLocationDetails;
