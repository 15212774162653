import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Row, Col, Button, Badge } from "react-bootstrap";
import {
  PencilSquare,
  // Trash,
  Plus,
  Eye,
  QuestionCircle,
} from "react-bootstrap-icons";
import AdminSidebar from "../../layouts/AdminSidebar";
import { authCall } from "../../../services";
import AppConstants from "../../../appConstants";
import AdsSreensListPopUp from "./AdsSreensListPopUp";
import VideoTutorialPopUp from "../VideoTutorialPopUp";
import waitingForAd from "../../../assets/images/waitingForAd.jpg";

const AdsListVideo = AppConstants.tutorial.adsList;

const OPTIONS = [
  { value: "All", label: "All" },
  { value: "Waiting", label: "Waiting" },
  { value: "Active", label: "Active" },
  { value: "Paused", label: "Paused" },
];

function AdsList() {
  const navigate = useNavigate();
  const { current } = useSelector((state) => state.user);
  const [adsList, setAdsList] = useState([]);
  const [initialAdsList, setInitialAdsList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showScreens, setShowScreens] = useState(false);
  const [screensList, setScreensList] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [videoURL, setVideoURL] = useState("");

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const resp = await authCall("getAds", {
        userId: current?.id,
      });
      if (resp?.type === "success") {
        const listData = resp?.payload;
        const sortedData = listData.sort((a, b) => b.status - a.status);
        setAdsList(sortedData);
        setInitialAdsList(sortedData);
        setLoading(false);
      }
    }
    fetchData();
  }, []);

  const handleEdit = (details) => {
    navigate(AppConstants.routes.admin.createAds, { state: { details } });
  };

  const handleViewReport = (item) => {
    navigate(AppConstants.routes.admin.adsReports, {
      state: { details: item },
    });
  };

  // const handleDelete = (el) => {
  //   toast.warning(
  //     <div>
  //       <p>Are you sure you want to delete?</p>
  //       <Button
  //         variant="success"
  //         className="btn-sm"
  //         onClick={() => deleteItem(el)}
  //       >
  //         Yes
  //       </Button>
  //       <Button
  //         variant="danger"
  //         className="btn-sm"
  //         style={{ marginLeft: "2px" }}
  //         onClick={closeToast}
  //       >
  //         No
  //       </Button>
  //     </div>,
  //     {
  //       position: "top-center",
  //       autoClose: false,
  //       closeOnClick: false,
  //       draggable: false,
  //       closeButton: false,
  //     }
  //   );
  // };

  // const deleteItem = async (el) => {
  //   const resp = await authCall("", {
  //     machineId: el.id,
  //   });
  //   if (resp?.type === "success") {
  //     closeToast();
  //     const updatedItems = adsList.filter((item) => item.id !== el.id);
  //     setAdsList(updatedItems);
  //     toast.success("Deleted successfully.");
  //   }
  // };

  // const closeToast = () => {
  //   toast.dismiss();
  // };

  const handleAdd = () => {
    navigate(AppConstants.routes.admin.createAds);
  };

  const viewScreens = (items) => {
    setScreensList(items);
    setShowScreens(true);
  };

  const viewContent = (url) => {
    setVideoURL(url)
  };

  const closeViewScreens = () => {
    setShowScreens(false);
  };

  // filter
  const handleChangeFilter = (selected) => {
    setSelectedOption(selected);
    if (selected?.label === "All") {
      setAdsList(initialAdsList);
    } else {
      const filteredData = initialAdsList.filter(
        (item) => item.status === selected?.value
      );
      setAdsList(filteredData);
    }
  };

  const handleClickTutorial = () => {
    setVideoURL(AdsListVideo);
  };

  const tutoralClose = () => {
    setVideoURL("");
  };

  return (
    <>
      <div className="update-machine-page common-table-design py-4">
        <div className="container custom-container">
          <div className="row">
            <div className="col-sm-4 col-lg-3 mb-4">
              <AdminSidebar />
            </div>
            <div className="col-sm-8 col-lg-9">
              <div className="common-info-card">
                <div className="heading-title d-flex flex-wrap justify-content-between gap-2">
                  <span>Ads List</span>
                  <span
                    className="cursor-pointer"
                    onClick={handleClickTutorial}
                  >
                    <QuestionCircle />
                  </span>
                </div>
                <div className="details-wrapper py-lg-4">
                  <Row className="align-items-center mb-4">
                    <Col xs={4}>
                      <span className="fs-sm">Filter by Status:</span>
                      <Select
                        className="text-body mt-1"
                        placeholder="Select Status"
                        value={selectedOption}
                        onChange={handleChangeFilter}
                        options={OPTIONS}
                      />
                    </Col>

                    <Col xs={8} className="text-end">
                      <Button
                        className="btn btn-custom btn-custom-sm fs-16"
                        onClick={handleAdd}
                      >
                        <Plus />
                        Create Ads
                      </Button>
                    </Col>
                  </Row>
                  <div className="table-responsive">
                    <table className="table table-borderless table-custom m-0">
                      <thead>
                        <tr>
                          <th scope="col">Id:</th>
                          <th scope="col">Ads:</th>
                          <th scope="col">Day of Weeks:</th>
                          <th scope="col">Time of Days:</th>
                          <th scope="col">Style:</th>
                          <th scope="col">Length:</th>
                          <th scope="col">Goal:</th>
                          <th scope="col">Plan:</th>
                          <th scope="col">Screens:</th>
                          <th scope="col">Status:</th>
                          <th scope="col">Action:</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!loading &&
                          (adsList.length > 0 ? (
                            adsList.map((el, index) => (
                              <tr key={index}>
                                <td scope="row">#{el.id}</td>
                                <td>
                                  <img
                                    src={waitingForAd}
                                    className="img-fluid1 rounded"
                                    alt="cropped"
                                    style={{
                                      width: 85,
                                      border: "1px solid #ddb12e",
                                      marginRight: 10,
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>
                                      viewContent(el?.final_ads_contents)
                                    }
                                  />
                                </td>
                                <td
                                  style={{ maxWidth: 135, textWrap: "balance" }}
                                >
                                  {el.day_of_week
                                    .map((day) => day.label)
                                    .join(", ")}
                                </td>
                                <td
                                  style={{ maxWidth: 110, textWrap: "balance" }}
                                >
                                  {el.time_of_day
                                    .map((day) => day.label)
                                    .join(", ")}
                                </td>
                                <td>{el.added_style}</td>
                                <td>{el.durations} Sec</td>
                                <td>{el.desired_plan}</td>
                                <td>${Math.floor(el.amount)}</td>
                                <td>
                                  <button
                                    type="button"
                                    className="btn btn-link fs-16 text-blue border-0 p-0"
                                    onClick={() => viewScreens(el.screens)}
                                  >
                                    Link
                                  </button>
                                </td>
                                <td>
                                  <div className="icon-container">
                                    <Badge bg={el.status_bg}>{el.status}</Badge>
                                  </div>
                                </td>
                                <td>
                                  <div className="icon-container">
                                    <Eye
                                      onClick={() => handleViewReport(el)}
                                      className="cursor-pointer"
                                      style={{
                                        color: "#ddb12e",
                                      }}
                                    />
                                    <PencilSquare
                                      onClick={() => handleEdit(el)}
                                      className="cursor-pointer"
                                      style={{
                                        color: "#ddb12e",
                                      }}
                                    />
                                    {/* <Trash
                                      onClick={() => handleDelete(el)}
                                      className="cursor-pointer"
                                      style={{
                                        color: "red",
                                      }}
                                    /> */}
                                  </div>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan={10} className="text-center">
                                No record found
                              </td>
                            </tr>
                          ))}
                        {loading && (
                          <tr>
                            <td colSpan={10} className="text-center">
                              Loading...
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AdsSreensListPopUp
        modalStatus={showScreens}
        handleClose={closeViewScreens}
        screenList={screensList}
        loading={false}
      />
      <VideoTutorialPopUp videoURL={videoURL} closeVideo={tutoralClose} />
    </>
  );
}

export default AdsList;
