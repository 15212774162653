import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import AdminSidebar from "../../layouts/AdminSidebar";
import webServices from "../../../services/webServices";
import AppConstants from "../../../appConstants";
import StopsPage from "../../common/StopsPage";

function UpdateStops() {
  const navigate = useNavigate();
  const location = useLocation();
  const { activityId } = location.state || {};
  const [loading, setLoading] = useState(false);
  const [fields, setFields] = useState([
    { id: 1, stopName: "", stopDescription: "" },
  ]);

  useEffect(() => {
    if (!activityId) {
      navigate(AppConstants.routes.admin.activityEditListing);
    }

    async function fetchData() {
      const resp = await webServices.getTour(activityId);
      if (resp.type === "success") {
        const { major_stops } = resp?.payload;
        if (major_stops) {
          setFields(major_stops);
        }
      }
    }
    fetchData();
  }, [activityId]);

  if (!activityId) {
    return null;
  }

  const handleSubmit = async (data, _) => {
    setLoading(true);
    const resp = await webServices.createTour({
      bookingId: activityId,
      majorStops: data.majorStops,
    });
    if (resp.type === "success") {
      setLoading(false);
      toast.success(`Updated successfully`);
      navigate(AppConstants.routes.admin.activityEditListing);
    }
    setLoading(false);
  };

  return (
    <>
      <StopsPage
        SideBar={AdminSidebar}
        majorStops={fields}
        btnLabel="Update"
        handleSubmit={handleSubmit}
        loading={loading}
      />
    </>
  );
}

export default UpdateStops;
