import React from "react";
import NightEvents from "../../../../assets/landing/icons/nightEvents.svg";
import frame from "../../../../../src/assets/images/icons/Frame.svg";
// import SampleVideo from "../../../../assets/videos/Sample.mp4";
import "./StepTwo.scss";

const StepTwo = () => {
  return (
    <div className="steptwo_wrapper mt-5 container">
      <div className="content-container">
        <div className="heading" style={{ fontFamily: "Arial Rounded MT Std", fontSize: "25px" }} id="stepTwoheading">STEP TWO - CREATE REZDY NEGOTIATED RATES</div>
        <div className="text-content text-center text-justify mt-3" style={{ fontSize: "17px", color: "#DADADA" }}>
          We have created a two-minute video that walks you through the process
          of creating your Rezdy Negotiated Rates, which  you can watch by
          clicking on the video on the left of the page. If you’re familiar with
          the process of setting up Negotiated Rates, then the important
          step is making sure to select the “Full Payment to Supplier” option
          during the setup.
        </div>

        <div className="video-container mt-5">
          <video width="100%" height="100%" controls>
            <source src="https://touch-it.com.au/tutorial_videos/add_agent_video2.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>

        <a
          href="https://rezdy.com"
          target="_blank"
          style={{ textDecoration: "none" }}
        >
          <button className="center-button">
            click here to open <span style={{ color: "#EEAD20" }}>Rezdy</span>{" "}
            <img src={frame} height={19} width={19} />
          </button>
        </a>

        <div className="text-content text-center text-justify">
          We utilize Stripe to collect payment, which is held for 48 hours to
          provide time for any refunds then is deposited directly into your bank
          account via Stripe Connected Accounts. This process is one hundred
          percent secure, and ensures that the clients credit card details
          remain secure at all times.
        </div>
      </div>
    </div>
  );
};

export default StepTwo;
