import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { useSelector } from "react-redux";
import AdminSidebar from "../../layouts/AdminSidebar";
import AppConstants from "../../../appConstants";
import { QuestionCircle } from "react-bootstrap-icons";
import webServices from "../../../services/webServices";
import { authCall } from "../../../services";
import { Bar, Line } from "react-chartjs-2";
import VideoTutorialPopUp from "../VideoTutorialPopUp";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import ChartPopUp from "../ChartPopUp";
// Register necessary components with ChartJS
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    y: {
      beginAtZero: true,
    },
  },
  layout: {},
};

const startDate = moment().subtract(27, "days").format("YYYY-MM-DD");
const endDate = moment().format("YYYY-MM-DD");
const DashboardVideo = AppConstants.tutorial.dashboard;
function ActivitiesDashboard() {
  const { current } = useSelector((state) => state.user);
  const [machineLocation, setMachingLocation] = useState([]);
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [chartType, setChartType] = useState("");
  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});

  const [dayEarning, setDayEarning] = useState(null);
  const [dayOfWeekEarning, setDayOfWeekEarning] = useState(null);
  const [hourEarning, setHourEarning] = useState(null);
  const [weekEarning, setWeekEarning] = useState([]);
  const [videoURL, setVideoURL] = useState("");

  // For Bootstrap Model
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const resp = await authCall("getMachineEarning", {
        companyType: "Activity",
        userId: current?.id,
      });
      if (resp?.type === "success") {
        setMachingLocation(resp?.payload);
        setLoading(false);
      }
      // day wise clicks
      const resp2 = await authCall("getDailyEarning", {
        startDate: moment().subtract(27, "days").format("YYYY-MM-DD"),
        endDate,
        companyType: "Activity",
        userId: current?.id,
      });
      if (resp2?.type === "success") {
        setDayEarning(resp2?.payload);
      }

      // day of week reveal clicks
      const resp3 = await authCall("getDayOfWeekEarning", {
        startDate: moment().subtract(30, "days").format("YYYY-MM-DD"),
        endDate,
        companyType: "Activity",
        userId: current?.id,
      });
      if (resp3?.type === "success") {
        setDayOfWeekEarning(resp3?.payload);
      }

      // per hours reveal clicks
      const resp4 = await authCall("getPerHoursEarning", {
        startDate: moment().subtract(30, "days").format("YYYY-MM-DD"),
        endDate,
        companyType: "Activity",
        userId: current?.id,
      });
      if (resp4?.type === "success") {
        setHourEarning(resp4?.payload);
      }

      // week reveal clicks
      const resp5 = await authCall("getWeekEarning", {
        startDate: moment().startOf("month").format("YYYY-MM-DD"),
        endDate,
        companyType: "Activity",
        userId: current?.id,
      });
      if (resp5?.type === "success") {
        setWeekEarning(resp5?.payload);
      }
    }
    fetchData();
  }, []);

  const filterChart = (title, Chart, chartData) => {
    setTitle(title);
    setShow(true);
    setChartType(Chart);
    setChartData(chartData);
  };

  const handleChangeFiter = async (startDate, endDate, type) => {
    if (type === "day") {
      const resp = await authCall("getDailyEarning", {
        startDate,
        endDate,
        companyType: "Activity",
        userId: current?.id,
      });
      if (resp?.type === "success") {
        setChartData(resp?.payload);
      }
    } else if (type === "week") {
      const resp = await authCall("getDayOfWeekEarning", {
        startDate,
        endDate,
        companyType: "Activity",
        userId: current?.id,
      });
      if (resp?.type === "success") {
        setChartData(resp?.payload);
      }
    } else {
      const resp = await authCall("getPerHoursEarning", {
        startDate,
        endDate,
        companyType: "Activity",
        userId: current?.id,
      });
      if (resp?.type === "success") {
        setChartData(resp?.payload);
      }
    }
  };

  const handleClickTutorial = () => {
    setVideoURL(DashboardVideo);
  };

  const tutoralClose = () => {
    setVideoURL("");
  };

  return (
    <>
      <div className="dashboard-page py-4">
        <div className="container custom-container">
          <div className="row">
            <div className="col-sm-4 col-lg-3 mb-4">
              <AdminSidebar />
            </div>
            <div className="col-sm-8 col-lg-9">
              <div className="common-info-card">
                <div className="heading-title lh-1 d-flex flex-wrap justify-content-between gap-2">
                  <span>Dashboard</span>
                  <span
                    className="cursor-pointer"
                    onClick={handleClickTutorial}
                  >
                    <QuestionCircle />
                  </span>
                </div>
                <div className="details-wrapper pt-3">
                  <div className="sub-title lh-sm mb-3 mb-lg-4">
                    Below you will see how your listing is preforming:
                  </div>

                  <div className="row pb-3">
                    <div className="col-lg-5">
                      <div className="chart-wrapper mb-4">
                        <div className="chart-cover p-3">
                          <div className="fw-medium mb-1">Earnings By Day</div>
                          {!dayEarning && (
                            <div
                              style={{
                                height: "136px",
                                width: "273px",
                                padding: "50px",
                                textAlign: "center",
                              }}
                            >
                              Loading...
                            </div>
                          )}
                          {dayEarning && (
                            <Line data={dayEarning} options={options} />
                          )}
                        </div>
                        <div className="btn-wrapper text-center mt-3">
                          <button
                            type="button"
                            className="btn btn-custom"
                            onClick={() =>
                              filterChart(
                                "Number of Drink Codes Reveals",
                                Line,
                                dayEarning
                              )
                            }
                          >
                            Select
                          </button>
                        </div>
                      </div>
                      <div className="chart-wrapper mb-4">
                        <div className="chart-cover p-3">
                          <div className="fw-medium mb-1">
                            Earnings By Days Of The Week
                          </div>
                          {!dayOfWeekEarning && (
                            <div
                              style={{
                                height: "136px",
                                width: "273px",
                                padding: "50px",
                                textAlign: "center",
                              }}
                            >
                              Loading...
                            </div>
                          )}
                          {dayOfWeekEarning && (
                            <Bar data={dayOfWeekEarning} options={options} />
                          )}
                        </div>
                        <div className="btn-wrapper text-center mt-3">
                          <button
                            type="button"
                            className="btn btn-custom"
                            onClick={() =>
                              filterChart(
                                "Total Reveals Days Of The Week",
                                Bar,
                                dayOfWeekEarning
                              )
                            }
                          >
                            Select
                          </button>
                        </div>
                      </div>
                      <div className="chart-wrapper mb-4">
                        <div className="chart-cover p-3">
                          <div className="fw-medium mb-1">
                            Earnings By Hour Of The Day
                          </div>
                          {!hourEarning && (
                            <div
                              style={{
                                height: "136px",
                                width: "273px",
                                padding: "50px",
                                textAlign: "center",
                              }}
                            >
                              Loading...
                            </div>
                          )}
                          {hourEarning && (
                            <Bar data={hourEarning} options={options} />
                          )}
                        </div>
                        <div className="btn-wrapper text-center mt-3">
                          <button
                            type="button"
                            className="btn btn-custom"
                            onClick={() =>
                              filterChart(
                                "Total Reveals Per Hour",
                                Bar,
                                hourEarning
                              )
                            }
                          >
                            Select
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-7 mb-4 mb-lg-0">
                      <div
                        className="table-responsive rounded-3"
                        style={{ border: "2px solid var(--theme-color1)" }}
                      >
                        <table
                          className="table theade-bg table-bordered m-0"
                          style={{ border: 0 }}
                        >
                          <thead>
                            <tr>
                              <th className="fs-md-18">Location</th>
                              <th className="fs-md-18 text-center">Earnings</th>
                            </tr>
                          </thead>
                          <tbody>
                            {!loading &&
                              (machineLocation.length > 0 ? (
                                machineLocation.map((el, index) => (
                                  <tr key={index}>
                                    <td>
                                      <div className="name fw-semibold">
                                        {el?.machine_details?.vanue_name}
                                      </div>
                                      <div className="fs-14">
                                        {el?.machine_details?.street_address},{" "}
                                        {el?.machine_details?.city},{" "}
                                        {el?.machine_details?.state}{" "}
                                        {el?.machine_details?.post_code}
                                      </div>
                                    </td>
                                    <td>
                                      <div className="fs-md-18 count fw-semibold text-center">
                                        ${el?.totalcount}
                                      </div>
                                    </td>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan={2} className="text-center">
                                    No record found
                                  </td>
                                </tr>
                              ))}
                            {loading && (
                              <tr>
                                <td colSpan={2} className="text-center">
                                  Loading...
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>

                      <div className="btn-wrapper text-center mt-4">
                        <Link to={AppConstants.routes.admin.createAds}>
                          <button type="button" className="btn btn-custom">
                            Run An Add
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div className="table-responsive table-bordered rounded-3 m-0">
                    <table className="table table-bordered m-0">
                      <thead>
                        <tr>
                          <th colSpan="2" className="fs-md-18 text-center">
                            1<sup className="fw-medium">st</sup> - 7
                            <sup className="fw-medium">th</sup>{" "}
                            {moment().format("MMM")}
                          </th>
                          <th colSpan="2" className="fs-md-18 text-center">
                            8<sup className="fw-medium">th</sup> - 15
                            <sup className="fw-medium">th</sup>{" "}
                            {moment().format("MMM")}
                          </th>
                          <th colSpan="2" className="fs-md-18 text-center">
                            15<sup className="fw-medium">th</sup> - 23
                            <sup className="fw-medium">rd</sup>{" "}
                            {moment().format("MMM")}
                          </th>
                          <th colSpan="2" className="fs-md-18 text-center">
                            24<sup className="fw-medium">th</sup> - 30
                            <sup className="fw-medium">th</sup>{" "}
                            {moment().format("MMM")}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          {weekEarning.map((el, index) => (
                            <>
                              <td key={index}>Total</td>
                              <td>${el.total}</td>
                            </>
                          ))}
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ChartPopUp
        handleClose={handleClose}
        modalStatus={show}
        title={title}
        Chart={chartType}
        data={chartData}
        options={options}
        handleChangeFiter={handleChangeFiter}
        startDt={startDate}
        endDt={endDate}
      />
      <VideoTutorialPopUp videoURL={videoURL} closeVideo={tutoralClose} />
    </>
  );
}

export default ActivitiesDashboard;
