import AppConstants from "../appConstants";
import {
  TourComponent,
  BookingCheckoutComponent,
  BookingSuccessComponent,
  TourBookingCalendarComponent,
  TourRezdyIntegrationComponent,
  TourCostPerPersonComponent,
  TourCompanyCategoryComponent,
  TourCompanyDetailsComponent,
  TourStopsComponent,
  TourLocationDetailsComponent,
  TourPaymentComponent,
  // RESTAURANT
  RestaurantEditListingComponent,
  DesiredServicesPageComponent,
  RestaurantCategoryPageComponent,
  RestaurantDetailsPageComponent,
  RestaurantMenuPageComponent,
  RestaurantPaymentPageComponent,
  NewRestaurantOptionsComponent,
  UpdateRestaurantDetailsComponent,
  UpdateRestaurantCategoryComponent,
  UpdateRestaurantMenuComponent,
  UpdateDesireServicesComponent,
  // END RESTARANT
  RestaurantComponent,
  MenuPreviewComponent,
  NightLifeCategoryComponent,
  VenueDetailsComponent,
  EventComponent,
  NightLifeComponent,
  EventDetailsComponent,
  NightlifePaymentComponent,
  DashboardComponent,
  SettingComponent,
  UpdateActivityPriceComponent,
  UpdateDetailsComponent,
  ChangeEmailComponent,
  ChangePasswordComponent,
  RunAnAddComponent,
  ListAdsComponent,
  SelectScreenComponent,
  UploadContentComponent,
  MenuListingComponent,
  UpdateMenuDetailsComponent,
  UpdateVenueDetailsComponent,
  EventListingComponent,
  UpdateEventDetailsComponent,
  // USER ADMIN - ACTIVITY
  ActivityEditListingComponent,
  ActivityCompanyTypeComponent,
  ActivityAdminActionComponent,
  UpdateCostPersonComponent,
  UpdateCompanyDetailsComponent,
  UpdateStopsComponent,
  UpdateLocationDetailsComponent,
  RestaurantActionComponent,
  MachineAddComponent,
  MachineListComponent,
  AdsSubscriptionPaymentComponent,
  AdsReportsComponent,
  ActivityActionComponent,
  OnboardCompleteComponent,
  // CHECKINGS
  EditListingComponent,
  NightlifeEventListingComponent,
  TagsListComponent,
  EventsAddComponent,
  EventsListComponent,
  UserListingComponent,
  UserAddComponent,
  /* activity */
  UpdateAdminCostPersonComponent,
  UpdateAdminCompanyDetailsComponent,
  UpdateAdminStopsComponent,
  UpdateAdminLocationDetailsComponent,
  UpdateAdminCompanyCategoryComponent,
  // END CHECKINGS
  // DESIGNER CONTENT
  ContentsAdsListComponent,
} from "../components";

export const indexRoutesTour = [
  {
    exact: true,
    path: "",
    name: "Tour",
    component: TourComponent,
  },
  {
    exact: true,
    path: AppConstants.routes.tour.bookingCheckout,
    name: "Tour Booking Checkout",
    component: BookingCheckoutComponent,
  },
  {
    exact: true,
    path: AppConstants.routes.tour.bookingSuccess,
    name: "Tour Booking Success",
    component: BookingSuccessComponent,
  },
  {
    exact: true,
    path: AppConstants.routes.tour.tourBookingCalendar,
    name: "Tour Booking Calendar",
    component: TourBookingCalendarComponent,
  },
  {
    exact: true,
    path: AppConstants.routes.tour.tourRezdyIntegration,
    name: "Tour Rezdy Integration",
    component: TourRezdyIntegrationComponent,
  },
  {
    exact: true,
    path: AppConstants.routes.tour.tourCostPersion,
    name: "Tour Cost per persion",
    component: TourCostPerPersonComponent,
  },
  {
    exact: true,
    path: AppConstants.routes.tour.tourCompanyCategory,
    name: "Tour company category",
    component: TourCompanyCategoryComponent,
  },
  {
    exact: true,
    path: AppConstants.routes.tour.tourCompanyDetails,
    name: "Tour company category",
    component: TourCompanyDetailsComponent,
  },
  {
    exact: true,
    path: AppConstants.routes.tour.tourStops,
    name: "Tour stops",
    component: TourStopsComponent,
  },
  {
    exact: true,
    path: AppConstants.routes.tour.tourLocatoinDetails,
    name: "Tour location details",
    component: TourLocationDetailsComponent,
  },
  {
    exact: true,
    path: AppConstants.routes.tour.tourPayments,
    name: "Tour payments",
    component: TourPaymentComponent,
  },
  {
    exact: true,
    path: AppConstants.routes.tour.createCompany,
    name: "Activity Actions",
    component: ActivityActionComponent,
  },
  {
    exact: true,
    path: AppConstants.routes.tour.onboardStatus,
    name: "Stripe onboard stauts",
    component: OnboardCompleteComponent,
  },
];

export const indexRoutesRestaurant = [
  {
    exact: true,
    path: AppConstants.routes.restaurant.restaurantCreate,
    name: "Restaurant Actions",
    component: RestaurantActionComponent,
  },
  {
    exact: true,
    path: AppConstants.routes.restaurant.desiredServices,
    name: "Restaurant desired services",
    component: DesiredServicesPageComponent,
  },
  {
    exact: true,
    path: AppConstants.routes.restaurant.restaurantCategory,
    name: "Restaurant category",
    component: RestaurantCategoryPageComponent,
  },
  {
    exact: true,
    path: AppConstants.routes.restaurant.restaurantDetails,
    name: "Restaurant details",
    component: RestaurantDetailsPageComponent,
  },
  {
    exact: true,
    path: AppConstants.routes.restaurant.restaurantMenu,
    name: "Restaurant menu",
    component: RestaurantMenuPageComponent,
  },
  {
    exact: true,
    path: AppConstants.routes.restaurant.restaurantPayment,
    name: "Restaurant payment",
    component: RestaurantPaymentPageComponent,
  },
  {
    exact: true,
    path: AppConstants.routes.restaurant.newRestaurantOption,
    name: "Restaurant payment",
    component: NewRestaurantOptionsComponent,
  },
  

  {
    exact: true,
    path: "",
    name: "Restaurant",
    component: RestaurantComponent,
  },
  {
    exact: true,
    path: AppConstants.routes.restaurant.menuPreview,
    name: "Restaurant menu preview",
    component: MenuPreviewComponent,
  },
];

export const indexRoutesNightLife = [
  {
    exact: true,
    path: "",
    name: "Night Life",
    component: NightLifeComponent,
  },
  {
    exact: true,
    path: AppConstants.routes.nightLife.nightLifeEventDetails,
    name: "Event details",
    component: EventDetailsComponent,
  },
  {
    exact: true,
    path: AppConstants.routes.nightLife.category,
    name: "Night life category",
    component: NightLifeCategoryComponent,
  },
  {
    exact: true,
    path: AppConstants.routes.nightLife.vanueDetails,
    name: "Vanue details",
    component: VenueDetailsComponent,
  },
  {
    exact: true,
    path: AppConstants.routes.nightLife.eventDetails,
    name: "Events",
    component: EventComponent,
  },
  {
    exact: true,
    path: AppConstants.routes.nightLife.payments,
    name: "Night life payments",
    component: NightlifePaymentComponent,
  },
];

export const indexRoutesAdmin = [
  {
    exact: true,
    path: "",
    name: "Dashboard",
    component: DashboardComponent,
  },
  {
    exact: true,
    path: AppConstants.routes.admin.dashboard,
    name: "Dashboard",
    component: DashboardComponent,
  },
  {
    exact: true,
    path: AppConstants.routes.admin.setting,
    name: "Settings",
    component: SettingComponent,
  },
  {
    exact: true,
    path: AppConstants.routes.admin.updateActivityPrice,
    name: "Update Cost Person",
    component: UpdateActivityPriceComponent,
  },
  {
    exact: true,
    path: AppConstants.routes.admin.updateDetails,
    name: "Update Details",
    component: UpdateDetailsComponent,
  },
  {
    exact: true,
    path: AppConstants.routes.admin.changeEmail,
    name: "Change Email",
    component: ChangeEmailComponent,
  },
  {
    exact: true,
    path: AppConstants.routes.admin.changePassword,
    name: "Settings",
    component: ChangePasswordComponent,
  },
  {
    exact: true,
    path: AppConstants.routes.admin.createAds,
    name: "Create Ads",
    component: RunAnAddComponent,
  },
  {
    exact: true,
    path: AppConstants.routes.admin.selectScreen,
    name: "Select Screen",
    component: SelectScreenComponent,
  },
  {
    exact: true,
    path: AppConstants.routes.admin.uploadContent,
    name: "Upload Content",
    component: UploadContentComponent,
  },
  {
    exact: true,
    path: AppConstants.routes.admin.adsSubscription,
    name: "Ads Subscription form",
    component: AdsSubscriptionPaymentComponent,
  },
  {
    exact: true,
    path: AppConstants.routes.admin.listAds,
    name: "List Ads",
    component: ListAdsComponent,
  },
  {
    exact: true,
    path: AppConstants.routes.admin.adsReports,
    name: "Ads Report",
    component: AdsReportsComponent,
  },
  {
    exact: true,
    path: AppConstants.routes.admin.breakfastMenu,
    name: "Breakfast menu",
    component: MenuListingComponent,
  },
  {
    exact: true,
    path: AppConstants.routes.admin.lunchMenu,
    name: "Lunch menu",
    component: MenuListingComponent,
  },
  {
    exact: true,
    path: AppConstants.routes.admin.dinnerMenu,
    name: "Dinner menu",
    component: MenuListingComponent,
  },
  // RESTAURANT - USER ADMIN
  {
    exact: true,
    path: AppConstants.routes.admin.restaurantEditListing,
    name: "Restaurant edit listing",
    component: RestaurantEditListingComponent,
  },
  {
    exact: true,
    path: AppConstants.routes.admin.updateRestaurantDetails,
    name: "Update Restaurant details",
    component: UpdateRestaurantDetailsComponent,
  },
  {
    exact: true,
    path: AppConstants.routes.admin.updateRestaurantCategory,
    name: "Update Restaurant category",
    component: UpdateRestaurantCategoryComponent,
  },
  {
    exact: true,
    path: AppConstants.routes.admin.updateRestaurantMenu,
    name: "Update Restaurant menu",
    component: UpdateRestaurantMenuComponent,
  },
  {
    exact: true,
    path: AppConstants.routes.admin.updateDesireServices,
    name: "Update Restaurant desire services",
    component: UpdateDesireServicesComponent,
  },

  // END RESTAURANT - USER ADMIN
  {
    exact: true,
    path: AppConstants.routes.admin.updateMenuDetails,
    name: "Update Menu details",
    component: UpdateMenuDetailsComponent,
  },
  {
    exact: true,
    path: AppConstants.routes.admin.updateVenueDtails,
    name: "Update Venue details",
    component: UpdateVenueDetailsComponent,
  },
  {
    exact: true,
    path: AppConstants.routes.admin.eventList,
    name: "Event List",
    component: EventListingComponent,
  },
  {
    exact: true,
    path: AppConstants.routes.admin.updateEventDtails,
    name: "Update Event Details",
    component: UpdateEventDetailsComponent,
  },
  {
    exact: true,
    path: AppConstants.routes.admin.activityEditListing,
    name: "Activity Edit Listing",
    component: ActivityEditListingComponent,
  },
  {
    exact: true,
    path: AppConstants.routes.admin.activityCompanyType,
    name: "Activity company type",
    component: ActivityCompanyTypeComponent,
  },
  {
    exact: true,
    path: AppConstants.routes.admin.actiityCreateCompany,
    name: "Activity Create",
    component: ActivityAdminActionComponent,
  },
  {
    exact: true,
    path: AppConstants.routes.admin.updateCostPerson,
    name: "Update Cost per perspn",
    component: UpdateCostPersonComponent,
  },
  {
    exact: true,
    path: AppConstants.routes.admin.updateCompanyDetails,
    name: "Update Company details",
    component: UpdateCompanyDetailsComponent,
  },
  {
    exact: true,
    path: AppConstants.routes.admin.updateStops,
    name: "Update Stops",
    component: UpdateStopsComponent,
  },
  {
    exact: true,
    path: AppConstants.routes.admin.updateLocationDetails,
    name: "Update Location Details",
    component: UpdateLocationDetailsComponent,
  },
  {
    exact: true,
    path: AppConstants.routes.admin.addMachine,
    name: "Add Machine",
    component: MachineAddComponent,
  },
  {
    exact: true,
    path: AppConstants.routes.admin.updateMachine,
    name: "Update Machine",
    component: MachineAddComponent,
  },
  {
    exact: true,
    path: AppConstants.routes.admin.listMachine,
    name: "Machine List",
    component: MachineListComponent,
  },
  {
    exact: true,
    path: AppConstants.routes.admin.archivedMachine,
    name: "Archived Machine List",
    component: MachineListComponent,
  },
  // CHECKINGS
  {
    exact: true,
    path: AppConstants.routes.admin.activityList,
    name: "Activity Listing",
    component: EditListingComponent,
  },
  {
    exact: true,
    path: AppConstants.routes.admin.nightLifeList,
    name: "Nightlife Listing",
    component: EditListingComponent,
  },
  {
    exact: true,
    path: AppConstants.routes.admin.restaurantList,
    name: "Restaurant Listing",
    component: EditListingComponent,
  },
  {
    exact: true,
    path: AppConstants.routes.admin.tags,
    name: "Tags Listing",
    component: TagsListComponent,
  },
  {
    exact: true,
    path: AppConstants.routes.admin.nightlifeEvents,
    name: "Nighlife event listing",
    component: NightlifeEventListingComponent,
  },
  {
    exact: true,
    path: AppConstants.routes.admin.addEvents,
    name: "Add Events",
    component: EventsAddComponent,
  },
  {
    exact: true,
    path: AppConstants.routes.admin.updateEvents,
    name: "Update Event",
    component: EventsAddComponent,
  },
  {
    exact: true,
    path: AppConstants.routes.admin.liveEvents,
    name: "Live Events Listing",
    component: EventsListComponent,
  },
  {
    exact: true,
    path: AppConstants.routes.admin.archivedEvents,
    name: "Archived Events Listing",
    component: EventsListComponent,
  },
  {
    exact: true,
    path: AppConstants.routes.admin.users,
    name: "User Listing",
    component: UserListingComponent,
  },
  {
    exact: true,
    path: AppConstants.routes.admin.userAdd,
    name: "User Add",
    component: UserAddComponent,
  },
  {
    exact: true,
    path: AppConstants.routes.admin.userUpdate,
    name: "User Update",
    component: UserAddComponent,
  },
  {
    exact: true,
    path: AppConstants.routes.admin.desigUploadContent,
    name: "Upload Ads Contends",
    component: ContentsAdsListComponent,
  },
  {
    exact: true,
    path: AppConstants.routes.admin.adminUpdateCostPerson,
    name: "Update cost per persion by admin",
    component: UpdateAdminCostPersonComponent,
  },
  {
    exact: true,
    path: AppConstants.routes.admin.adminUpdateCompanyDetails,
    name: "Update company details",
    component: UpdateAdminCompanyDetailsComponent,
  },
  {
    exact: true,
    path: AppConstants.routes.admin.adminUpdateStopDetails,
    name: "Update stop details",
    component: UpdateAdminStopsComponent,
  },
  {
    exact: true,
    path: AppConstants.routes.admin.adminUpdateLocationDetails,
    name: "Update location details",
    component: UpdateAdminLocationDetailsComponent,
  },
  {
    exact: true,
    path: AppConstants.routes.admin.adminUpdateCompanyCategory,
    name: "Update company category",
    component: UpdateAdminCompanyCategoryComponent,
  },
];
