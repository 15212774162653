import React from "react";
import { useSelector } from "react-redux";
import ActivitySidebar from "../../layouts/TourSidebar";
import CostPerPersonPage from "../../common/CostPerPersonPage";

function CostPerPerson({ handleSubmit, loading, stepValue, setStep }) {
  const companyDetails  = useSelector((state) => state.companyDetails);

  return (
    <>
      <CostPerPersonPage
        SideBar={ActivitySidebar}
        priceDataList={companyDetails?.price_options || []}
        setStep={setStep}
        btnLabel="Submit"
        handleSubmit={handleSubmit}
        loading={loading}
        stepValue={companyDetails?.variations ? 6 : stepValue}
        calanderType={companyDetails.booking_calendar}
      />
    </>
  );
}

export default CostPerPerson;
