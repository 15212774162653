import React from "react";
import { useSelector } from "react-redux";
import ActivitySidebar from "../../layouts/TourSidebar";
import ActivityDetailsPage from "../../common/ActivityDetailsPage";

function ActivityDetails({ handleSubmit, loading, stepValue, setStep }) {
  const companyDetails = useSelector((state) => state.companyDetails);

  return (
    <>
      <ActivityDetailsPage
        SideBar={ActivitySidebar}
        details={companyDetails}
        setStep={setStep}
        btnLabel="Submit"
        handleSubmit={handleSubmit}
        loading={loading}
        stepValue={companyDetails?.company_type === "Tour" ? 7 : stepValue}
      />
    </>
  );
}

export default ActivityDetails;
