import React, { useEffect, useState, useRef } from "react";
import SimpleReactValidator from "simple-react-validator";
import Select from "react-select";
import { useLocation } from "react-router-dom";
import { State } from "country-state-city";
import { handleNumericInput } from "../util/utils";
import { QuestionCircle } from "react-bootstrap-icons";
import VideoTutorialPopUp from "../admin/VideoTutorialPopUp";
import AppConstants from "../../appConstants";

const HOURS = Array.from({ length: 24 });
const LocationDetailsVideo = AppConstants.tutorial.locationDetails;

const customStyles = {
  control: (provided) => ({
    ...provided,
    border: "1px solid var(--theme-dark1) !important;",
    color: "var(--black)",
    height: 40,
    fontSize: 16,
    borderRadius: "var(--bs-border-radius)",
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 9999,
  }),
};

function LocationDetailsPage({
  details,
  handleSubmit,
  stepValue,
  setStep,
  SideBar,
  loading,
  btnLabel,
  // AddVariationsBtn,
  // DoneBtn
}) {
  const location = useLocation();
  const { pathname } = location;
  const validator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState();

  const [pickupLocations, setPickupLocations] = useState([]);
  const [values, setValues] = useState({
    locationDescription: "",
    address: {
      addressLine: "",
      postCode: "",
      city: "",
      state: "",
      countryCode: "",
      latitude: 0,
      longitude: 0,
    },
    timeBeforeDeparture: "",
    pickupDescription: "",
    pickupLocation: "",
  });
  const [states, setStates] = useState([]);
  const [videoURL, setVideoURL] = useState("");
  const showTutorialVideo = [
    AppConstants.routes.admin.updateLocationDetails,
    AppConstants.routes.tour.createCompany,
  ].includes(pathname);

  useEffect(() => {
    async function fetchData() {
      setValues({
        ...values,
        locationDescription: details?.location_description || "",
        address: details?.location || {},
        timeBeforeDeparture: details?.time_before_departure || "",
        pickupDescription: details?.pickup_description || "",
        pickupLocation: details?.pickup_location || "",
      });
    }
    fetchData();
  }, [details]);

  useEffect(() => {
    const data = State.getStatesOfCountry("AU");
    const options = data.map((s) => ({
      value: s.isoCode,
      label: s.name,
    }));
    setStates(options);
  }, []);

  const handleChangeState = (selected) => {
    setValues({ ...values, address: { ...values.address, state: selected } });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name.includes(".")) {
      const [parent, child] = name.split(".");
      setValues((prevValues) => ({
        ...prevValues,
        [parent]: {
          ...prevValues[parent],
          [child]: value,
        },
      }));
    } else {
      setValues((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
    }
  };

  const handleSubmitForm = async () => {
    if (validator.current.allValid()) {
      values.bookingId = details.id;
      values.pickupLocation = pickupLocations;
      if (stepValue) {
        values.profileLavel = stepValue;
      }
      handleSubmit(values, stepValue);
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };

  const handleChangeZipcode = (e) => {
    const inputValue = handleNumericInput(e.target.value);
    if (inputValue !== null && inputValue.length < 8) {
      setValues((prevValues) => ({
        ...prevValues,
        address: {
          ...prevValues.address,
          postCode: inputValue,
        },
      }));
    }
  };

  const handleClickTutorial = () => {
    setVideoURL(LocationDetailsVideo);
  };

  const tutoralClose = () => {
    setVideoURL("");
  };

  return (
    <>
      <div className="location-details-page py-4">
        <div className="container">
          <div className="row">
            <div className="col-md-4 col-lg-3 mb-4">
              <SideBar setStep={setStep} />
            </div>
            <div className="col-md-8 col-lg-9">
              <div className="common-info-card">
                <div className="heading-title d-flex flex-wrap justify-content-between gap-2">
                  <span>Please Enter Your Location Details</span>
                  <span
                    className="cursor-pointer"
                    onClick={handleClickTutorial}
                  >
                    {showTutorialVideo && <QuestionCircle />}
                  </span>
                </div>
                <div className="details-wrapper">
                  <div className="sub-title mb-3">
                    These details will allow your customers to find you
                  </div>
                  {details && (
                    <div className="custom-field-wrapperr">
                      <div className="info-cover mb-4 pb-2">
                        <div className="main-heading1 mb-1">
                          Activity Location:
                        </div>
                        <div className="main-heading2 text-white mb-1">
                          Description:
                        </div>
                        <div className="info-text mb-3">
                          Please provide a short description to help customers
                          to find your location
                        </div>
                        <input
                          type="text"
                          className="form-control mt-1"
                          autoComplete="off"
                          placeholder="Please enter a maximun of 50 characters"
                          value={values.locationDescription}
                          onChange={(e) => {
                            if (e.target.value.length < 51) {
                              setValues({
                                ...values,
                                locationDescription: e.target.value,
                              });
                            }
                          }}
                        />
                        <div className="main-heading2 fs-12 fw-light text-end mt-1">
                          <span id="charCount">
                            {values.locationDescription.length}
                          </span>
                          /50
                        </div>
                      </div>

                      <div className="info-cover mb-4">
                        <div className="main-heading1 mb-2">Address:</div>
                        <div className="address-wrapper">
                          <div className="row">
                            <div className="col-sm-12">
                              <div className="form-group">
                                <label className="fw-normal" htmlFor="address">
                                  Address
                                </label>
                                <input
                                  type="text"
                                  className="form-control rounded-pill-"
                                  placeholder=""
                                  autoComplete="off"
                                  value={values?.address?.addressLine}
                                  name="address.addressLine"
                                  onChange={handleChange}
                                />
                                {validator.current.message(
                                  "address",
                                  values?.address?.addressLine,
                                  "required"
                                )}
                              </div>
                            </div>
                            {/* <div className="col-sm-12">
                            <div className="form-group">
                              <label
                                className="fw-normal"
                                htmlFor="streetAddress"
                              >
                                Street Address
                              </label>
                              <input
                                type="text"
                                className="form-control rounded-pill"
                                placeholder=""
                                autoComplete="off"
                                disabled
                              />
                            </div>
                          </div> */}
                            <div className="col-sm-6 col-md-4">
                              <div className="form-group">
                                <label className="fw-normal" htmlFor="city">
                                  City
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder=""
                                  autoComplete="off"
                                  value={values?.address?.city}
                                  name="address.city"
                                  onChange={handleChange}
                                />
                                {validator.current.message(
                                  "city",
                                  values?.address?.city,
                                  "required"
                                )}
                              </div>
                            </div>
                            <div className="col-sm-6 col-md-4">
                              <div className="form-group">
                                <label className="fw-normal" htmlFor="state">
                                  State
                                </label>
                                {/* <input
                                type="text"
                                className="form-control"
                                placeholder=""
                                autoComplete="off"
                                value={values?.address?.state}
                                name="address.state"
                                onChange={handleChange}
                              /> */}
                                <Select
                                  className="text-body"
                                  styles={customStyles}
                                  placeholder="Select State"
                                  value={values?.address?.state}
                                  name="address.state"
                                  onChange={handleChangeState}
                                  options={states}
                                />
                                {validator.current.message(
                                  "state",
                                  values?.address?.state,
                                  "required"
                                )}
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-group mb-2">
                                <label className="fw-normal" htmlFor="zipcode">
                                  Zip Code
                                </label>
                                <input
                                  type="number"
                                  className="form-control"
                                  placeholder=""
                                  autoComplete="off"
                                  max="6"
                                  value={values?.address?.postCode}
                                  name="address.postCode"
                                  onChange={handleChangeZipcode}
                                />
                                {validator.current.message(
                                  "zip code",
                                  values?.address?.postCode,
                                  "required"
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <hr className="divider my-4" />
                      {details?.pickupId && pickupLocations?.length > 0 && (
                        <>
                          <div className="pickup-location-wrapper">
                            <div className="info-cover mb-4">
                              <div className="main-heading1 mb-2">
                                Pickup Location{" "}
                                <span className="fs-16 fw-normal text-white">
                                  <i>(Optional)</i>
                                </span>
                              </div>
                              <div className="d-flex flex-wrap align-items-center gap-2 mb-3">
                                <div className="main-heading2">
                                  Time Before Trip Departure{" "}
                                  <span className="fs-16 fw-normal text-white">
                                    <i>(Hours)</i>
                                  </span>
                                  :
                                </div>
                                <div
                                  className="select-cover"
                                  style={{ minWidth: "220px;" }}
                                >
                                  <select
                                    id="pickupLocation"
                                    className="form-select rounded-pill"
                                    onChange={(e) =>
                                      setValues({
                                        ...values,
                                        timeBeforeDeparture: e.target.value,
                                      })
                                    }
                                  >
                                    <option value="" hidden>
                                      Select
                                    </option>
                                    {HOURS.map((_, index) => (
                                      <option value={index + 1}>
                                        {index + 1}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </div>
                              <div className="fs-18 fw-semibold mb-1">
                                How long before the trip does the participant
                                need to be at this pickup location?
                              </div>
                              <p>
                                <i>
                                  For example if the trip departs at 9:00am and
                                  they need to be at this pickup location at
                                  8:00am, then select 1 hour
                                </i>
                              </p>
                            </div>
                            <div className="info-cover mb-3">
                              <div className="main-heading2 mb-1">
                                Description:
                              </div>
                              <div className="info-text fs-18 mb-3">
                                Please provide a short description to help
                                customers to find your pickup location
                              </div>
                              <textarea
                                id="overview"
                                rows="4"
                                className="form-control"
                                placeholder="Please provide a 75-to-150 word descripition of activity."
                                autoComplete="off"
                                onChange={(e) =>
                                  setValues({
                                    ...values,
                                    pickupDescription: e.target.value,
                                  })
                                }
                              ></textarea>
                            </div>
                            <div className="info-cover mb-4">
                              <div className="main-heading2 mb-2">
                                Pickup Location Address:
                              </div>
                              {pickupLocations.map((el) => (
                                <div className="address-wrapper mb-3">
                                  <div className="row">
                                    <div className="col-sm-12">
                                      <div className="form-group">
                                        <label
                                          className="fw-normal"
                                          htmlFor="address"
                                        >
                                          Address
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control rounded-pill"
                                          placeholder=""
                                          autoComplete="off"
                                          value={el?.locationName}
                                          disabled
                                        />
                                      </div>
                                    </div>
                                    <div className="col-sm-12">
                                      <div className="form-group">
                                        <label
                                          className="fw-normal"
                                          htmlFor="streetAddress"
                                        >
                                          Street Address
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control rounded-pill"
                                          placeholder=""
                                          autoComplete="off"
                                          value={el?.address}
                                          disabled
                                        />
                                      </div>
                                    </div>
                                    {el?.additionalInstructions && (
                                      <div className="col-sm-12">
                                        <div className="form-group">
                                          <label
                                            className="fw-normal"
                                            htmlFor="city"
                                          >
                                            Additional Instructions
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control rounded-pill"
                                            placeholder=""
                                            autoComplete="off"
                                            value={el?.additionalInstructions}
                                            disabled
                                          />
                                        </div>
                                      </div>
                                    )}
                                    {/* <div className="col-sm-6 col-md-4">
                                    <div className="form-group">
                                      <label className="fw-normal" htmlFor="city">
                                        City
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control rounded-pill"
                                        id="city"
                                        placeholder=""
                                        autoComplete="off"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-sm-6 col-md-4">
                                    <div className="form-group">
                                      <label className="fw-normal" htmlFor="state">
                                        State
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control rounded-pill"
                                        id="state"
                                        placeholder=""
                                        autoComplete="off"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-4">
                                    <div className="form-group">
                                      <label
                                        className="fw-normal"
                                        htmlFor="zipcode"
                                      >
                                        Zip Code
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control rounded-pill"
                                        id="zipcode"
                                        placeholder=""
                                        autoComplete="off"
                                        maxlength="6"
                                      />
                                    </div>
                                  </div> */}
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </>
                      )}
                      {/* <div className="dropoff-location-wrapper">
                          <div className="info-cover mb-4">
                            <div className="main-heading1 mb-2">
                              Dropoff Location{" "}
                              <span className="fs-16 fw-normal text-white">
                                <i>(Optional)</i>
                              </span>
                            </div>
                            <div className="d-flex flex-wrap align-items-center gap-2 mb-3">
                              <div className="main-heading2">
                                Time After Trip Arrival:
                              </div>
                              <div
                                className="select-cover"
                                style={{ minWidth: "220px;" }}
                              >
                                <select
                                  id="pickupLocation"
                                  className="form-select rounded-pill"
                                >
                                  <option value="" hidden>
                                    Select
                                  </option>
                                  <option value="Lucknow">Lucknow</option>
                                  <option value="Kanpur">Kanpur</option>
                                  <option value="Fatehpur">Fatehpur</option>
                                </select>
                              </div>
                            </div>
                            <div className="fs-18 fw-semibold mb-1">
                              How long after the trip will the participant be
                              dropped off at this location?
                            </div>
                            <p>
                              For example if the trip arrives at 5:00pm and they
                              will dropped off at 6:00pm, then select 1 hour
                            </p>
                          </div>

                          <div className="info-cover mb-3">
                            <div className="main-heading2 mb-1">
                              Description:
                            </div>
                            <div className="info-text fs-18 mb-3">
                              Please provide a short description to help
                              customers to find the drop off location
                            </div>
                            <textarea
                              id="overview"
                              rows="4"
                              className="form-control"
                              placeholder="Please provide a 75-to-150 word descripition of activity."
                              autoComplete="off"
                            ></textarea>
                          </div>

                          <div className="info-cover mb-4">
                            <div className="main-heading2 mb-2">
                              Dropoff Location Address:
                            </div>
                            <div className="address-wrapper">
                              <div className="row">
                                <div className="col-sm-12">
                                  <div className="form-group">
                                    <label className="fw-normal" htmlFor="address">
                                      Address
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control rounded-pill"
                                      id="address"
                                      placeholder=""
                                      autoComplete="off"
                                    />
                                  </div>
                                </div>
                                <div className="col-sm-12">
                                  <div className="form-group">
                                    <label
                                      className="fw-normal"
                                      htmlFor="streetAddress"
                                    >
                                      Street Address
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control rounded-pill"
                                      id="streetAddress"
                                      placeholder=""
                                      autoComplete="off"
                                    />
                                  </div>
                                </div>
                                <div className="col-sm-6 col-md-4">
                                  <div className="form-group">
                                    <label className="fw-normal" htmlFor="city">
                                      City
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control rounded-pill"
                                      id="city"
                                      placeholder=""
                                      autoComplete="off"
                                    />
                                  </div>
                                </div>
                                <div className="col-sm-6 col-md-4">
                                  <div className="form-group">
                                    <label className="fw-normal" htmlFor="state">
                                      State
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control rounded-pill"
                                      id="state"
                                      placeholder=""
                                      autoComplete="off"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="form-group">
                                    <label className="fw-normal" htmlFor="zipcode">
                                      Zip Code
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control rounded-pill"
                                      id="zipcode"
                                      placeholder=""
                                      autoComplete="off"
                                      maxlength="6"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="add-another fw-medium text-end mt-2">
                              <span
                                className="glyphicon glyphicon-plus"
                                aria-hidden="true"
                              ></span>{" "}
                              Add Another Dropoff Location
                            </div>
                          </div>
                        </div> */}

                      <div className="d-flex flex-wrap justify-content-center gap-3 gap-md-5 pt-2">
                        {/* {AddVariationsBtn && <AddVariationsBtn />} */}
                        <button
                          type="submit"
                          className="btn btn-custom"
                          onClick={handleSubmitForm}
                          disabled={loading || false}
                        >
                          {loading ? "Updating..." : btnLabel}
                        </button>
                        {/* {DoneBtn && <DoneBtn />} */}
                      </div>
                    </div>
                  )}
                  {!details && (
                    <div className="fs-18 text-center">Loading...</div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <VideoTutorialPopUp videoURL={videoURL} closeVideo={tutoralClose} />
    </>
  );
}

export default LocationDetailsPage;
