import { BookingConstants } from "../constants";

export const initialState = {
  bookingDetails: {},
  activityDetails: {},
};

const serviceReducer = (state = initialState, action) => {
  switch (action.type) {
    case BookingConstants.BOOKING_DETAILS:
      return { ...state, bookingDetails: action.payload };
    case BookingConstants.ACTIVITY_DETAILS:
      return { ...state, activityDetails: action.payload };
    default:
      return { ...state };
  }
};

export default serviceReducer;
