import React from "react";
import GoldCoastImg from "../../../../assets/images/site/GoldCoastImg.png";

import "./GoldCoast.scss";

const GoldCoast = () => {
  return (
    <div className="goldCoastBG">
      <div className="goldcoast_wrapper container">
        <div className="row mt-5 align-items-center" style={{ height: "100%" }}>
          <div className="col-12 col-lg-6 d-flex flex-column align-items-start mt-5">
            <div
              className="heading"
              style={{ fontFamily: "Arial Rounded MT Std", fontSize: "25px", zIndex:"5"}}
              id="goldcoastheading"
            >
              Exclusive to the Gold Coast
            </div>
            <div
              className="text-content mt-1"
              style={{ fontSize: "17px", color: "#DADADA", zIndex:"5"}}
            >
              Each kiosk is specifically programed to provide tourists with the
              information they need, at the touch of a button and has been built
              around the following key questions
              <div>
                <ul
                  className="mt-5"
                  style={{ fontSize: "20px", color: "#eead20", zIndex:"5"}}
                >
                  <li>What to do?</li>
                  <li>Where to eat?</li>
                  <li>What is on?</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6 d-flex flex-column justify-content-between gap-4 mt-5 mt-lg-0">
            <img
              src={GoldCoastImg}
              className="w mb-0"
              height={636}
              // width={482}
              alt="GoldCoastImg"
              style={{maxWidth:"482px", width:"100",zIndex:"5"}}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GoldCoast;
