import React from "react";
import MenuDetailPage from "../common/MenuDetailPage";
import RestaurantSidebar from "../layouts/RestaurantSidebar";
import webServices from "../../services/webServices";

function RestaurantMenu({
  companyDetails,
  handleSubmit,
  loading,
  loadingDetails,
  setStep,
  userId,
}) {

  const handleSubmitForm = async (data, val, act) => {
    data.menuType = val;
    const stepValue = act.type === "another" ? 1 : 5;
    const profileLavel = act.type === "another" ? 1 : 5;
    handleSubmit(data, stepValue, profileLavel);
  };

  const buttons = [
    {
      id: 1,
      label: "Add Anonter Menu",
      type: "another",
    },
    {
      id: 3,
      label: "Done",
      type: "done",
    },
  ];

  return (
    <>
      <MenuDetailPage
        onSubmit={handleSubmitForm}
        loading={loading}
        restaurantId={companyDetails?.id}
        type="Breakfast"
        heading="Please Create Your Menu"
        Sidebar={RestaurantSidebar}
        buttons={buttons}
        item={companyDetails}
        loadingDetails={loadingDetails}
        setStep={setStep}
      />
    </>
  );
}

export default RestaurantMenu;
