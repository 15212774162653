import React, { useRef } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { Modal } from "react-bootstrap"; // Assuming you're using Bootstrap

const CropperModal = ({
  show,
  image,
  handleClose,
  sendCropedDataToParent,
  aspectRation,
  croppedSize,
}) => {
  const cropperRef = useRef(null);

  const getCropData = () => {
    if (typeof cropperRef.current?.cropper !== "undefined") {
      const cropedFile = cropperRef.current?.cropper
        .getCroppedCanvas(croppedSize)
        .toDataURL("image/jpeg", 0.7);

      sendCropedDataToParent(cropedFile);
      handleClose();
    }
  };

  return (
    <>
      {/* {console.log(cropData)} */}
      {/* Modal */}
      <Modal
        show={show}
        centered
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
        dialogClassName="custom-modal"
      >
        <Modal.Header className="justify-content-center">
          <Modal.Title className="fw-bold">Crop Image</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Cropper
            // ref={cropperRef}
            // style={{ height: 400, width: "100%" }}
            // zoomTo={0.5}
            // initialAspectRatio={1.45 / 1}
            // preview=".img-preview"
            // src={image}
            // viewMode={1}
            // minCropBoxHeight={10}
            // minCropBoxWidth={10}
            // background={false}
            // responsive={true}
            // scalable={false}
            // autoCropArea={1}
            // checkOrientation={false}
            // guides={true}
            // cropBoxResizable={false}
            // cropBoxMovable={false}

            src={image}
            style={{ height: 400, width: "100%" }}
            initialAspectRatio={aspectRation}
            guides={true}
            autoCropArea={1}
            ref={cropperRef}
            scalable={false}
            zoomable={false}
            // cropBoxResizable={false}
            // cropBoxMovable={false}
          />

          <div className="btn-wrapper d-flex flex-wrap justify-content-center gap-3 mt-4">
            <button
              type="submit"
              className="btn btn-custom"
              onClick={getCropData}
            >
              Crop Image
            </button>
            <button
              type="reset"
              className="btn btn-custom"
              onClick={handleClose}
            >
              Cancel
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CropperModal;
