import React from "react";
import rectangle1 from "../../../../assets/images/site/Rectangle 14.png";
import NameIcon from "../../../../assets/images/site/NameIcon.png";
import ContactIcon from "../../../../assets/images/site/contactIcon.png";
import Hotelbooking1 from "../../../../assets/images/site/HotelBooking.png";
import EmailtIcon from "../../../../assets/images/site/EmailIcon.png";
import StopwatchIcon from "../../../../assets/images/site/EmailIcon.png";
import LocationtIcon from "../../../../assets/images/site/LocationIcon.png";
import "./Hotelbooking.scss";

const Hotelbooking = () => {
  return (
    <div
      className="hotelBookin_wrapper mt-5 container" id="claimkiosk"
      style={{ position: "relative" }}
    >
      <div className="gradient-line"></div>
      <img src={rectangle1} alt="Book Now" className="centered-image" />
      <div className="overlay-text" style={{ fontFamily: "Inter" }}>
        CLAIM YOUR KIOSK
      </div>
      <div className="contact-form-wrapper">
        <form className="contact-form mb-5" style={{margin:"0px 35px"}}>
          <div className="row mt-3">
            <div
              className="col-6 col-lg-6 d-flex flex-column align-items-center justify-content-between gap-4 mt-5"
              style={{ fontFamily: "Poppins", color: "#BABABA" }}
              id="contacttWrapper"
            >
              <div className="form-group ms-3" id="hotelform">
                <div className="input-with-icon">
                  <img
                    src={NameIcon}
                    alt="Name Icon"
                    className="input-icon"
                    height={31}
                    width={20}
                    style={{left:"3%"}}
                  />
                  <input
                    type="text"
                    id="firstName"
                    name="firstName"
                    placeholder="First Name"
                    required
                  />
                </div>
              </div>

              <div className="form-group ms-3" id="hotelform">
                <div className="input-with-icon">
                  <img
                    src={ContactIcon}
                    alt="Name Icon"
                    className="input-icon"
                    height={24}
                    width={20}
                    style={{left:"3%"}}
                  />
                  <input
                    type="number"
                    id="phoneNumber"
                    name="phoneNumber"
                    placeholder="Phone Number"
                    required
                  />
                </div>
              </div>
            </div>

            <div
              className="col-6 col-lg-6 d-flex flex-column align-items-center justify-content-between gap-4 mt-5"
              style={{ fontFamily: "Poppins", color: "#BABABA" }}
              id="contacttWrapper"
            >
              <div className="form-group ms-3" id="hotelform">
                <div className="input-with-icon">
                  <img
                    src={NameIcon}
                    alt="Name Icon"
                    className="input-icon"
                    height={31}
                    width={20}
                    style={{left:"3%"}}
                  />
                  <input
                    type="text"
                    id="lastName"
                    name="lastName"
                    placeholder="Last Name"
                    required
                  />
                </div>
              </div>
              <div className="form-group ms-3" id="hotelform">
                <div className="input-with-icon">
                  <img
                    src={EmailtIcon}
                    alt="Name Icon"
                    className="input-icon"
                    height={20}
                    width={20}
                    style={{left:"3%"}}
                  />
                  <input
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Email Address"
                    required
                  />
                </div>
              </div>
            </div>

            <div
              className="col-6 col-lg-6 d-flex flex-column align-items-center mt-4"
              style={{ fontFamily: "Poppins", color: "#BABABA" }}
              id="contacttWrapper"
            >
              <div className="form-group ms-3" id="hotelform">
                <div className="input-with-icon">
                  <img
                    src={Hotelbooking1}
                    alt="Name Icon"
                    className="input-icon"
                    height={26}
                    width={20}
                    style={{left:"3%"}}
                  />
                  <input
                    type="text"
                    id="Hotel"
                    name="Hotel"
                    placeholder="Hotel"
                    required
                  />
                </div>
              </div>
            </div>


            <div
              className="col-6 col-lg-6 d-flex flex-column align-items-center mt-4"
              style={{ fontFamily: "Poppins", color: "#BABABA" }}
              id="contacttWrapper"
            >
              <button className="hotelBooking ms-3" style={{width:"100%"}}>Claim Your Kiosk Now!</button>
            </div>
          </div>
        </form>
      </div>
      <div
        className="footer-text text-center mt-3"
        style={{ fontSize: "16px", color: "#323232" }}
      >
        copyright@2010-2024 Touch-iT.com All rights Reserved
      </div>
    </div>
  );
};

export default Hotelbooking;
