import React from "react";
import About from "../About/About";
import GoldCoast from "../GoldCoast/GoldCoast";
// import EarlyRegistration from "../EarlyRegistration/EarlyRegistration";
// import StepTwo from "../StepTwo/StepTwo";
import OurMachines from "../OurMachines/OurMachines";
import Vision from "../Vision/Vision";
import Register from "../Register/Register";
import NewBanner from "../NewBanner/NewBanner";
import Header from "../../homeLayout/Header";
// import LandingHeader from "../LandingHeader/LandingHeader";
import GoldCoast2 from "../GoldCoast2/GoldCoast2";

// import BookNow from "../BookNow/BookNow";

const LandingPage = () => {
  return (
    <div>
      <Header />

      {/* <LandingHeader /> */}
      <NewBanner />
      <About />
      <GoldCoast />
      <GoldCoast2 />
      <Register id="register" />
      {/* <EarlyRegistration />
      <BookNow />
      <StepTwo /> */}
      <OurMachines />
      <Vision />
    </div>
  );
};

export default LandingPage;
