import React, { useEffect, useState, useRef } from "react";
import SimpleReactValidator from "simple-react-validator";
import Select from "react-select";
import { State } from "country-state-city";
import ImageUploads from "../util/ImageUploads";
import BannerImagesUploads from "../util/BannerImagesUploads";
import { handleNumericInput } from "../util/utils";

const BREACKFASTDAYS = {
  Monday: {
    openningTime: "06:00",
    closingTime: "11:00",
    close: false,
  },
  Tuesday: {
    openningTime: "06:00",
    closingTime: "11:00",
    close: false,
  },
  Wednesday: {
    openningTime: "06:00",
    closingTime: "11:00",
    close: false,
  },
  Thursday: {
    openningTime: "06:00",
    closingTime: "11:00",
    close: false,
  },
  Friday: {
    openningTime: "06:00",
    closingTime: "11:00",
    close: false,
  },
  Saturday: {
    openningTime: "06:00",
    closingTime: "11:00",
    close: false,
  },
  Sunday: {
    openningTime: "06:00",
    closingTime: "11:00",
    close: false,
  },
};

const LUNCHDAYS = {
  Monday: {
    openningTime: "11:00",
    closingTime: "16:00",
    close: false,
  },
  Tuesday: {
    openningTime: "11:00",
    closingTime: "16:00",
    close: false,
  },
  Wednesday: {
    openningTime: "11:00",
    closingTime: "16:00",
    close: false,
  },
  Thursday: {
    openningTime: "11:00",
    closingTime: "16:00",
    close: false,
  },
  Friday: {
    openningTime: "11:00",
    closingTime: "16:00",
    close: false,
  },
  Saturday: {
    openningTime: "11:00",
    closingTime: "16:00",
    close: false,
  },
  Sunday: {
    openningTime: "11:00",
    closingTime: "16:00",
    close: false,
  },
};

const DINNERDAYS = {
  Monday: {
    openningTime: "16:00",
    closingTime: "22:00",
    close: false,
  },
  Tuesday: {
    openningTime: "16:00",
    closingTime: "22:00",
    close: false,
  },
  Wednesday: {
    openningTime: "16:00",
    closingTime: "22:00",
    close: false,
  },
  Thursday: {
    openningTime: "16:00",
    closingTime: "22:00",
    close: false,
  },
  Friday: {
    openningTime: "16:00",
    closingTime: "22:00",
    close: false,
  },
  Saturday: {
    openningTime: "16:00",
    closingTime: "22:00",
    close: false,
  },
  Sunday: {
    openningTime: "16:00",
    closingTime: "22:00",
    close: false,
  },
};

const ALLDAYS = {
  Monday: {
    openningTime: "06:00",
    closingTime: "22:00",
    close: false,
  },
  Tuesday: {
    openningTime: "06:00",
    closingTime: "22:00",
    close: false,
  },
  Wednesday: {
    openningTime: "06:00",
    closingTime: "22:00",
    close: false,
  },
  Thursday: {
    openningTime: "06:00",
    closingTime: "22:00",
    close: false,
  },
  Friday: {
    openningTime: "06:00",
    closingTime: "22:00",
    close: false,
  },
  Saturday: {
    openningTime: "06:00",
    closingTime: "22:00",
    close: false,
  },
  Sunday: {
    openningTime: "06:00",
    closingTime: "22:00",
    close: false,
  },
};

const customStyles = {
  control: (provided) => ({
    ...provided,
    border: "1px solid var(--theme-dark1) !important;",
    color: "var(--black)",
    height: 40,
    fontSize: 16,
    borderRadius: "var(--bs-border-radius)",
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 9999,
  }),
};

function RestaurantDetailPage({
  onSubmit,
  loading,
  details,
  heading,
  Sidebar,
  buttons,
}) {
  const validator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState();
  const [states, setStates] = useState([]);

  useEffect(() => {
    const data = State.getStatesOfCountry("AU");
    const options = data.map((s) => ({
      value: s.isoCode,
      label: s.name,
    }));
    setStates(options);
  }, []);

  const [profileImage, setProfileImage] = useState("");
  const [bannerImages, setBannerImages] = useState(details?.banners_path || []);

  const [isProfileImageChange, setIsProfileImageChange] = useState(false);
  const [isBannerImageChange, setIsBannerImageChange] = useState(false);

  const [openBreakfast, setOpenBreakfast] = useState(false);
  const [openLunch, setOpenLunch] = useState(false);
  const [openDinner, setOpenDinner] = useState(false);
  const [openAllDay, setOpenAllDay] = useState(false);

  const [values, setValues] = useState({
    restaurantId: "",
    name: "",
    phone: "",
    address: {
      streetName: "",
      city: "",
      state: "",
      zipCode: "",
    },
    overview: "",
    breakFastHours: BREACKFASTDAYS,
    lunchHours: LUNCHDAYS,
    dinnerHours: DINNERDAYS,
    allDayHours: ALLDAYS,
    bannerImageUploaded: details?.banners_path || "",
  });

  useEffect(() => {
    setValues({
      ...values,
      restaurantId: details?.id || "",
      name: details?.name || "",
      phone: details?.phone || "",
      address: {
        streetName: details?.restaurant_address?.streetName || "",
        city: details?.restaurant_address?.city || "",
        state: details?.restaurant_address?.state || "",
        zipCode: details?.restaurant_address?.zipCode || "",
      },
      overview: details?.overview || "",
      breakFastHours: details?.breakfast_hours || BREACKFASTDAYS,
      lunchHours: details?.lunch_hours || LUNCHDAYS,
      dinnerHours: details?.dinner_hours || DINNERDAYS,
      allDayHours: details?.same_hours_all_day || ALLDAYS,
      bannerImageUploaded: details?.banners_path || "",
    });

    setProfileImage(details?.profile_image_path || "");
    setBannerImages(details?.banners_path || []);

    setOpenBreakfast(details?.open_breakfast === "1" ? true : openBreakfast);
    setOpenLunch(details?.open_lunch === "1" ? true : openLunch);
    setOpenDinner(details?.open_dinner === "1" ? true : openDinner);
    setOpenAllDay(details?.open_allday === "1" ? true : openAllDay);
  }, [details]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name.includes(".")) {
      const [parent, child] = name.split(".");
      setValues((prevValues) => ({
        ...prevValues,
        [parent]: {
          ...prevValues[parent],
          [child]: value,
        },
      }));
    } else {
      setValues((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
    }

    if (name === "phone" && value.length > 10) {
      setValues({ ...values, phone: value.slice(0, 10) });
    }
  };

  const handleChangeHours = (day, field, value) => {
    setValues({
      ...values,
      breakFastHours: {
        ...values.breakFastHours,
        [day]: {
          ...values.breakFastHours[day],
          [field]: value,
        },
      },
    });
  };

  const handleChangeLunchHours = (day, field, value) => {
    setValues({
      ...values,
      lunchHours: {
        ...values.lunchHours,
        [day]: {
          ...values.lunchHours[day],
          [field]: value,
        },
      },
    });
  };

  const handleChangeDinnerHours = (day, field, value) => {
    setValues({
      ...values,
      dinnerHours: {
        ...values.dinnerHours,
        [day]: {
          ...values.dinnerHours[day],
          [field]: value,
        },
      },
    });
  };

  const handleChangeAllDayHours = (day, field, value) => {
    setValues({
      ...values,
      allDayHours: {
        ...values.allDayHours,
        [day]: {
          ...values.allDayHours[day],
          [field]: value,
        },
      },
    });
  };

  const handleSubmit = async (val) => {
    if (validator.current.allValid()) {
      values.profileImage = isProfileImageChange ? profileImage : "";
      values.bannerImage = isBannerImageChange ? bannerImages : "";
      values.openBreakfast = openBreakfast === true ? "1" : "0";
      values.openLunch = openLunch === true ? "1" : "0";
      values.openDinner = openDinner === true ? "1" : "0";
      values.openAllDay = openAllDay === true ? "1" : "0";

      onSubmit(values, val);
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };

  const getBannerImages = (banners) => {
    setBannerImages(banners);
    setIsBannerImageChange(true);
    setValues({ ...values, bannerImageUploaded: "uploaded" });
  };

  const getProfileImage = (img) => {
    setProfileImage(img);
    setIsProfileImageChange(true);
  };

  const handleChangeState = (selected) => {
    setValues({ ...values, address: { ...values.address, state: selected } });
  };

  const handleChangePhone = (e) => {
    const inputValue = handleNumericInput(e.target.value);
    if (inputValue !== null && inputValue.length < 11) {
      setValues({
        ...values,
        phone: inputValue,
      });
    }
  };

  return (
    <>
      <div className="restaurant-details-page py-4">
        <div className="container custom-container">
          <div className="row">
            <div className="col-md-4 col-lg-3 mb-4">
              <Sidebar details={details} />
            </div>
            <div className="col-md-8 col-lg-9">
              <div className="common-info-card">
                <div className="heading-title">{heading}</div>
                <div className="details-wrapper">
                  <div className="sub-title mb-3">
                    These details will appear on your activity listing
                  </div>
                  {/* Restaurant Details */}
                  <div className="row">
                    <div className="col-12">
                      <div className="info-cover pb-lg-2">
                        <div className="main-heading2 mb-3">
                          Restaurant Details:
                        </div>
                        {/* name */}
                        <div className="row gx-3 d-flex flex-wrap align-items-center mb-2">
                          <div className="col-6">
                            <label
                              htmlFor="RestaurantName"
                              className="fs-md-18 mb-1"
                            >
                              Restaurant Name:
                              <span className="text-red">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="RestaurantName"
                              placeholder=""
                              autoComplete="off"
                              name="name"
                              value={values.name}
                              onChange={handleChange}
                            />
                            {validator.current.message(
                              "restaurant name",
                              values.name,
                              "required"
                            )}
                          </div>
                          {/* phone */}
                          <div className="col-6">
                            <label
                              htmlFor="RestaurantPhone"
                              className="fs-md-18 mb-1"
                            >
                              Restaurant Phone:
                              <span className="text-red">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="RestaurantPhone"
                              placeholder=""
                              autoComplete="off"
                              value={values.phone}
                              name="phone"
                              onChange={handleChangePhone}
                            />
                            {validator.current.message(
                              "restaurant Phone",
                              values.phone,
                              "required"
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Restaurant Address */}
                  <div className="row">
                    <div className="col-12">
                      <div className="location-details-page info-cover mb-2 pb-lg-3">
                        <label
                          htmlFor="RestaurantPhone"
                          className="fs-md-18 mb-1"
                        >
                          Restaurant Address :
                        </label>
                        <div className="address-wrapper">
                          <div className="row gx-3">
                            <div className="col-12">
                              <div className="form-group">
                                <label
                                  className="fs-14 fw-medium"
                                  htmlFor="address1"
                                >
                                  Address Line 1{" "}
                                  <span className="text-red">*</span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="address1"
                                  placeholder=""
                                  autoComplete="off"
                                  name="address.streetName"
                                  value={values.address.streetName}
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                            <div className="col-sm-4">
                              <div className="form-group">
                                <label
                                  className="fs-14 fw-medium"
                                  htmlFor="city"
                                >
                                  City <span className="text-red">*</span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="city"
                                  placeholder=""
                                  autoComplete="off"
                                  name="address.city"
                                  value={values.address.city}
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                            <div className="col-sm-4">
                              <div className="form-group">
                                <label
                                  className="fs-14 fw-medium"
                                  htmlFor="State"
                                >
                                  State <span className="text-red">*</span>
                                </label>
                                <Select
                                  className="text-body"
                                  styles={customStyles}
                                  placeholder="Select State"
                                  value={values?.address?.state}
                                  name="address.state"
                                  onChange={handleChangeState}
                                  options={states}
                                />
                              </div>
                            </div>
                            <div className="col-sm-4">
                              <div className="form-group mb-2">
                                <label
                                  className="fs-14 fw-medium"
                                  htmlFor="zipcode"
                                >
                                  Post Code <span className="text-red">*</span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="zipcode"
                                  placeholder=""
                                  autoComplete="off"
                                  maxLength="6"
                                  name="address.zipCode"
                                  value={values.address.zipCode}
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Houres */}
                  <div className="row">
                    <div className="col-12">
                      <div className="info-cover pb-lg-2">
                        <div className="main-heading2 mb-3">Hours:</div>
                        {/* Breakfast hours */}
                        <div className="col-12">
                          <div className="info-cover breakfast-timing">
                            <div
                              className="main-heading23 fs-md-18 d-flex mb-3 gap-1"
                              style={{ color: openAllDay ? "#ffffff47" : "" }}
                            >
                              Open for Breakfast?:{" "}
                              <input
                                style={{
                                  marginTop: 4,
                                  backgroundColor: openAllDay
                                    ? "#808080a8"
                                    : "",
                                }}
                                type="checkbox"
                                className="custom-radio1"
                                checked={openBreakfast}
                                onChange={(e) =>
                                  setOpenBreakfast(e.target.checked)
                                }
                                disabled={openAllDay}
                              />
                            </div>
                            {openBreakfast && (
                              <>
                                <div className="title-cover">
                                  <div className="row">
                                    <div className="col-3 mb-1">&nbsp;</div>
                                    <div className="col-3 d-flex gap-3">
                                      <div className="fs-14 fw-semibold flex-fill">
                                        Opening Time
                                      </div>
                                    </div>
                                    <div className="col-3 d-flex gap-3">
                                      <div className="fs-14 fw-semibold flex-fill">
                                        Closing Time
                                      </div>
                                    </div>
                                    <div className="col-3 d-flex gap-3">
                                      <div className="fs-14 fw-semibold flex-fill">
                                        Close?
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {Object.entries(values.breakFastHours).map(
                                  ([day, hours, index]) => (
                                    <div key={index} className="day-cover row">
                                      <div className="col-3 mb-1">{day}:</div>
                                      {hours.close === false ? (
                                        <>
                                          <div className="col-3 d-flex gap-3">
                                            <div className="flex-fill mb-2">
                                              <input
                                                type="time"
                                                className="form-control"
                                                autoComplete="off"
                                                value={hours.openningTime}
                                                onChange={(e) =>
                                                  handleChangeHours(
                                                    day,
                                                    "openningTime",
                                                    e.target.value
                                                  )
                                                }
                                              />
                                            </div>
                                          </div>
                                          <div className="col-3 d-flex gap-3">
                                            <div className="flex-fill mb-2">
                                              <input
                                                type="time"
                                                className="form-control"
                                                value={hours.closingTime}
                                                autoComplete="off"
                                                onChange={(e) =>
                                                  handleChangeHours(
                                                    day,
                                                    "closingTime",
                                                    e.target.value
                                                  )
                                                }
                                              />
                                            </div>
                                          </div>
                                        </>
                                      ) : (
                                        <div
                                          className="col-6 d-flex justify-content-center"
                                          style={{
                                            color: "red",
                                            placeItems: "center1",
                                          }}
                                        >
                                          Closed
                                        </div>
                                      )}
                                      <div className="col-3 d-flex gap-3">
                                        <div className="flex-fill mb-2">
                                          <input
                                            style={{ marginTop: 5 }}
                                            type="checkbox"
                                            className="custom-radio1"
                                            value={hours.close}
                                            checked={hours.close}
                                            onChange={(e) =>
                                              handleChangeHours(
                                                day,
                                                "close",
                                                e.target.checked
                                              )
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  )
                                )}
                              </>
                            )}
                          </div>
                        </div>

                        {/* Lunch Hours */}
                        <div className="col-12 mt-3">
                          <div className="info-cover breakfast-timing">
                            <div
                              className="main-heading23 fs-md-18 d-flex mb-3 gap-1"
                              style={{ color: openAllDay ? "#ffffff47" : "" }}
                            >
                              Open for Lunch?:
                              <input
                                style={{
                                  marginTop: 4,
                                  backgroundColor: openAllDay
                                    ? "#808080a8"
                                    : "",
                                }}
                                type="checkbox"
                                className="custom-radio1"
                                checked={openLunch}
                                onChange={(e) => setOpenLunch(e.target.checked)}
                                disabled={openAllDay}
                              />
                            </div>
                            {openLunch && (
                              <>
                                <div className="title-cover">
                                  <div className="row">
                                    <div className="col-3 mb-1">&nbsp;</div>
                                    <div className="col-3 d-flex gap-3">
                                      <div className="fs-14 fw-semibold flex-fill">
                                        Opening Time
                                      </div>
                                    </div>
                                    <div className="col-3 d-flex gap-3">
                                      <div className="fs-14 fw-semibold flex-fill">
                                        Closing Time
                                      </div>
                                    </div>
                                    <div className="col-3 d-flex gap-3">
                                      <div className="fs-14 fw-semibold flex-fill">
                                        Close?
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {Object.entries(values.lunchHours).map(
                                  ([day, hours, index]) => (
                                    <div key={index} className="day-cover row">
                                      <div className="col-3 mb-1">{day}:</div>
                                      {hours.close === false ? (
                                        <>
                                          <div className="col-3 d-flex gap-3">
                                            <div className="flex-fill mb-2">
                                              <input
                                                type="time"
                                                className="form-control"
                                                autoComplete="off"
                                                value={hours.openningTime}
                                                onChange={(e) =>
                                                  handleChangeLunchHours(
                                                    day,
                                                    "openningTime",
                                                    e.target.value
                                                  )
                                                }
                                              />
                                            </div>
                                          </div>
                                          <div className="col-3 d-flex gap-3">
                                            <div className="flex-fill mb-2">
                                              <input
                                                type="time"
                                                className="form-control"
                                                value={hours.closingTime}
                                                autoComplete="off"
                                                onChange={(e) =>
                                                  handleChangeLunchHours(
                                                    day,
                                                    "closingTime",
                                                    e.target.value
                                                  )
                                                }
                                              />
                                            </div>
                                          </div>
                                        </>
                                      ) : (
                                        <div
                                          className="col-6 d-flex justify-content-center"
                                          style={{
                                            color: "red",
                                            placeItems: "center1",
                                          }}
                                        >
                                          Closed
                                        </div>
                                      )}
                                      <div className="col-3 d-flex gap-3">
                                        <div className="flex-fill mb-2">
                                          <input
                                            style={{ marginTop: 5 }}
                                            type="checkbox"
                                            className="custom-radio1"
                                            value={hours.close}
                                            checked={hours.close}
                                            onChange={(e) =>
                                              handleChangeLunchHours(
                                                day,
                                                "close",
                                                e.target.checked
                                              )
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  )
                                )}
                              </>
                            )}
                          </div>
                        </div>

                        {/* Dinner Hours */}
                        <div className="col-12 mt-3">
                          <div className="info-cover breakfast-timing">
                            <div
                              className="main-heading23 fs-md-18 d-flex mb-3 gap-1"
                              style={{ color: openAllDay ? "#ffffff47" : "" }}
                            >
                              Open for Dinner?:
                              <input
                                style={{
                                  marginTop: 4,
                                  backgroundColor: openAllDay
                                    ? "#808080a8"
                                    : "",
                                }}
                                type="checkbox"
                                className="custom-radio1"
                                checked={openDinner}
                                onChange={(e) =>
                                  setOpenDinner(e.target.checked)
                                }
                                disabled={openAllDay}
                              />
                            </div>
                            {openDinner && (
                              <>
                                <div className="title-cover">
                                  <div className="row">
                                    <div className="col-3 mb-1">&nbsp;</div>
                                    <div className="col-3 d-flex gap-3">
                                      <div className="fs-14 fw-semibold flex-fill">
                                        Opening Time
                                      </div>
                                    </div>
                                    <div className="col-3 d-flex gap-3">
                                      <div className="fs-14 fw-semibold flex-fill">
                                        Closing Time
                                      </div>
                                    </div>
                                    <div className="col-3 d-flex gap-3">
                                      <div className="fs-14 fw-semibold flex-fill">
                                        Close?
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {Object.entries(values.dinnerHours).map(
                                  ([day, hours, index]) => (
                                    <div key={index} className="day-cover row">
                                      <div className="col-3 mb-1">{day}:</div>
                                      {hours.close === false ? (
                                        <>
                                          <div className="col-3 d-flex gap-3">
                                            <div className="flex-fill mb-2">
                                              <input
                                                type="time"
                                                className="form-control"
                                                autoComplete="off"
                                                value={hours.openningTime}
                                                onChange={(e) =>
                                                  handleChangeDinnerHours(
                                                    day,
                                                    "openningTime",
                                                    e.target.value
                                                  )
                                                }
                                              />
                                            </div>
                                          </div>
                                          <div className="col-3 d-flex gap-3">
                                            <div className="flex-fill mb-2">
                                              <input
                                                type="time"
                                                className="form-control"
                                                value={hours.closingTime}
                                                autoComplete="off"
                                                onChange={(e) =>
                                                  handleChangeDinnerHours(
                                                    day,
                                                    "closingTime",
                                                    e.target.value
                                                  )
                                                }
                                              />
                                            </div>
                                          </div>
                                        </>
                                      ) : (
                                        <div
                                          className="col-6 d-flex justify-content-center"
                                          style={{
                                            color: "red",
                                            placeItems: "center1",
                                          }}
                                        >
                                          Closed
                                        </div>
                                      )}
                                      <div className="col-3 d-flex gap-3">
                                        <div className="flex-fill mb-2">
                                          <input
                                            style={{ marginTop: 5 }}
                                            type="checkbox"
                                            className="custom-radio1"
                                            value={hours.close}
                                            checked={hours.close}
                                            onChange={(e) =>
                                              handleChangeDinnerHours(
                                                day,
                                                "close",
                                                e.target.checked
                                              )
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  )
                                )}
                              </>
                            )}
                          </div>
                        </div>

                        {/* Same menu all day */}
                        <div className="col-12 mt-3">
                          <div className="info-cover breakfast-timing">
                            <div
                              className="main-heading23 fs-md-18 d-flex mb-3 gap-1"
                              style={{
                                color:
                                  openBreakfast || openLunch || openDinner
                                    ? "#ffffff47"
                                    : "",
                              }}
                            >
                              Same Menu all Day?:
                              <input
                                style={{
                                  marginTop: 4,
                                  backgroundColor:
                                    openBreakfast || openLunch || openDinner
                                      ? "#808080a8"
                                      : "",
                                }}
                                type="checkbox"
                                className="custom-radio1"
                                checked={openAllDay}
                                onChange={(e) =>
                                  setOpenAllDay(e.target.checked)
                                }
                                disabled={
                                  openBreakfast || openLunch || openDinner
                                }
                              />
                            </div>
                            {openAllDay && (
                              <>
                                <div className="title-cover">
                                  <div className="row">
                                    <div className="col-3 mb-1">&nbsp;</div>
                                    <div className="col-3 d-flex gap-3">
                                      <div className="fs-14 fw-semibold flex-fill">
                                        Opening Time
                                      </div>
                                    </div>
                                    <div className="col-3 d-flex gap-3">
                                      <div className="fs-14 fw-semibold flex-fill">
                                        Closing Time
                                      </div>
                                    </div>
                                    <div className="col-3 d-flex gap-3">
                                      <div className="fs-14 fw-semibold flex-fill">
                                        Close?
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {Object.entries(values.allDayHours).map(
                                  ([day, hours, index]) => (
                                    <div key={index} className="day-cover row">
                                      <div className="col-3 mb-1">{day}:</div>
                                      {hours.close === false ? (
                                        <>
                                          <div className="col-3 d-flex gap-3">
                                            <div className="flex-fill mb-2">
                                              <input
                                                type="time"
                                                className="form-control"
                                                autoComplete="off"
                                                value={hours.openningTime}
                                                onChange={(e) =>
                                                  handleChangeAllDayHours(
                                                    day,
                                                    "openningTime",
                                                    e.target.value
                                                  )
                                                }
                                              />
                                            </div>
                                          </div>
                                          <div className="col-3 d-flex gap-3">
                                            <div className="flex-fill mb-2">
                                              <input
                                                type="time"
                                                className="form-control"
                                                value={hours.closingTime}
                                                autoComplete="off"
                                                onChange={(e) =>
                                                  handleChangeAllDayHours(
                                                    day,
                                                    "closingTime",
                                                    e.target.value
                                                  )
                                                }
                                              />
                                            </div>
                                          </div>
                                        </>
                                      ) : (
                                        <div
                                          className="col-6 d-flex justify-content-center"
                                          style={{
                                            color: "red",
                                            placeItems: "center1",
                                          }}
                                        >
                                          Closed
                                        </div>
                                      )}
                                      <div className="col-3 d-flex gap-3">
                                        <div className="flex-fill mb-2">
                                          <input
                                            style={{ marginTop: 5 }}
                                            type="checkbox"
                                            className="custom-radio1"
                                            value={hours.close}
                                            checked={hours.close}
                                            onChange={(e) =>
                                              handleChangeAllDayHours(
                                                day,
                                                "close",
                                                e.target.checked
                                              )
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  )
                                )}
                              </>
                            )}
                          </div>
                        </div>

                        {validator.current.message(
                          "meal selection",
                          openBreakfast || openLunch || openDinner,
                          "required"
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="field-wrapper mb-lg-2">
                    <div className="info-cover">
                      <div className="row">
                        <div className="col-12">
                          <div className="main-heading2 mb-1">Images:</div>
                        </div>
                        <div className="col-lg-6 mb-4 mb-lg-0">
                          <div className="info-cover">
                            <div className="profile-cover mb-3">
                              <div className="main-heading2 text-white mb-1">
                                Profile Images:
                              </div>
                              <p className="fs-12">
                                <i>
                                  (This is the image shown on the search results
                                  page)
                                </i>
                              </p>
                              <ImageUploads
                                getImage={getProfileImage}
                                uploadedImage={profileImage}
                              />
                              <div
                                style={{
                                  marginTop: !profileImage ? "-5px" : 0,
                                }}
                              >
                                {validator.current.message(
                                  "profile image",
                                  profileImage,
                                  "required"
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="info-cover">
                            <BannerImagesUploads
                              bannerImages={getBannerImages}
                              images={bannerImages}
                            />
                            {validator.current.message(
                              "banner",
                              values.bannerImageUploaded,
                              "required"
                            ) && (
                              <div
                                style={{
                                  marginTop: "-38px",
                                }}
                              >
                                {validator.current.message(
                                  "banner",
                                  values.bannerImageUploaded,
                                  "required"
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="wrapper mb-2">
                    <div className="info-cover">
                      <div className="btn-wrapper d-flex flex-column flex-md-row flex-wrap justify-content-center row-gap-3 column-gap-4 mt-4">
                        {buttons.map((button) => (
                          <button
                            key={button.id}
                            onClick={() => handleSubmit(button.type)}
                            className="btn btn-custom"
                            disabled={loading || false}
                          >
                            {loading ? "Loading..." : button.label}
                          </button>
                        ))}
                      </div>
                    </div>
                  </div>
                  {/* {admin ? (
                      <div className="wrapper mb-2">
                        <div className="info-cover">
                          <div className="btn-wrapper d-flex flex-column flex-md-row flex-wrap justify-content-center row-gap-3 column-gap-4 mt-4">
                            <button
                              type="button"
                              className="btn btn-custom"
                              onClick={handleSubmit}
                              disabled={loading || false}
                            >
                              {loading ? "Updating..." : "Update"}
                            </button>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="wrapper mb-2">
                        <div className="info-cover">
                          <div className="btn-wrapper d-flex flex-column flex-md-row flex-wrap justify-content-center row-gap-3 column-gap-4 mt-4">
                            <button
                              type="button"
                              className="btn btn-custom"
                              onClick={() => handleSubmit("breakFast")}
                              disabled={loading || false}
                            >
                              {loading ? "Submitting..." : "Add Breakfast Menu"}
                            </button>

                            <button
                              type="button"
                              className="btn btn-custom"
                              onClick={() => handleSubmit("lunch")}
                              disabled={loading || false}
                            >
                              {loading ? "Submitting..." : "Add Lunch Menu"}
                            </button>

                            <button
                              type="button"
                              className="btn btn-custom"
                              onClick={() => handleSubmit("dinner")}
                              disabled={loading || false}
                            >
                              {loading ? "Submitting..." : "Add Dinner Menu"}
                            </button>
                          </div>
                        </div>
                      </div>
                    )} */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default RestaurantDetailPage;
