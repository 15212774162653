import React from "react";

function VideoTutorialPopUp({ closeVideo, videoURL }) {
  return (
    <>
      {videoURL && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1040,
            border: "2px solid #fdb539",
            borderRadius: 5,
          }}
        >
          {" "}
          <div
            style={{
              border: "3px solid #fdb539",
              borderRadius: "15px",
              overflow: "hidden",
              maxWidth: "90%",
              maxHeight: "90%",
            }}
          >
            <button
              onClick={closeVideo}
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                backgroundColor: "rgba(255, 0, 0, 0.7)",
                color: "white",
                border: "none",
                borderRadius: "5px",
                padding: "5px 10px",
                cursor: "pointer",
                zIndex: 1001, // Ensure button is above the video
              }}
            >
              Close
            </button>
            <video
              src={videoURL}
              autoPlay
              controls
              style={{
                width: "100%",
                height: "auto",
                maxHeight: "90vh",
              }}
            >
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      )}
    </>
  );
}

export default VideoTutorialPopUp;
