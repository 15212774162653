import React from "react";
// import LandingHeader from "../../Landing/LandingHeader/LandingHeader";
import WhyChooseKiosk from "../WhyChooseKiosk/WhyChooseKiosk";
import Hotelbooking from "../Hotelbooking/Hotelbooking";
import NightClubBanner from "../../nightClubLand/NightClubBanner/NightClubBanner";
import Header from "../../homeLayout/Header";

const HotelLanding = () => {
  return (
    <>
    <Header/>
      {/* <LandingHeader /> */}
      <NightClubBanner/>
      <WhyChooseKiosk />
      <Hotelbooking id="claimkiosk" />
    </>
  );
};

export default HotelLanding;
