import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import AdminSidebar from "../layouts/AdminSidebar";
import { Button } from "react-bootstrap";
import { Trash, Plus } from "react-bootstrap-icons";
import { handleNumericInput } from "../util/utils";
import webServices from "../../services/webServices";
import { authCall } from "../../services";

function PlanDetailPage() {
  const [loading, setLoading] = useState(false);
  const [loadingPerHotel, setLoadingPerHotel] = useState(false);
  const [loadingMaxHotel, setLoadingMaxHotel] = useState(false);
  const [loadingOnlineResv, setLoadingOnlineResv] = useState(false);
  const [loadingSubs, setLoadingSubs] = useState("");
  const [loadingAds, setLoadingAds] = useState("");
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [isSubscribeChange, setIsSubscribeChange] = useState("");
  const [perHotelPrice, setPerHotelPrice] = useState({});
  const [maxHotelPrice, setMaxHotelPrice] = useState("");
  const [onlineSubsPrice, setOnlineSubsPrice] = useState("");
  const [subscriptionField, setSubscriptionField] = useState([
    {
      id: 1,
      subscriptionName: "",
      numberOfHotes: "",
      onlineReservation: "",
      new: true,
    },
  ]);
  const [desireAdsPlan, setDesireAdsPlan] = useState([
    {
      id: 1,
      planName: "Desired Plan 1",
      goalValue: "",
      desirePlan: "",
      new: true,
    },
  ]);

  const getPlasDetails = async () => {
    setLoading(true);
    const resp = await webServices.getPlans({
      type: "",
    });
    if (resp?.type === "success") {
      const subsPlans = [];
      const desirePlans = [];
      resp?.payload.forEach((el) => {
        if (el?.name === "Per Hotel") {
          setPerHotelPrice(el);
        } else if (el?.name === "Max Hotel Price") {
          setMaxHotelPrice(el);
        } else if (el?.name === "Online Reservation") {
          setOnlineSubsPrice(el);
        } else if (el?.type === "subscription") {
          subsPlans.push({
            id: el?.id,
            subscriptionName: el?.name,
            numberOfHotes: el?.hotels,
            onlineReservation: el?.online_reservations === 1 ? "Yes" : "No",
            planId: el?.plan_id,
            priceId: el?.price_id,
          });
        } else if (el?.type === "adsSubscription") {
          desirePlans.push({
            id: el?.id,
            planName: el?.name,
            goalValue: el?.goal_value,
            desirePlan: el?.amount,
            planId: el?.plan_id,
            priceId: el?.price_id,
          });
          setDesireAdsPlan(desirePlans);
        }
      });
      setSubscriptionField(subsPlans);
      setLoading(false);
    }
  };

  useEffect(() => {
    getPlasDetails();
  }, []);

  const handleUpdate = (el, value, setValue) => {
    const inputValue = handleNumericInput(value);
    // if (inputValue !== null && inputValue.length < 5) {
    setValue({ ...el, amount: value });
    if (el?.name === "Online Reservation") {
      setIsSubscribeChange(value);
    }
    // }
  };

  const addSubscriptionPlan = () => {
    const newFields = [];
    const newField = {
      id: subscriptionField.length + 1,
      subscriptionName: "",
      numberOfHotes: "",
      onlineReservation: "",
      new: true,
    };
    newFields.push(newField);
    setSubscriptionField([...subscriptionField, ...newFields]);
  };

  const removeSubscriptionPlan = (index) => {
    const updatedFiles = subscriptionField.filter((_, i) => i !== index);
    setSubscriptionField(updatedFiles);
  };

  const handleChangePlan = (id, name, value) => {
    setSubscriptionField(
      subscriptionField.map((field) =>
        field.id === id ? { ...field, [name]: value } : field
      )
    );
  };

  const handleChangeHotels = (id, name, value) => {
    const inputValue = handleNumericInput(value);
    if (inputValue !== null && inputValue.length < 5) {
      setSubscriptionField(
        subscriptionField.map((field) =>
          field.id === id ? { ...field, [name]: inputValue } : field
        )
      );
    }
  };

  const handleSubmit = async (item) => {
    const { subscriptionName, numberOfHotes, onlineReservation } = item;
    if (subscriptionName && numberOfHotes && onlineReservation) {
      setLoadingSubs(`loading${item?.id}`);
      toast.dismiss();

      const updateValue = {
        name: item?.subscriptionName,
        description: `${item?.subscriptionName} with ${item?.numberOfHotes} hotels`,
        amount: +perHotelPrice?.amount * +item?.numberOfHotes,
        interval: "month",
        type: "subscription",
        numberOfHotes: +item?.numberOfHotes,
        onlineReservation: item?.onlineReservation === "Yes" ? 1 : 0,
      };

      if (item?.new) {
        const resp = await authCall("createPlanWithStripe", updateValue);
        if (resp?.type === "success") {
          setLoadingSubs("");
          toast.success(resp?.message);
          setSubscriptionField(
            subscriptionField.map((field) =>
              field.id === item?.id ? { ...field, new: false } : field
            )
          );
        } else {
          setLoadingSubs("");
          toast.error(resp?.message);
        }
      } else {
        const updateNewValue = {
          ...updateValue,
          plan_id: item?.id,
          product_id: item?.planId,
          price_id: item?.priceId,
        };

        const resp = await authCall("updatePlanWithPrice", updateNewValue);
        if (resp?.type === "success") {
          setLoadingSubs("");
          toast.success(resp?.message);
        } else {
          setLoadingSubs("");
          toast.error(resp?.message);
        }
      }
    } else {
      toast.dismiss();
      toast.error("All fields are required.");
    }
  };

  const handleDelete = (el) => {
    toast.warning(
      <div>
        <p>Are you sure you want to delete?</p>
        <Button
          variant="success"
          className="btn-sm"
          onClick={() => deleteItem(el)}
          disabled={loadingDelete || false}
        >
          {loadingDelete ? "Loading..." : "Yes"}
        </Button>
        <Button
          variant="danger"
          className="btn-sm"
          style={{ marginLeft: "2px" }}
          onClick={closeToast}
        >
          No
        </Button>
      </div>,
      {
        position: "top-center",
        autoClose: false,
        closeOnClick: false,
        draggable: false,
        closeButton: false,
      }
    );
  };

  const deleteItem = async (item) => {
    const resp = await authCall("deletePlanWithStripe", {
      plan_id: item?.planId,
    });
    setLoadingDelete(true);
    if (resp?.type === "success") {
      closeToast();
      toast.success(resp?.message);
      setLoadingDelete(false);
      getPlasDetails();
    } else {
      toast.error(resp?.message);
      setLoadingDelete(false);
    }
  };

  const closeToast = () => {
    toast.dismiss();
  };

  // update plan in DB
  const updatePlan = async (el, planLoading) => {
    const { id, amount } = el;
    if (!amount) {
      toast.dismiss();
      toast.error("Amount is required.");
      return null;
    }
    planLoading(true);
    const resp = await webServices.updatePlan({ id, amount });
    if (resp?.type === "success") {
      toast.dismiss();
      toast.success(resp?.message);
      planLoading(false);
      // getPlasDetails();
    }
  };

  // update online reservation price in DB and stripe
  const updateOnlineReservation = async (el) => {
    const updateValue = {
      plan_id: el?.id,
      product_id: el?.plan_id,
      name: "Online Reservation",
      description: "Online Reservation",
      price_id: el?.price_id,
      amount: +el?.amount,
      interval: "month",
      type: "restaurant",
    };
    setLoadingOnlineResv(true);
    const resp = await authCall("updatePlanWithPrice", updateValue);
    if (resp?.type === "success") {
      setLoadingOnlineResv(false);
      toast.dismiss();
      toast.success(resp?.message);
      setIsSubscribeChange(false);
    } else {
      setIsSubscribeChange(false);
      setLoadingOnlineResv(false);
      toast.dismiss();
      toast.error(resp?.message);
    }
  };

  // ################ desire plan #####################
  const addAdsDesirePlan = () => {
    const newFields = [];
    const newField = {
      id: desireAdsPlan.length + 1,
      planName: `Desired Plan ${desireAdsPlan.length + 1}`,
      goalValue: "",
      desirePlan: "",
      new: true,
    };
    newFields.push(newField);
    setDesireAdsPlan([...desireAdsPlan, ...newFields]);
  };

  const removeAdsPlan = (index) => {
    const updatedFiles = desireAdsPlan.filter((_, i) => i !== index);
    setDesireAdsPlan(updatedFiles);
  };

  const handleChangeDesirePlan = (id, name, value) => {
    const limit = name === "desirePlan" ? 5 : 10;
    const inputValue = handleNumericInput(value);
    if (inputValue !== null && inputValue.length < limit) {
      setDesireAdsPlan(
        desireAdsPlan.map((field) =>
          field.id === id ? { ...field, [name]: value } : field
        )
      );
    }
  };

  const handleSubmitAdsPlan = async (item) => {
    const { desirePlan, goalValue, planName } = item;
    if (desirePlan) {
      setLoadingAds(`loading${item?.id}`);
      toast.dismiss();

      const updateValue = {
        name: planName,
        description: `Amount $${desirePlan} for goal value ${goalValue} list for ads subscriptions`,
        amount: desirePlan,
        goalValue: goalValue,
        interval: "month",
        type: "adsSubscription",
      };

      if (item?.new) {
        const resp = await authCall("createPlanWithStripe", updateValue);
        if (resp?.type === "success") {
          setLoadingAds("");
          toast.success(resp?.message);
          setDesireAdsPlan(
            desireAdsPlan.map((field) =>
              field.id === item?.id ? { ...field, new: false } : field
            )
          );
        } else {
          setLoadingAds("");
          toast.error(resp?.message);
        }
      } else {
        const updateNewValue = {
          ...updateValue,
          plan_id: item?.id,
          product_id: item?.planId,
          price_id: item?.priceId,
        };

        const resp = await authCall("updatePlanWithPrice", updateNewValue);
        if (resp?.type === "success") {
          setLoadingAds("");
          toast.success(resp?.message);
        } else {
          setLoadingAds("");
          toast.error(resp?.message);
        }
      }
    } else {
      toast.dismiss();
      toast.error("Desired plan amount is required.");
    }
  };

  return (
    <>
      {console.log("desireAdsPlan", desireAdsPlan)}
      <div className="restaurant-details-page py-4">
        <div className="container custom-container">
          <div className="row">
            <div className="col-md-4 col-lg-3 mb-4">
              <AdminSidebar />
            </div>
            <div className="col-md-8 col-lg-9">
              <div className="common-info-card">
                <div className="heading-title">Plans & Subscriptions</div>

                {/* plan details */}
                <div className="details-wrapper">
                  <div className="row">
                    <div className="col-12">
                      <div className="info-cover pb-lg-2">
                        <div className="main-heading2 mb-3">Plan Details:</div>

                        {/* Price Per hotel */}
                        <div className="row gx-3 d-flex flex-wrap align-items-center mb-3">
                          <div className="col-4">
                            <label
                              htmlFor="RestaurantName"
                              className="fs-md-18 mb-1"
                            >
                              Per Hotel Price:
                            </label>
                          </div>
                          {loading && (
                            <>
                              <div className="col-3">
                                <Skeleton count={1} height={40} />
                              </div>
                              <div className="col-3">
                                <Skeleton count={1} height={40} width={120} />
                              </div>
                            </>
                          )}
                          {!loading && (
                            <>
                              <div className="col-3">
                                <input
                                  type="text"
                                  className="form-control"
                                  autoComplete="off"
                                  name="perHotelPrice"
                                  value={perHotelPrice?.amount}
                                  onChange={(e) =>
                                    handleUpdate(
                                      perHotelPrice,
                                      e.target.value,
                                      setPerHotelPrice
                                    )
                                  }
                                />
                              </div>
                              <div className="col-3">
                                <button
                                  type="button"
                                  style={{ height: 40 }}
                                  className="btn btn-custom btn-custom-sm fs-16"
                                  onClick={() =>
                                    updatePlan(
                                      perHotelPrice,
                                      setLoadingPerHotel
                                    )
                                  }
                                  disabled={loadingPerHotel || false}
                                >
                                  {loadingPerHotel ? "Loading..." : "Update"}
                                </button>
                              </div>
                            </>
                          )}
                        </div>

                        {/* Max hotel price */}
                        <div className="row gx-3 d-flex flex-wrap align-items-center mb-3">
                          <div className="col-4">
                            <label
                              htmlFor="RestaurantPhone"
                              className="fs-md-18 mb-1"
                            >
                              Max Hotel Price:
                            </label>
                          </div>
                          {loading && (
                            <>
                              <div className="col-3">
                                <Skeleton count={1} height={40} />
                              </div>
                              <div className="col-3">
                                <Skeleton count={1} height={40} width={120} />
                              </div>
                            </>
                          )}
                          {!loading && (
                            <>
                              <div className="col-3">
                                <input
                                  type="text"
                                  className="form-control"
                                  autoComplete="off"
                                  value={maxHotelPrice?.amount}
                                  name="maxHotelPrice"
                                  onChange={(e) =>
                                    handleUpdate(
                                      maxHotelPrice,
                                      e.target.value,
                                      setMaxHotelPrice
                                    )
                                  }
                                />
                              </div>
                              <div className="col-3">
                                <button
                                  type="button"
                                  style={{ height: 40 }}
                                  className="btn btn-custom btn-custom-sm fs-16"
                                  onClick={() =>
                                    updatePlan(
                                      maxHotelPrice,
                                      setLoadingMaxHotel
                                    )
                                  }
                                  disabled={loadingMaxHotel || false}
                                >
                                  {loadingMaxHotel ? "Loading..." : "Update"}
                                </button>
                              </div>
                            </>
                          )}
                        </div>

                        {/* online reservation price */}
                        <div className="row gx-3 d-flex flex-wrap align-items-center">
                          <div className="col-4">
                            <label
                              htmlFor="RestaurantPhone"
                              className="fs-md-18 mb-1"
                            >
                              Online Reservation Price:
                            </label>
                          </div>
                          {loading && (
                            <>
                              <div className="col-3">
                                <Skeleton count={1} height={40} />
                              </div>
                              <div className="col-3">
                                <Skeleton count={1} height={40} width={120} />
                              </div>
                            </>
                          )}
                          {!loading && (
                            <>
                              <div className="col-3">
                                <input
                                  type="text"
                                  className="form-control"
                                  autoComplete="off"
                                  value={onlineSubsPrice?.amount}
                                  name="onlineSubsPrice"
                                  onChange={(e) =>
                                    handleUpdate(
                                      onlineSubsPrice,
                                      e.target.value,
                                      setOnlineSubsPrice
                                    )
                                  }
                                />
                              </div>
                              <div className="col-3">
                                <button
                                  type="button"
                                  style={{ height: 40 }}
                                  className="btn btn-custom btn-custom-sm fs-16"
                                  onClick={() =>
                                    updateOnlineReservation(onlineSubsPrice)
                                  }
                                  disabled={loadingOnlineResv || false}
                                >
                                  {loadingOnlineResv
                                    ? "Loading..."
                                    : isSubscribeChange
                                    ? `Subscribe $${isSubscribeChange}`
                                    : "Update"}
                                </button>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* subsciption details */}
                <div className="details-wrapper">
                  <div className="row">
                    <div className="col-12">
                      <div className="info-cover pb-lg-2">
                        <div
                          className="main-heading2 mb-3"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          Subscriptions Plan Details:
                          {loading && (
                            <Skeleton count={1} height={31} width={120} />
                          )}
                          {!loading && (
                            <Button
                              className="btn btn-custom btn-custom-sm fs-16"
                              onClick={addSubscriptionPlan}
                            >
                              <Plus />
                              Add Plan
                            </Button>
                          )}
                        </div>
                        {/* Lavels */}
                        <div className="row gx-3 d-flex flex-wrap align-items-center">
                          <div className="col-3">
                            <label
                              htmlFor="RestaurantPhone"
                              className="fs-md-16 mb-1 text-custom-primary"
                              style={{ fontWeight: 700 }}
                            >
                              Subscription Name
                            </label>
                          </div>
                          <div className="col-3">
                            <label
                              htmlFor="RestaurantPhone"
                              className="fs-md-16 mb-1 text-custom-primary"
                              style={{ fontWeight: 700 }}
                            >
                              Number of Hotels
                            </label>
                          </div>

                          <div className="col-2">
                            <label
                              htmlFor="RestaurantPhone"
                              className="fs-md-16 mb-1 text-custom-primary"
                              style={{ fontWeight: 700 }}
                            >
                              Online Reservations
                            </label>
                          </div>

                          <div className="col-4">
                            <label
                              htmlFor="RestaurantPhone"
                              className="fs-md-16 mb-1 text-custom-primary"
                              style={{ fontWeight: 700 }}
                            >
                              Action
                            </label>
                          </div>
                        </div>
                        {loading &&
                          Array(3)
                            .fill()
                            .map((index) => (
                              <div
                                key={index}
                                className="row gx-3 d-flex flex-wrap align-items-center mb-3"
                              >
                                <div className="col-3">
                                  <Skeleton count={1} height={40} />
                                </div>
                                <div className="col-3">
                                  <Skeleton count={1} height={40} />
                                </div>

                                <div className="col-3">
                                  <Skeleton count={1} height={40} />
                                </div>

                                <div
                                  className="col-3"
                                  style={{
                                    display: "flex",
                                    // justifyContent: "space-between",
                                    alignItems: "center",
                                  }}
                                >
                                  <Skeleton count={1} height={40} width={120} />
                                  <Skeleton
                                    count={1}
                                    height={25}
                                    width={25}
                                    style={{ marginLeft: 13 }}
                                  />
                                </div>
                              </div>
                            ))}
                        {!loading &&
                          subscriptionField.map((field, index) => (
                            <div
                              key={index}
                              className="row gx-3 d-flex flex-wrap align-items-center mb-3"
                            >
                              <div className="col-3">
                                <input
                                  required
                                  type="text"
                                  className="form-control"
                                  autoComplete="off"
                                  value={field.subscriptionName}
                                  name="subscriptionName"
                                  onChange={(e) =>
                                    handleChangePlan(
                                      field.id,
                                      e.target.name,
                                      e.target.value
                                    )
                                  }
                                />
                              </div>
                              <div className="col-3">
                                <input
                                  required
                                  type="text"
                                  className="form-control"
                                  autoComplete="off"
                                  value={field.numberOfHotes}
                                  name="numberOfHotes"
                                  onChange={(e) =>
                                    handleChangeHotels(
                                      field.id,
                                      e.target.name,
                                      e.target.value
                                    )
                                  }
                                />
                              </div>

                              <div className="col-2">
                                <select
                                  required
                                  className="form-select"
                                  name="onlineReservation"
                                  onChange={(e) =>
                                    handleChangePlan(
                                      field.id,
                                      e.target.name,
                                      e.target.value
                                    )
                                  }
                                >
                                  <option value="">Select</option>
                                  <option
                                    value="Yes"
                                    selected={
                                      field.onlineReservation === "Yes" || false
                                    }
                                  >
                                    Yes
                                  </option>
                                  <option
                                    value="No"
                                    selected={
                                      field.onlineReservation === "No" || false
                                    }
                                  >
                                    No
                                  </option>
                                </select>
                              </div>

                              <div className="col-4">
                                <button
                                  type="button"
                                  style={{ height: 40 }}
                                  className="btn btn-custom btn-custom-sm fs-16"
                                  onClick={() => handleSubmit(field)}
                                  disabled={
                                    `loading${field?.id}` === loadingSubs ||
                                    false
                                  }
                                >
                                  {`loading${field?.id}` === loadingSubs
                                    ? "Loading..."
                                    : field.new
                                    ? `Subscribe ${
                                        +perHotelPrice?.amount *
                                          +field.numberOfHotes >
                                        0
                                          ? `$${
                                              +perHotelPrice?.amount *
                                              +field.numberOfHotes
                                            }`
                                          : ""
                                      }`
                                    : "Update"}
                                </button>
                                {subscriptionField.length > 1 && (
                                  <Trash
                                    onClick={() =>
                                      field.new
                                        ? removeSubscriptionPlan(index)
                                        : handleDelete(field)
                                    }
                                    className="cursor-pointer"
                                    style={{
                                      marginLeft: 10,
                                      color: "red",
                                      width: 25,
                                      height: 25,
                                    }}
                                  />
                                )}
                              </div>
                            </div>
                          ))}

                        {subscriptionField.length === 0 && (
                          <div className="text-center mt-3">
                            No subscription plan available
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                {/* ads desired plan */}
                <div className="details-wrapper">
                  <div className="row">
                    <div className="col-12">
                      <div className="info-cover pb-lg-2">
                        <div
                          className="main-heading2 mb-3"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          Ads Desired Plan Details:{" "}
                          {loading && (
                            <Skeleton count={1} height={31} width={120} />
                          )}
                          {!loading && (
                            <Button
                              className="btn btn-custom btn-custom-sm fs-16"
                              onClick={addAdsDesirePlan}
                            >
                              <Plus />
                              Add Plan
                            </Button>
                          )}
                        </div>
                        {/* Lavels */}
                        <div className="row gx-3 d-flex flex-wrap align-items-center">
                          <div className="col-2">
                            <label
                              htmlFor="RestaurantPhone"
                              className="fs-md-16 mb-1 text-custom-primary"
                              style={{ fontWeight: 700 }}
                            >
                              Plane Name
                            </label>
                          </div>
                          <div className="col-3">
                            <label
                              htmlFor="RestaurantPhone"
                              className="fs-md-16 mb-1 text-custom-primary"
                              style={{ fontWeight: 700 }}
                            >
                              Goal Value
                            </label>
                          </div>

                          <div className="col-3">
                            <label
                              htmlFor="RestaurantPhone"
                              className="fs-md-16 mb-1 text-custom-primary"
                              style={{ fontWeight: 700 }}
                            >
                              Amount
                            </label>
                          </div>

                          <div className="col-4">
                            <label
                              htmlFor="RestaurantPhone"
                              className="fs-md-16 mb-1 text-custom-primary"
                              style={{ fontWeight: 700 }}
                            >
                              Action
                            </label>
                          </div>
                        </div>
                        {loading &&
                          Array(3)
                            .fill()
                            .map((index) => (
                              <div
                                key={index}
                                className="row gx-3 d-flex flex-wrap align-items-center mb-3"
                              >
                                <div className="col-3">
                                  <Skeleton count={1} height={40} />
                                </div>
                                <div className="col-3">
                                  <Skeleton count={1} height={40} />
                                </div>

                                <div className="col-3">
                                  <Skeleton count={1} height={40} />
                                </div>

                                <div
                                  className="col-3"
                                  style={{
                                    display: "flex",
                                    // justifyContent: "space-between",
                                    alignItems: "center",
                                  }}
                                >
                                  <Skeleton count={1} height={40} width={120} />
                                  <Skeleton
                                    count={1}
                                    height={25}
                                    width={25}
                                    style={{ marginLeft: 13 }}
                                  />
                                </div>
                              </div>
                            ))}
                        {!loading &&
                          desireAdsPlan.map((field, index) => (
                            <div
                              key={index}
                              className="row gx-3 d-flex flex-wrap align-items-center mb-3"
                            >
                              <div className="col-2">
                                <label
                                  htmlFor="RestaurantName"
                                  className="fs-md-18 mb-1"
                                >
                                  {/* Desired Plan {index + 1}: */}
                                  {field?.planName}:
                                </label>
                              </div>
                              {loading && (
                                <>
                                  <div className="col-3">
                                    <Skeleton count={1} height={40} />
                                  </div>
                                  <div className="col-3">
                                    <Skeleton
                                      count={1}
                                      height={40}
                                      width={120}
                                    />
                                  </div>
                                </>
                              )}
                              {!loading && (
                                <>
                                  <div className="col-3">
                                    <input
                                      type="text"
                                      className="form-control"
                                      autoComplete="off"
                                      name="goalValue"
                                      value={field?.goalValue}
                                      onChange={(e) =>
                                        handleChangeDesirePlan(
                                          field.id,
                                          e.target.name,
                                          e.target.value
                                        )
                                      }
                                    />
                                  </div>
                                  <div className="col-3">
                                    <input
                                      type="text"
                                      className="form-control"
                                      autoComplete="off"
                                      name="desirePlan"
                                      value={field?.desirePlan}
                                      onChange={(e) =>
                                        handleChangeDesirePlan(
                                          field.id,
                                          e.target.name,
                                          e.target.value
                                        )
                                      }
                                    />
                                  </div>
                                  <div className="col-4">
                                    <button
                                      type="button"
                                      style={{ height: 40 }}
                                      className="btn btn-custom btn-custom-sm fs-16"
                                      onClick={() => handleSubmitAdsPlan(field)}
                                      disabled={
                                        `loading${field?.id}` === loadingAds ||
                                        false
                                      }
                                    >
                                      {`loading${field?.id}` === loadingAds
                                        ? "Loading..."
                                        : field.new
                                        ? `Subscribe ${
                                            +field?.desirePlan > 0
                                              ? `$${field?.desirePlan}`
                                              : ""
                                          }`
                                        : "Update"}
                                    </button>
                                    {desireAdsPlan.length > 1 && (
                                      <Trash
                                        onClick={() =>
                                          field.new
                                            ? removeAdsPlan(index)
                                            : handleDelete(field)
                                        }
                                        className="cursor-pointer"
                                        style={{
                                          marginLeft: 10,
                                          color: "red",
                                          width: 25,
                                          height: 25,
                                        }}
                                      />
                                    )}
                                  </div>
                                </>
                              )}
                            </div>
                          ))}

                        {subscriptionField.length === 0 && (
                          <div className="text-center mt-3">
                            No desire subscription plan available
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PlanDetailPage;
