import React, { useState } from "react";
import { Link } from "react-router-dom";
import DOMPurify from "dompurify";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { QuestionCircle } from "react-bootstrap-icons";
import { Button } from "react-bootstrap";
import ActivitySidebar from "../../layouts/TourSidebar";
import SearchIcon from "./../../../assets/images/admin/search.svg";
import { authCall } from "../../../services";
import AppConstants from "../../../appConstants";
import VideoTutorialPopUp from "../../admin/VideoTutorialPopUp";

const ResovaIntegrationVideo = AppConstants.tutorial.resovaIntegrationVideo;

function ResovaIntegration({ handleSubmit, loadingAdd, stepValue, setStep }) {
  const companyDetails = useSelector((state) => state.companyDetails);
  const [productCode, setProductCode] = useState("");
  const [isVariation] = useState(companyDetails?.variation_parent_id || false);
  const [variationName, setVariationName] = useState("");
  const [activityDetails, setActivityDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [videoURL, setVideoURL] = useState("");

  const handleSearchProductCode = async () => {
    toast.dismiss();
    if (isVariation && variationName === "") {
      toast.error("Please enter variation name.");
    } else if (productCode === "") {
      toast.error("Please enter api key.");
    } else {
      setLoading(true);
      const resp = await authCall("searchResovaItemsWithProxy", {
        apiKey: productCode,
      });
      if (resp?.type === "success" && !resp?.payload?.error?.message) {
        setActivityDetails(resp?.payload?.data);
        setLoading(false);
      } else if (resp?.type === "success" && resp?.payload?.error?.message) {
        setLoading(false);
        toast.error(resp?.payload?.error?.message);
      } else {
        setLoading(false);
        toast.error(resp?.message);
      }
    }
  };

  const handleClickProductCode = (item, code) => {
    const data = {
      profileLavel: stepValue,
      activityCode: code,
      activityName: item?.name,
      overview: DOMPurify.sanitize(item?.long_description),
      priceOptions: item?.pricing_categories,
      variationName: variationName,
    };
    handleSubmit(data, stepValue);
  };

  const handleClickTutorial = () => {
    setVideoURL(ResovaIntegrationVideo);
  };

  const tutoralClose = () => {
    setVideoURL("");
  };

  return (
    <>
      <div className="rezdy-integration-page py-4">
        <div className="container">
          <div className="row">
            <div className="col-md-4 col-lg-3 mb-4">
              <ActivitySidebar setStep={setStep} />
            </div>
            <div className="col-md-8 col-lg-9">
              <div className="common-info-card">
                <div className="heading-title d-flex flex-wrap justify-content-between gap-2">
                  <span>Resova Integration</span>
                  <span
                    className="cursor-pointer"
                    onClick={handleClickTutorial}
                  >
                    <QuestionCircle />
                  </span>
                </div>
                <div className="custom-field-wrapperr p-3 pb-4">
                  <div className="fs-18 fw-medium mb-4">
                    This ensures all bookings appear on your resova calendar
                  </div>
                  <div className="mb-4">
                    {isVariation && (
                      <div className="form-group mb-3">
                        <label className="form-label fs-18 text-white me-3">
                          Please enter your variation name:
                        </label>
                        <div className="d-flex flex-wrap align-items-center gap-3">
                          <div
                            className="searchfield-wrapper"
                            style={{ maxWidth: "400px", width: "100%" }}
                          >
                            <input
                              type="text"
                              className="form-control rounded-pill py-2 h-auto pe-5"
                              autoComplete="off"
                              onChange={(e) => setVariationName(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="form-group mb-0">
                      <label className="form-label fs-18 text-white me-3">
                        Please enter your api key:
                      </label>
                      <div className="d-flex flex-wrap align-items-center gap-3">
                        <div
                          className="searchfield-wrapper"
                          style={{ maxWidth: "400px", width: "100%" }}
                        >
                          <input
                            type="search"
                            className="form-control rounded-pill py-2 h-auto pe-5"
                            autoComplete="off"
                            value={productCode}
                            onChange={(e) => setProductCode(e.target.value)}
                          />
                          <img
                            src={SearchIcon}
                            className="img-responsive"
                            alt="Search Icon"
                          />
                        </div>
                        <button
                          type="button"
                          className="btn btn-custom"
                          onClick={handleSearchProductCode}
                          disabled={loading || false}
                        >
                          {loading ? "Searching..." : "Search"}
                        </button>
                      </div>
                      <Link
                        className="fs-12"
                        to="https://login.resova.com/"
                        target="_blank"
                      >
                        Click here to open your Resova account and retrieve your
                        API Key
                      </Link>

                      <div className="fs-14 mb-4">
                        Please also make sure to whitelist the IP Address:
                        69.57.162.30
                      </div>
                    </div>
                  </div>
                  {activityDetails.length > 0 && (
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Price</th>
                            <th scope="col">Code</th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {activityDetails.map((el) => {
                            const sentenceWithoutSpaces = el.name.replace(
                              /\s+/g,
                              ""
                            );
                            const code = sentenceWithoutSpaces
                              .substring(0, 5)
                              .toUpperCase();
                            return (
                              <>
                                <tr>
                                  <td>
                                    <div className="company-name">
                                      {el?.name}
                                    </div>
                                  </td>
                                  <td>
                                    {el?.pricing_categories.map((price) => (
                                      <div className="price-cover d-flex gap-3">
                                        <div className="type p-0">
                                          {price?.name}
                                        </div>
                                        <div className="amount">
                                          ${price?.single_price}
                                        </div>
                                      </div>
                                    ))}
                                  </td>
                                  <td>
                                    {code}
                                    {el.id}
                                  </td>
                                  <td>
                                    <Button
                                      className="btn btn-custom btn-custom-sm fs-16"
                                      onClick={() =>
                                        handleClickProductCode(el, code)
                                      }
                                      disabled={loadingAdd || false}
                                    >
                                      {loadingAdd ? "Adding..." : "Add"}
                                    </Button>
                                  </td>
                                </tr>
                              </>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <VideoTutorialPopUp videoURL={videoURL} closeVideo={tutoralClose} />
    </>
  );
}

export default ResovaIntegration;
