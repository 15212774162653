// TutorialComponent.jsx
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import LogoLandingImg from "./../assets/images/logo-landing.png";
import ActivitySetupImg from "./../assets/videos/ActivitySetup.jpg";
import PaymentSetupImg from "./../assets/videos/PaymentSetup.jpg";
import RezdySetupImg from "./../assets/videos/RezdySetup.jpg";
import rest from "./../assets/images/banner/nightlife-1.jpg";
import rest2 from "./../assets/images/banner/nightlife-2.jpg";
import AppConstants from "../appConstants";
import LandingPageHeader from "./layouts/LandingPageHeader";

const ACTIVITYVIDEOS = [
  {
    id: 1,
    videoSRC: AppConstants.tutorial.agentVideo,
    thumbnails: RezdySetupImg,
    title: "Rezdy Setup",
  },
  {
    id: 2,
    videoSRC: AppConstants.tutorial.activitySignup,
    thumbnails: ActivitySetupImg,
    title: "Activity Setup",
  },
  {
    id: 3,
    videoSRC: AppConstants.tutorial.paymentSetup,
    thumbnails: PaymentSetupImg,
    title: "Payment Setup",
  },
];

const RESTAURANTVIDEOS = [
  {
    id: 1,
    videoSRC: AppConstants.tutorial.activitySignup,
    thumbnails: rest,
    title: "Rezdy Setup",
  },
  {
    id: 2,
    videoSRC: AppConstants.tutorial.activitySignup,
    thumbnails: rest,
    title: "Activity Setup",
  },
  {
    id: 3,
    videoSRC: AppConstants.tutorial.activitySignup,
    thumbnails: rest,
    title: "Payment Setup",
  },
];

const NIGHLIFEVIDEOS = [
  {
    id: 1,
    videoSRC: AppConstants.tutorial.activitySignup,
    thumbnails: rest2,
    title: "Activity Setup",
  },
  {
    id: 2,
    videoSRC: AppConstants.tutorial.activitySignup,
    thumbnails: rest2,
    title: "Rezdy Setup",
  },
  {
    id: 3,
    videoSRC: AppConstants.tutorial.activitySignup,
    thumbnails: rest2,
    title: "Payment Setup",
  },
];

function TutorialComponent() {
  const [videoType, setVideoType] = useState("activity");
  const [videoList, setVideoList] = useState(ACTIVITYVIDEOS);
  const [selectedVideo, setSelectedVideo] = useState(null);

  useEffect(() => {
    if (videoType === "restaurant") {
      setVideoList(RESTAURANTVIDEOS);
    } else if (videoType === "nightlife") {
      setVideoList(NIGHLIFEVIDEOS);
    } else {
      setVideoList(ACTIVITYVIDEOS);
    }
  }, [videoType]);

  const handleVideoClick = (src) => {
    setSelectedVideo(src);
  };

  const closeVideo = () => {
    setSelectedVideo(null);
  };

  return (
    <>
      <LandingPageHeader />

      <section
        className="top-experiences profile landing"
        style={{ padding: "3rem 0 1.5rem" }}
      >
        <div className="container">
          <div className="row g-4">
            <div className="col-md-4"></div>
            <div className="col-md-4">
              <div className="landing-details">
                <Link to="/">
                  <img
                    src={LogoLandingImg}
                    className="img-fluid large11"
                    alt="LogoLandingImg"
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="quote-tutorial">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <p>
                <span>
                  Below are the videos to help you setup your profile listing on
                  the Touch-It Network
                </span>
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="quote" style={{ marginBottom: 50 }}>
        <div className="container">
          <div className="row">
            <div className="d-flex flex-wrap justify-content-center gap-3 gap-md-3 pt-4 mb-4">
              <button
                type="submit"
                className={`btn ${
                  videoType === "activity"
                    ? "btn-custom"
                    : "btn-custom-tutorial"
                }`}
                style={{ fontSize: 22 }}
                onClick={() => setVideoType("activity")}
              >
                Activity Provider
              </button>

              <button
                type="submit"
                className={`btn ${
                  videoType === "restaurant"
                    ? "btn-custom"
                    : "btn-custom-tutorial"
                }`}
                style={{ fontSize: 22 }}
                onClick={() => setVideoType("restaurant")}
              >
                Restaurant
              </button>
              <button
                type="submit"
                className={`btn ${
                  videoType === "nightlife"
                    ? "btn-custom"
                    : "btn-custom-tutorial"
                }`}
                style={{ fontSize: 22 }}
                onClick={() => setVideoType("nightlife")}
              >
                Nightlife
              </button>
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            {videoList.map((video) => (
              <div
                key={video.id}
                onClick={() => handleVideoClick(video.videoSRC)}
                style={{ cursor: "pointer" }}
              >
                <img
                  style={{
                    border: "2px solid #fdb539",
                    borderRadius: 15,
                    cursor: "pointer",
                  }}
                  controls
                  src={video.thumbnails}
                />
              </div>
            ))}
          </div>

          {/* {videoType === "activity" && (
            <div className="row">
              <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                <img
                  onClick={() => handleVideoClick(AgentVideo)}
                  style={{
                    border: "2px solid #fdb539",
                    borderRadius: 5,
                    cursor: "pointer",
                  }}
                  controls
                  src={RezdySetupImg}
                />
              </div>
              <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                <img
                  onClick={() => handleVideoClick(ActivitySignUpVideo)}
                  style={{
                    border: "2px solid #fdb539",
                    borderRadius: 5,
                    cursor: "pointer",
                  }}
                  controls
                  src={ActivitySetupImg}
                />
              </div>
              <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                <img
                  onClick={() => handleVideoClick(PaymentSetup)}
                  style={{
                    border: "2px solid #fdb539",
                    borderRadius: 5,
                    cursor: "pointer",
                  }}
                  controls
                  src={PaymentSetupImg}
                />
              </div>
            </div>
          )} */}
        </div>
      </section>
      {selectedVideo && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1000,
            border: "2px solid #fdb539",
            borderRadius: 5,
          }}
        >
          {" "}
          <div
            style={{
              border: "3px solid #fdb539",
              borderRadius: "15px",
              overflow: "hidden",
              maxWidth: "90%",
              maxHeight: "90%",
            }}
          >
            <button
              onClick={closeVideo}
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                backgroundColor: "rgba(255, 0, 0, 0.7)",
                color: "white",
                border: "none",
                borderRadius: "5px",
                padding: "5px 10px",
                cursor: "pointer",
                zIndex: 1001, // Ensure button is above the video
              }}
            >
              Close
            </button>
            <video
              src={selectedVideo}
              autoPlay
              controls
              style={{
                width: "100%",
                height: "auto",
                maxHeight: "90vh",
              }}
            >
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      )}
    </>
  );
}

export default TutorialComponent;
