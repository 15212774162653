import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { Check } from "react-bootstrap-icons";
import ActivityPayment from "../../Stripe/ActivityPayment";
import AppConstants from "../../appConstants";
import webServices from "../../services/webServices";
import { authCall } from "../../services";
import SplitStripeCheckout from "../../Stripe/SplitStripeCheckout";

function BookingSuccess() {
  const location = useLocation();
  const navigate = useNavigate();
  const { current } = useSelector((state) => state.user);
  const { details } = location.state || {};
  const [perHotel, setPerHotel] = useState(0);

  useEffect(() => {
    if (!details) {
      navigate(AppConstants.routes.tour.home);
    } 
  }, [details, navigate]);

  if (!details) {
    return null;
  }

  const handleClickHome = async () => {
    navigate(AppConstants.routes.home);
  };

  return (
    <>
      {console.log("details", details)}

      <div className="company-payment-page py-4">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-lg-12">
              <div className="common-info-card">
                <div className="heading-title">Booking Success</div>
                <div className="row">
                  <div className="col-md-12 col-lg-12">
                    <div className="details-wrapper">
                      <div
                        className="text-center"
                        style={{
                          color: "#00ffe7",
                          fontSize: 25,
                          fontWeight: 500,
                          marginBottom: 25,
                        }}
                      >
                        Congratulation!
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginBottom: "25px",
                        }}
                      >
                        <div
                          style={{
                            width: 70,
                            height: 70,
                            borderRadius: "50%",
                            backgroundColor: "green",
                          }}
                        >
                          <Check style={{ width: 70, height: 70 }} />
                        </div>
                      </div>
                      <div className="text-center pb-2 mb-3">
                        <div
                          className="plan"
                          style={{ color: "#fff5f5ab", fontWeight: 700 }}
                        >
                          Booking Ref. {details?.id}
                        </div>
                        <div
                          className="plan mt-3"
                          style={{ fontWeight: 700, fontSize: 25 }}
                        >
                          You successfully created your booking
                        </div>
                        <div className="btn-wrapper mt-5 text-center">
                          <button
                            type="submit"
                            className="btn btn-custom"
                            id="submit"
                            onClick={handleClickHome}
                          >
                            Go Home
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BookingSuccess;
