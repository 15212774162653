import React, { useEffect, useRef, useState } from "react";
import rectangle1 from "../../../../assets/images/site/Rectangle 14.png";
import EventLogo1 from "../../../../assets/images/site/EventLogo1.png";
import EventLogo2 from "../../../../assets/images/site/EventLogo2.png";
import CalendertIcon from "../../../../assets/images/site/CalenderIcon.png";
import uploadLogo from "../../../../assets/images/site/uploadLogo.png";
import StopwatchIcon from "../../../../assets/images/site/StopwatchIcon.png";
import EventLogo3 from "../../../../assets/images/site/EventLogo3.png";
import EventLogo4 from "../../../../assets/images/site/EventLogo4.png";
import "./EventDetails2.scss";

const EventDetails2 = () => {
  const maxDescriptionLength = 200;
  const [description, setDescription] = useState("");

  useEffect(() => {
    const datePicker = document.getElementById("datePicker");
    const formattedDateElement = document.getElementById("formattedDate");

    // Check if the elements exist before adding event listeners
    if (datePicker) {
      datePicker.addEventListener("change", function () {
        const selectedDate = new Date(this.value);
        const options = { day: "numeric", month: "long", year: "numeric" };
        const formattedDate = selectedDate.toLocaleDateString("en-GB", options);

        // Display the formatted date
        if (formattedDateElement) {
          formattedDateElement.textContent = formattedDate;
        }
      });
    }

    // Cleanup function to remove event listeners when component unmounts
    return () => {
      if (datePicker) {
        datePicker.removeEventListener("change", () => {});
      }
    };
  }, []); // Empty dependency array to run only once

  const [time, setTime] = useState("");
  const timePickerRef = useRef(null);
  const [formattedDate, setFormattedDate] = useState("");

  const handleDateChange = (event) => {
    const selectedDate = new Date(event.target.value);
    const options = { day: "numeric", month: "long", year: "numeric" };
    const formattedDate = selectedDate.toLocaleDateString("en-GB", options); // Use 'formattedDate' here
    setFormattedDate(formattedDate); // Set the state with 'formattedDate'
  };

  const openCalendar = () => {
    document.getElementById("evnetdatePicker2").focus(); // This triggers the date picker to open
  };

  const handleTimeChange = (event) => {
    setTime(event.target.value);
  };

  const handleClick = () => {
    const timePicker = document.getElementById("eventTimePicker2");
    if (timePicker) {
      timePicker.showPicker();
    } else {
      document.getElementById("eventTimePicker2").click();
    }
  };

  const handleDescriptionChange = (e) => {
    const input = e.target.value;
    if (input.length <= maxDescriptionLength) {
      setDescription(input);
    }
  };

 

  return (
    <div
      className="eventDetails_wrapper mt-5 container"
      style={{ position: "relative" }}
    >
      <div className="gradient-line"></div>
      <img src={rectangle1} alt="Book Now" className="centered-image" />
      <div className="overlay-text" style={{ fontFamily: "Inter" }}>
        EVENT DETAILS
      </div>
      <div className="contact-form-wrapper">
        <form className="contact-form mb-5" style={{ margin: "0px 35px" }}>
          <div className="text-content">
            <p
              className="text-center mb-0 mt-3"
              style={{ fontSize: "17px", color: "#BABABA" }}
            >
              Please enter your weekly promotion details : (for example :
              Wednesday night womens night)
            </p>
          </div>
          <div className="row mt-0">
            <div
              className="col-6 col-lg-6 d-flex flex-column align-items-center justify-content-between gap-4 mt-4"
              style={{ fontFamily: "Poppins", color: "#BABABA" }}
              id="contacttWrapper"
            >
              <div className="form-group ms-3" id="eventform2">
                <div className="input-with-icon">
                  <img
                    src={EventLogo1}
                    alt="Name Icon"
                    className="input-icon"
                    height={26}
                    width={25}
                    style={{ left: '3%'}}
                  />
                  <input
                    type="text"
                    id="firstName"
                    name="firstName"
                    placeholder="Promotion name"
                    required
                  />
                </div>
              </div>

              <div className="form-group ms-3" id="eventform2">
                <div className="input-with-icon">
                  <img
                    src={CalendertIcon}
                    alt="Name Icon"
                    className="input-icon"
                    height={21}
                    width={25}
                    style={{ left: '3%'}}
                  />
                  <input
                    type="date"
                    id="evnetdatePicker2"
                    required
                    onChange={handleDateChange} // Handle date change
                    onFocus={() =>
                      document.getElementById("evnetdatePicker2").showPicker()
                    } // Open calendar when the field is focused
                    style={{ cursor: "pointer" }}
                  />
                </div>
              </div>
            </div>

            <div
              className="col-6 col-lg-6 d-flex flex-column align-items-center justify-content-between gap-4 mt-4"
              style={{ fontFamily: "Poppins", color: "#BABABA" }}
              id="contacttWrapper"
            >
              <div className="form-group ms-3" id="eventform2">
                <div
                  className="input-with-icon"
                  style={{ position: "relative" }}
                >
                  <img
                    src={EventLogo2}
                    alt="MenuLogo"
                    style={{
                      position: "absolute",
                      left: "5px",
                      top: "50%",
                      transform: "translateY(-50%)",
                      width: "25px", // Adjust the size as needed
                      height: "25px",
                      zIndex: 3,
                      left:"3%"
                    }}
                  />
                  {/* Placeholder Text */}
                  <span
                    className="placeholder-text"
                    style={{
                      position: "absolute",
                      left: "50px",
                      top: "50%",
                      transform: "translateY(-50%)",
                      color: "#BABABA",
                      pointerEvents: "none", // Make sure the text doesn't interfere with clicking
                      fontSize: "16px",
                    }}
                  >
                    Promotion Photo
                  </span>

                  {/* Hidden File Input */}
                  <input
                    type="file"
                    id="fileUpload"
                    name="fileUpload"
                    className="upload-input"
                    style={{
                      width: "100%",
                      opacity: 0,
                      cursor: "pointer",
                      zIndex: 2,
                    }}
                    required
                  />

                  {/* Custom Button with Icon */}
                  <button
                    type="button"
                    className="custom-upload-btn"
                    onClick={() =>
                      document.getElementById("fileUpload").click()
                    }
                    style={{
                      position: "absolute",
                      right: "10px", // Align button to the right
                      top: "50%",
                      transform: "translateY(-50%)",
                      background: "none",
                      border: "none",
                      cursor: "pointer",
                      zIndex: 3,
                    }}
                  >
                    <img
                      src={uploadLogo}
                      alt="Upload Icon"
                      style={{ width: "46px", height: "47px", margin: "2px" }}
                    />
                  </button>
                </div>
              </div>

              <div className="form-group ms-3" id="eventform2">
                <div className="input-with-icon" onClick={handleClick}>
                  <img
                    src={StopwatchIcon}
                    alt="Name Icon"
                    className="input-icon"
                    height={25}
                    width={25}
                    style={{ left: '3%'}}
                  />
                  <input
                    id="timeInput"
                    type="text"
                    placeholder="20:00"
                    value={time}
                    readOnly
                    style={{ cursor: "pointer" }} // Change cursor to pointer
                  />
                  <input
                    type="time"
                    id="eventTimePicker2"
                    value={time}
                    onChange={handleTimeChange}
                    style={{
                      position: "absolute",
                      left: 0,
                      top: 0,
                      opacity: 0,
                      cursor: "pointer",
                    }} // Hide the time input
                  />
                </div>
              </div>
            </div>

            <div
              className="col-12 col-lg-12 d-flex flex-column align-items-center justify-content-between gap-4 mt-4"
              style={{ fontFamily: "Poppins", color: "#BABABA" }}
              id="contacttWrapper"
            >
              <div className="form-group ms-3" id="eventdesc">
                <div className="input-with-icon">
                  <img
                    src={EventLogo3}
                    alt="Name Icon"
                    className="input-icon"
                    height={30}
                    width={25}
                    style={{ left: '1.5%'}}
                  />
                  <input
                    type="text"
                    id="location"
                    name="location"
                    placeholder="Promotion Description"
                    value={description}
                    onChange={handleDescriptionChange}
                    required
                  />
                </div>
                <small style={{ color: "#BABABA", fontSize: "14px" }}>
                  {description.length}/{maxDescriptionLength} characters
                </small>
              </div>
            </div>

            <div
              className="col-12 col-lg-8 d-flex flex-column align-items-center mt-2"
              style={{ fontFamily: "Poppins", color: "#BABABA" }}
              id="contacttWrapper"
            >
              <div
                className="form-group ms-3"
                id="locationInput"
                style={{ maxWidth: "100%" }}
              >
                <div className="input-with-icon" style={{ maxWidth: "100%" }}>
                  <img
                    src={EventLogo4}
                    alt="Name Icon"
                    className="input-icon"
                    height={25}
                    width={25}
                    style={{ left: '2%'}}
                  />
                  <input
                    type="text"
                    id="location"
                    name="location"
                    placeholder="Promotion Extras : (two free beers on entry)"
                    required
                  />
                </div>
              </div>
            </div>

            <div
              className="col-12 col-lg-4 d-flex flex-column align-items-center mt-2"
              style={{ fontFamily: "Poppins", color: "#BABABA" }}
              id="contacttWrapper"
            >
              <button className="eventButton ms-3">
                ADD ANOTHER PROMOTION
              </button>
            </div>
          </div>
        </form>
      </div>
      <div style={{ textAlign: "center" }}>
        <button className="menuButton text-center mt-5">BOOK NOW</button>
      </div>
    </div>
  );
};

export default EventDetails2;
