import React, { useState, useRef } from "react";
import { toast } from "react-toastify";
import SimpleReactValidator from "simple-react-validator";
import { useDispatch } from "react-redux";
import { setUser } from "../redux/actions/user.actions";
import authService from "../services/authServices";
import { useNavigate } from "react-router-dom";
import webServices from "../services/webServices";
import AppConstants from "../appConstants";
import { handleNumericInput } from "./util/utils";

function Register() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [, forceUpdate] = useState();
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    userName: "",
    firstName: "",
    lastName: "",
    mobile: "",
    email: "",
    password: "",
    touchIdRep: "",
  });

  const validator = useRef(new SimpleReactValidator());

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validator.current.allValid()) {
      setLoading(true);
      const resp = await webServices.register(values);
      if (resp.type === "success") {
        setLoading(false);
        authService.setToken(resp?.payload?.token);
        dispatch(setUser(resp?.payload?.user));
        navigate(AppConstants.routes.companyType);
      } else {
        setLoading(false);
        toast.dismiss();
        toast.error(resp?.errormessage);
      }
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };

  const handleChangePhone = (e) => {
    const inputValue = handleNumericInput(e.target.value);
    if (inputValue !== null && inputValue.length < 11) {
      setValues({
        ...values,
        mobile: inputValue,
      });
    }
  };

  return (
    <>
      {console.log("values", values)}
      <div className="login-page py-4">
        <div className="container">
          <div className="form-wrapper">
            <div className="heading text-center">Register</div>
            <form onSubmit={handleSubmit} className="custom-field-wrapper">
              <div className="form-group">
                <label htmlFor="userName">User Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="userName"
                  autoComplete="off"
                  onChange={(e) =>
                    setValues({
                      ...values,
                      userName: e.target.value,
                    })
                  }
                />
                {validator.current.message(
                  "user name",
                  values.userName,
                  "required"
                )}
              </div>
              <div className="form-group">
                <label htmlFor="firstName">First Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="firstName"
                  autoComplete="off"
                  onChange={(e) =>
                    setValues({
                      ...values,
                      firstName: e.target.value,
                    })
                  }
                />
                {validator.current.message(
                  "first name",
                  values.firstName,
                  "required"
                )}
              </div>
              <div className="form-group">
                <label htmlFor="lastName">Last Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="lastName"
                  autoComplete="off"
                  onChange={(e) =>
                    setValues({
                      ...values,
                      lastName: e.target.value,
                    })
                  }
                />
                {validator.current.message(
                  "last name",
                  values.lastName,
                  "required"
                )}
              </div>
              <div className="form-group">
                <label htmlFor="mobile">Mobile</label>
                <input
                  type="text"
                  className="form-control"
                  id="mobile"
                  autoComplete="off"
                  value={values.mobile}
                  onChange={handleChangePhone}
                />
                {validator.current.message(
                  "mobile number",
                  values.mobile,
                  "required"
                )}
              </div>
              <div className="form-group">
                <label htmlFor="userEmail">Email</label>
                <input
                  type="email"
                  className="form-control"
                  id="userEmail"
                  autoComplete="off"
                  onChange={(e) =>
                    setValues({ ...values, email: e.target.value })
                  }
                />
                {validator.current.message(
                  "email",
                  values.email,
                  "required|email"
                )}
              </div>
              <div className="form-group">
                <label htmlFor="userPassword">Password</label>
                <input
                  type="password"
                  className="form-control"
                  id="userPassword"
                  autoComplete="off"
                  onChange={(e) =>
                    setValues({
                      ...values,
                      password: e.target.value,
                    })
                  }
                />
                <div className="text-custom-primary fs-12 mt-1">
                  <i>
                    Minimum 8 characters long, 1 upper and 1 lowercase letter, 1
                    number.
                  </i>
                </div>

                {validator.current.message(
                  "password",
                  values.password,
                  "required|min:8|max:20|regex:^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d).+$",
                  {
                    messages: {
                      regex:
                        "Password must be 8-20 characters long and include at least 1 uppercase letter, 1 lowercase letter, and 1 number.",
                    },
                  }
                )}
              </div>

              {/* <div className="form-group d-flex align-items-center gap-3">
                <input
                  type="radio"
                  className="custom-radio m-0 cursor-pointer"
                  name="restaurantCategories"
                  value="Dakota"
                  onChange={(e) =>
                    setValues({
                      ...values,
                      touchIdRep: e.target.value,
                    })
                  }
                />
                <label className="m-0" htmlFor="Decoda">
                  Dakota
                </label>

                <input
                  type="radio"
                  className="custom-radio m-0 cursor-pointer"
                  name="restaurantCategories"
                  value="Helena"
                  onChange={(e) =>
                    setValues({
                      ...values,
                      touchIdRep: e.target.value,
                    })
                  }
                />
                <label className="m-0" htmlFor="Helena">
                  Helena
                </label>
              </div>
              <div style={{ marginTop: !values.touchIdRep ? "-10px" : 0 }}>
                {validator.current.message("", values.touchIdRep, "required")}
              </div> */}
              <div className="btn-wrapper text-center mt-4 pt-2">
                <button
                  type="submit"
                  className="btn btn-custom"
                  disabled={loading || false}
                >
                  {loading ? "Registering..." : "Register"}
                </button>
              </div>
              <div
                className="text-center pt-3"
                style={{ fontSize: 22, marginTop: 12 }}
              >
                Have an account?{" "}
                <span
                  className="add-another"
                  onClick={() => navigate(AppConstants.routes.login)}
                >
                  Login
                </span>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default Register;
