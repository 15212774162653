import React, { useEffect, useState, useRef } from "react";
import NightLifeSidebar from "../layouts/NightLifeSidebar";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import webServices from "../../services/webServices";
import AppConstants from "../../appConstants";
import checkIcon from "./../../assets/images/admin/check-icon.png";
import uploadIcon from "./../../assets/images/upload-icon.svg";
import CropperModal from "../CropperModal";

function EventDetails() {
  const [profileImage, setProfileImage] = useState("");
  const [bannerField, setBannerField] = useState([{ id: 1, value: "" }]);
  const [isBanner, setIsBanner] = useState(0);
  const [loading, setLoading] = useState(false);
  const [background, setBackground] = useState([]);
  const [backgroundImgPreview, setBackgroundImgPreview] = useState("");
  const navigate = useNavigate();
  const [highlights, setHighlights] = useState([
    { id: 1, value: "" },
    { id: 2, value: "" },
    { id: 3, value: "" },
    { id: 4, value: "" },
    { id: 5, value: "" },
    { id: 6, value: "" },
  ]);
  const [exclusions, setExclusions] = useState([
    { id: 1, value: "" },
    { id: 2, value: "" },
    { id: 3, value: "" },
  ]);

  const [values, setValues] = useState({
    name: "",
    coast: "",
    startDate: moment().format("Y-MM-DD"),
    recurring: "",
    startTime: "12:00",
    endTime: "23:45",
    highlights: [],
    exclusions: [],
    overview: "",
    profileImage: "",
    bannerImage: "",
    offerText: "",
    offerText2: "",
    backgroundImage: "",
    isBackgroundImageUpload: false,
  });

  useEffect(() => {
    async function fetchData() {
      const resp = await webServices.getNightLifeBackground();
      if (resp.type === "success") {
        const data = resp.payload[0];
        setBackground(resp.payload);
        setBackgroundImgPreview(data.image_path);
        setValues({ ...values, backgroundImage: data.name });
      }
    }
    fetchData();
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name.includes(".")) {
      const [parent, child] = name.split(".");
      setValues((prevValues) => ({
        ...prevValues,
        [parent]: {
          ...prevValues[parent],
          [child]: value,
        },
      }));
    } else if (name === "startDate") {
      setValues({ ...values, [name]: value, recurring: "" });
    } else {
      setValues((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
    }
  };

  const handleChangeHighlight = (id, value) => {
    setHighlights(
      highlights.map((field) =>
        field.id === id ? { ...field, value: value } : field
      )
    );
  };

  const handleChangeExclusion = (id, value) => {
    setExclusions(
      exclusions.map((field) =>
        field.id === id ? { ...field, value: value } : field
      )
    );
  };

  const handleSubmit = async (val) => {
    values.nightLifeId = 1;
    values.highlights = highlights;
    values.exclusions = exclusions;
    values.profileImage = profileImage;
    values.bannerImage = bannerField;
    values.backgroundImageOther = backgroundImgPreview;

    setLoading(true);
    const resp = await webServices.createEvents(values);
    if (resp.type === "success") {
      setLoading(false);
      restForm();
      localStorage.setItem("nightLifeDetails", JSON.stringify(resp.payload));
      if (val === "addEvent") {
        navigate(AppConstants.routes.nightLife.eventDetails);
      } else {
        navigate(AppConstants.routes.nightLife.payments);
      }
    }
  };

  const handleChangeRecurring = (event) => {
    const { checked } = event.target;
    if (checked && values.startDate !== "") {
      const day = moment(values.startDate).format("dddd");
      setValues({ ...values, recurring: day });
    } else {
      setValues({ ...values, recurring: "" });
    }
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleChangeBackground = (event) => {
    const selectedValue = event.target.value;
    const selectedObject = background.find(
      (option) => option.image === selectedValue
    );

    setBackgroundImgPreview(selectedObject.image_path);
    setValues({ ...values, backgroundImage: selectedObject.image });
  };

  const addBannerField = () => {
    const newFields = [];
    const newField = { id: bannerField.length + 1, value: "" };
    newFields.push(newField);
    setBannerField([...bannerField, ...newFields]);
  };

  //########## CROPPER #####################
  const fileInputRef = useRef(null);
  const fileInputBannerRef = useRef(null);
  const [imageForCrop, setImageForCrop] = useState("");
  const [show, setShow] = useState(false);
  const handleBrowseClick = (type) => {
    if (type === "banner") {
      fileInputBannerRef.current.click();
    } else {
      fileInputRef.current.click();
    }
  };

  const onChange = (id, e) => {
    e.preventDefault();
    if (id !== "") {
      setIsBanner(id);
    }

    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setImageForCrop(reader.result);
    };
    reader.readAsDataURL(files[0]);
    setShow(true);
  };

  const getCropData = (val) => {
    if (isBanner === 0) {
      setProfileImage(val);
    } else if (isBanner === "bg") {
      setBackgroundImgPreview(val);
    } else {
      setBannerField(
        bannerField.map((field) =>
          field.id === isBanner ? { ...field, value: val } : field
        )
      );
    }
    setIsBanner(0);
  };

  const removeBannerImage = (index) => {
    const updatedFiles = bannerField.filter((_, i) => i !== index);
    setBannerField(updatedFiles);
  };

  const restForm = () => {
    setValues({
      ...values,
      name: "",
      coast: "",
      startDate: moment().format("Y-MM-DD"),
      recurring: "",
      startTime: "12:00",
      endTime: "23:45",
      highlights: [],
      exclusions: [],
      overview: "",
      profileImage: "",
      bannerImage: "",
      offerText: "",
      offerText2: "",
      backgroundImage: background[0].name,
      isBackgroundImageUpload: false,
    });
    setHighlights([
      { id: 1, value: "" },
      { id: 2, value: "" },
      { id: 3, value: "" },
      { id: 4, value: "" },
      { id: 5, value: "" },
      { id: 6, value: "" },
    ]);
    setExclusions([
      { id: 1, value: "" },
      { id: 2, value: "" },
      { id: 3, value: "" },
    ]);
    setProfileImage("");
    setBannerField([{ id: 1, value: "" }]);
    setIsBanner(0);
    setBackgroundImgPreview(background[0].image_path);
    window.scrollTo(0, 0);
  };
  return (
    <>
      <div className="restaurant-details-page venue-details-page py-4">
        <div className="container custom-container">
          <div className="row">
            <div className="col-md-4 col-lg-3 mb-4">
              <NightLifeSidebar />
            </div>
            <div className="col-md-8 col-lg-9">
              <div className="common-info-card">
                <div className="heading-title">
                  Please Enter Your Event Details:
                </div>
                <div className="details-wrapper">
                  <div className="sub-title mb-3">
                    These details will appear on your activity listing
                  </div>
                  <div action="" className="custom-field-wrapperr">
                    <div className="info-cover mb-4">
                      <div className="main-heading2 mb-2">Event Details:</div>
                      <div className="row event-block">
                        <div className="col-6 col-lg-4">
                          <div className="form-group">
                            <label htmlFor="eventName" className="mb-1">
                              Event Name :
                            </label>
                            <input
                              type="text"
                              className="form-control rounded-pill"
                              id="eventName"
                              placeholder=""
                              autoComplete="off"
                              name="name"
                              value={values.name}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-6 col-lg-4">
                          <div className="form-group">
                            <label htmlFor="eventCost" className="mb-1">
                              Event Cost :
                            </label>
                            <input
                              type="number"
                              className="form-control rounded-pill"
                              id="eventCost"
                              placeholder=""
                              autoComplete="off"
                              name="coast"
                              value={values.coast}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row event-block">
                        <div className="col-lg-4">
                          <div className="form-group">
                            <label htmlFor="eventStartDate" className="mb-1">
                              Event Date :
                            </label>
                            <input
                              type="date"
                              className="form-control rounded-pill"
                              autoComplete="off"
                              name="startDate"
                              min={moment().format("Y-MM-DD")}
                              onChange={handleChange}
                              value={values.startDate}
                            />
                          </div>
                          <div className="form-group">
                            <div class="d-flex align-items-center gap-2">
                              <input
                                type="checkbox"
                                class="custom-checkbox m-0"
                                name="recurring"
                                id="Recurring"
                                checked={values.recurring}
                                onChange={handleChangeRecurring}
                              />
                              <label class="m-0" htmlFor="Recurring">
                                Recurring Weekly
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="col-6 col-lg-4">
                          <div className="form-group">
                            <label htmlFor="eventStartTime" className="mb-1">
                              Event Start Time :
                            </label>

                            <input
                              type="time"
                              className="form-control rounded-pill"
                              autoComplete="off"
                              name="startTime"
                              onChange={handleChange}
                              value={values.startTime}
                            />
                          </div>
                        </div>
                        <div className="col-6 col-lg-4">
                          <div className="form-group">
                            <label htmlFor="eventStartTime" className="mb-1">
                              Event End Time :
                            </label>
                            <input
                              type="time"
                              className="form-control rounded-pill"
                              autoComplete="off"
                              name="endTime"
                              onChange={handleChange}
                              value={values.endTime}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="info-cover mb-1 mb-lg-4 pb-2">
                      <div className="main-heading2 text-white mb-2 pb-1">
                        Highlight:
                      </div>
                      <div className="row">
                        {highlights.map((field, index) => (
                          <div key={index} className="col-sm-6 col-lg-4">
                            <div className="form-group d-flex align-items-center gap-2">
                              <div className="icon">
                                <img
                                  src={checkIcon}
                                  className="img-responsive"
                                  alt="Check"
                                />
                              </div>
                              <input
                                type="text"
                                className="form-control rounded-pill"
                                placeholder="Hightlight"
                                autoComplete="off"
                                value={field.value}
                                onChange={(e) =>
                                  handleChangeHighlight(
                                    field.id,
                                    e.target.value
                                  )
                                }
                              />
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>

                    <div className="info-cover mb-3 mb-lg-4">
                      <div className="main-heading2 text-white mb-2 pb-1">
                        Exclusion:
                      </div>
                      <div className="row">
                        {exclusions.map((field, index) => (
                          <div key={index} className="col-sm-6 col-lg-4">
                            <div className="form-group d-flex align-items-center gap-2">
                              <div className="icon">
                                <img
                                  src={checkIcon}
                                  className="img-responsive"
                                  alt="Check"
                                />
                              </div>
                              <input
                                type="text"
                                className="form-control rounded-pill"
                                id=""
                                placeholder="Exclusion"
                                autoComplete="off"
                                value={field.value}
                                onChange={(e) =>
                                  handleChangeExclusion(
                                    field.id,
                                    e.target.value
                                  )
                                }
                              />
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>

                    <div className="info-cover mb-4 pb-2">
                      <div className="main-heading2 text-white mb-2 pb-1">
                        Overview:
                      </div>
                      <textarea
                        id="overview"
                        rows="5"
                        className="form-control"
                        placeholder="Please provide a 75-to-150-word descripition of activity."
                        autoComplete="off"
                        name="overview"
                        value={values.overview}
                        onChange={handleChange}
                      ></textarea>
                    </div>

                    <div className="event-details-page info-cover mb-4">
                      <div className="row">
                        <div className="col-lg-5 mb-4 mb-lg-0">
                          <div className="info-cover">
                            <div className="main-heading2 mb-1">Images:</div>
                            <div className="profile-cover mb-3">
                              <div className="main-heading2 text-white mb-1">
                                Profile Images:
                              </div>
                              <p className="fs-12">
                                <i>
                                  (This is the image shown on the search results
                                  page)
                                </i>
                              </p>
                              {profileImage === "" && (
                                <div className="custom-input-file mb-2">
                                  <input
                                    type="file"
                                    name="img1"
                                    id="file-111"
                                    className="inputfile"
                                    onChange={(e) => onChange("", e)}
                                  />
                                  <label htmlFor="file-111" className="m-0">
                                    <span></span>
                                    <div className="icon">
                                      <img src={uploadIcon} alt="upload icon" />
                                    </div>
                                  </label>
                                </div>
                              )}
                              {profileImage !== "" && (
                                <>
                                  <div
                                    style={{ width: "120px" }}
                                    className="img-wrapper"
                                  >
                                    <img
                                      src={profileImage}
                                      className="img-fluid rounded"
                                      alt="cropped"
                                    />
                                    <input
                                      type="file"
                                      ref={fileInputRef}
                                      style={{ display: "none" }}
                                      onChange={(e) => onChange("", e)}
                                    />
                                  </div>

                                  <div className="d-flex flex-wrap align-items-center gap-2 mt-2">
                                    <button
                                      type="button"
                                      className="btn btn-custom btn-custom-sm"
                                      onClick={() =>
                                        handleBrowseClick("profile")
                                      }
                                    >
                                      Change
                                    </button>
                                  </div>
                                </>
                              )}
                            </div>
                            <div className="banner-cover mb-2">
                              <div className="main-heading2 text-white mb-1">
                                Banner Images:
                              </div>
                              <p className="fs-12">
                                <i>
                                  (These images will be displayed at the top of
                                  the page)
                                </i>
                              </p>
                              <div className="wrapper">
                                {bannerField.map((el, index) => (
                                  <div key={el.id}>
                                    {el.value === "" && (
                                      <div className="custom-input-file mb-2">
                                        <input
                                          type="file"
                                          id={`filee-${el.id}`}
                                          className="inputfile"
                                          onChange={(e) => onChange(el.id, e)}
                                        />
                                        <label
                                          htmlFor={`filee-${el.id}`}
                                          className="m-0"
                                        >
                                          <span></span>
                                          <div className="icon">
                                            <img
                                              src={uploadIcon}
                                              alt="upload icon"
                                            />
                                          </div>
                                        </label>
                                      </div>
                                    )}
                                    {el.value !== "" && (
                                      <>
                                        <div
                                          style={{ width: "120px" }}
                                          className="img-wrapper"
                                        >
                                          <img
                                            src={el.value}
                                            className="img-fluid rounded"
                                            alt="cropped"
                                          />
                                          <input
                                            type="file"
                                            ref={fileInputBannerRef}
                                            style={{ display: "none" }}
                                            onChange={(e) => onChange(el.id, e)}
                                          />
                                        </div>

                                        <div className="d-flex flex-wrap align-items-center gap-2 mt-2 mb-3">
                                          <button
                                            type="button"
                                            className="btn btn-custom btn-custom-sm"
                                            onClick={() =>
                                              handleBrowseClick("banner")
                                            }
                                          >
                                            Change
                                          </button>
                                          <button
                                            class="btn btn-sm btn-danger"
                                            onClick={() =>
                                              removeBannerImage(index)
                                            }
                                          >
                                            <i className="bi bi-trash3-fill"></i>{" "}
                                            Remove
                                          </button>
                                        </div>
                                      </>
                                    )}
                                  </div>
                                ))}
                              </div>
                              <div
                                className="add-another fw-medium text-end mt-3"
                                onClick={addBannerField}
                              >
                                <span
                                  className="glyphicon glyphicon-plus"
                                  aria-hidden="true"
                                ></span>{" "}
                                Add Another Banner Image
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-7">
                          <div className="info-cover">
                            <div className="main-heading2 mb-1">
                              Drink Offer:
                            </div>
                            <div className="offer-cover">
                              <div className="main-heading2 text-white mb-1">
                                Offer Text:
                              </div>
                              <p className="fs-12">
                                <i>
                                  (Describe your offer below in 5 words or less.
                                  For example Three Free Cocktails)
                                </i>
                              </p>
                              <div className="row custom-row">
                                <div className="col-sm-6">
                                  <div className="form-group">
                                    <input
                                      type="text"
                                      id=""
                                      className="form-control rounded-pill"
                                      placeholder=""
                                      autoComplete="off"
                                      name="offerText"
                                      maxLength={10}
                                      value={values.offerText}
                                      onChange={handleChange}
                                    />
                                  </div>
                                </div>
                                <div className="col-sm-6">
                                  <div className="form-group">
                                    <input
                                      type="text"
                                      id=""
                                      className="form-control rounded-pill"
                                      placeholder=""
                                      autoComplete="off"
                                      name="offerText2"
                                      maxLength={10}
                                      value={values.offerText2}
                                      onChange={handleChange}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="banner-cover mb-2">
                              <div className="main-heading2 text-white mb-1">
                                Select Background Images:
                              </div>
                              <p className="fs-12">
                                <i>
                                  (Select the background image from the list
                                  below)
                                </i>
                              </p>
                              <div className="row">
                                <div className="col-sm-6">
                                  <div className="form-group">
                                    <select
                                      className="form-select rounded-pill"
                                      name="backgroundImage"
                                      onChange={handleChangeBackground}
                                    >
                                      <option value="" hidden="">
                                        Select
                                      </option>
                                      {background.map((el, index) => (
                                        <option
                                          key={index}
                                          value={el.image}
                                          selected={
                                            el.name ===
                                              values.backgroundImage || false
                                          }
                                        >
                                          {el.name}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                  <div className="form-group">
                                    <div className="custom-input-file mb-2">
                                      <input
                                        type="file"
                                        name="img2"
                                        id="file-2"
                                        className="inputfile"
                                        onChange={(e) => {
                                          onChange("bg", e);
                                          setValues({
                                            ...values,
                                            isBackgroundImageUpload: true,
                                          });
                                        }}
                                      />
                                      <label htmlFor="file-2" className="m-0">
                                        <span></span>
                                        <div className="icon">
                                          <img
                                            src={uploadIcon}
                                            alt="upload icon"
                                          />
                                        </div>
                                      </label>
                                    </div>
                                  </div>
                                </div>
                                {backgroundImgPreview && (
                                  <div className="col-sm-6">
                                    <div
                                      className="wrapper"
                                      style={{ maxWidth: "200px" }}
                                    >
                                      <div className="preview-box rounded-3">
                                        <img
                                          src={backgroundImgPreview}
                                          className="img-fluid border-0 rounded-3"
                                          alt={backgroundImgPreview}
                                        />
                                        <div
                                          className="moon-wrapper position-absolute start-0 top-0 h-100"
                                          style={{
                                            backgroundImage: `url(${require("../../assets/images/gallery/half-moon.png")})`,
                                          }}
                                        >
                                          <div className="rel-text">
                                            <div className="text-truncate">
                                              {values.offerText}
                                            </div>
                                            <div className="text-truncate mb-1">
                                              {values.offerText2}
                                            </div>
                                            <button
                                              type="button"
                                              className="btn btn-custom2 rounded-0"
                                              style={{ marginLeft: "-5px" }}
                                            >
                                              Click to Reveal
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="text-center fw-medium mt-2">
                                        <i>Preview</i>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="btn-wrapper d-flex flex-column flex-md-row flex-md-wrap justify-content-between gap-3">
                      <button
                        type="submit"
                        className="btn btn-custom"
                        onClick={() => handleSubmit("addEvent")}
                        disabled={loading || false}
                      >
                        {loading ? "SUBMITTING..." : "ADD EVENT NOW"}
                      </button>

                      <button
                        type="submit"
                        className="btn btn-custom"
                        onClick={() => handleSubmit("submit")}
                        disabled={loading || false}
                      >
                        {loading ? "SUBMITTING..." : "SUBMIT"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <CropperModal
        show={show}
        handleClose={handleClose}
        image={imageForCrop}
        sendCropedDataToParent={getCropData}
      />
    </>
  );
}

export default EventDetails;
