import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, useStripe, useElements, PaymentElement, LinkAuthenticationElement } from '@stripe/react-stripe-js';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISH_KEY);

const CheckoutForm = () => {
    const stripe = useStripe();
    const elements = useElements();
    const [email, setEmail] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!stripe || !elements) {
            return;
        }

        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: elements.getElement(PaymentElement),
            billing_details: {
                email,
            },
        });

        if (error) {
            setErrorMessage(error.message);
            return;
        }

        // const response = await fetch('/api/create-subscription', {
        //     method: 'POST',
        //     headers: {
        //         'Content-Type': 'application/json',
        //     },
        //     body: JSON.stringify({
        //         email,
        //         payment_method: paymentMethod.id,
        //     }),
        // });

        // const subscription = await response.json();

        // if (subscription.error) {
        //     setErrorMessage(subscription.error);
        // } else {
        //     setSuccessMessage('Subscription successful!');
        // }
    };

    return (
        <form onSubmit={handleSubmit}>
           
            <PaymentElement id="payment-element" />
            <button type="submit" disabled={!stripe}>
                Subscribe
            </button>
            {errorMessage && <div>{errorMessage}</div>}
            {successMessage && <div>{successMessage}</div>}
        </form>
    );
};

const ActiityNewCheckout = () => {
  return (
    <Elements stripe={stripePromise}>
        <CheckoutForm />
    </Elements>
  );
};

export default ActiityNewCheckout;
