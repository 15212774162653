import React, { useEffect, useState } from "react";
import AdminSidebar from "../../layouts/AdminSidebar";
import moment from "moment";
import { toast } from "react-toastify";
import { Badge, Row, Col, Button } from "react-bootstrap";
import { PencilSquare, Trash, Plus } from "react-bootstrap-icons";
import { useNavigate, useLocation } from "react-router-dom";
import { authCall } from "../../../services";
import AppConstants from "../../../appConstants";
import webServices from "../../../services/webServices";

function EventListing() {
  const navigate = useNavigate();
  const location = useLocation();
  const { type } = location.state || {};
  const [loading, setLoading] = useState(false);
  const [evenList, setEvenList] = useState([]);

  const eventListFun = async () => {
    const nightLifeId = 1;
    const resp = await webServices.getNightLifeEvent(nightLifeId);
    if (resp?.type === "success") {
      setEvenList(resp?.payload)
      setLoading(false);
    }
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    eventListFun();
  }, [type]);

  const handleChangeStatus = async (id, status) => {
    const resp = await authCall("changeEventStatus", { id, status });
    if (resp?.type === "success") {
      eventListFun();
    }
  };

  const handleEdit = (item) => {
    navigate(AppConstants.routes.admin.updateEventDtails, { state: { item } });
  };

  const handleAdd = () => {
    navigate(AppConstants.routes.admin.updateEventDtails);
  };

  const handleDelete = (el) => {
    toast.warning(
      <div>
        <p>Are you sure you want to delete?</p>
        <Button
          variant="success"
          className="btn-sm"
          onClick={() => deleteItem(el)}
        >
          Yes
        </Button>
        <Button
          variant="danger"
          className="btn-sm"
          style={{ marginLeft: "2px" }}
          onClick={closeToast}
        >
          No
        </Button>
      </div>,
      {
        position: "top-center",
        autoClose: false,
        closeOnClick: false,
        draggable: false,
        closeButton: false,
      }
    );
  };

  const deleteItem = async (item) => {
    const resp = await authCall("changeEventStatus", {
      id: item.id,
      deletedAt: moment(),
    });
    if (resp?.type === "success") {
      closeToast();
      toast.success("Deleted successfully.");
      eventListFun();
    }
  };

  const closeToast = () => {
    toast.dismiss();
  };

  return (
    <>
      <div className="update-machine-page common-table-design py-4">
        <div className="container custom-container">
          <div className="row">
            <div className="col-sm-4 col-lg-3 mb-4">
              <AdminSidebar />
            </div>
            <div className="col-sm-8 col-lg-9">
              <div className="common-info-card">
                <div className="heading-title">Events List:</div>
                <div className="details-wrapper">
                  <div className="table-responsive">
                    <Row className="align-items-center mb-4">
                      <Col xs={12} className="text-end">
                        <Button
                          className="btn btn-custom btn-custom-sm fs-16"
                          onClick={handleAdd}
                        >
                          <Plus />
                          Add Event
                        </Button>
                      </Col>
                    </Row>

                    <table className="table theade-bg table-bordered table-custom m-0">
                      <thead>
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">Event Name</th>
                          <th scope="col">Cost</th>
                          <th scope="col">Date</th>
                          <th scope="col">Recurring</th>
                          <th scope="col">Start Time</th>
                          <th scope="col">End Time</th>
                          <th scope="col">Status</th>
                          <th scope="col">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!loading &&
                          (evenList.length > 0 ? (
                            evenList.map((el, index) => (
                              <tr key={index}>
                                <td>{el.id}</td>
                                <td>{el.event_name}</td>
                                <td>{el.event_cost}</td>
                                <td>{el.event_start_date}</td>
                                <td>{el.event_recurring}</td>
                                <td>{moment(el.event_start_time, 'HH:mm:ss').format('hh:mm A')}</td>
                                <td>{moment(el.event_end_time, 'HH:mm:ss').format('hh:mm A')}</td>
                                <td className="text-center">
                                  <Badge
                                    bg={
                                      el.status === "1" ? "success" : "danger"
                                    }
                                    className="cursor-pointer"
                                    onClick={() =>
                                      handleChangeStatus(
                                        el.id,
                                        el.status === "1" ? "0" : "1"
                                      )
                                    }
                                  >
                                    {el.status === "1" ? "Active" : "In-active"}
                                  </Badge>
                                </td>
                                <td>
                                  <div className="icon-container">
                                    <PencilSquare
                                      onClick={() => handleEdit(el)}
                                      className="cursor-pointer"
                                      style={{
                                        color: "blue",
                                      }}
                                    />
                                    <Trash
                                      onClick={() => handleDelete(el)}
                                      className="cursor-pointer"
                                      style={{
                                        color: "red",
                                      }}
                                    />
                                  </div>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan={9} className="text-center">
                                No record found
                              </td>
                            </tr>
                          ))}
                        {loading && (
                          <tr>
                            <td colSpan={9} className="text-center">
                              Loading...
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EventListing;
