import React, { useEffect, useRef, useState } from "react";
import rectangle2 from "../../../../assets/images/site/Rectangle 15.png";
import NameIcon from "../../../../assets/images/site/NameIcon.png";
import ContactIcon from "../../../../assets/images/site/contactIcon.png";
import CalendertIcon from "../../../../assets/images/site/CalenderIcon.png";
import EmailtIcon from "../../../../assets/images/site/EmailIcon.png";
import LocationtIcon from "../../../../assets/images/site/LocationIcon.png";
import StopwatchIcon from "../../../../assets/images/site/StopwatchIcon.png";
import "./NightClubBook.scss";

const NightClubBook = () => {
  useEffect(() => {
    const datePicker = document.getElementById("datePicker");
    const formattedDateElement = document.getElementById("formattedDate");

    // Check if the elements exist before adding event listeners
    if (datePicker) {
      datePicker.addEventListener("change", function () {
        const selectedDate = new Date(this.value);
        const options = { day: "numeric", month: "long", year: "numeric" };
        const formattedDate = selectedDate.toLocaleDateString("en-GB", options);

        // Display the formatted date
        if (formattedDateElement) {
          formattedDateElement.textContent = formattedDate;
        }
      });
    }

    // Cleanup function to remove event listeners when component unmounts
    return () => {
      if (datePicker) {
        datePicker.removeEventListener("change", () => {});
      }
    };
  }, []); // Empty dependency array to run only once

  const [time, setTime] = useState("");
  const timePickerRef = useRef(null);
  const [formattedDate, setFormattedDate] = useState("");

  const handleDateChange = (event) => {
    const selectedDate = new Date(event.target.value);
    const options = { day: "numeric", month: "long", year: "numeric" };
    const formattedDate = selectedDate.toLocaleDateString("en-GB", options); // Use 'formattedDate' here
    setFormattedDate(formattedDate); // Set the state with 'formattedDate'
  };

  const handleTimeChange = (event) => {
    setTime(event.target.value);
  };
  const handleClick = () => {
    const timePicker = document.getElementById("timePicker");
    if (timePicker) {
      timePicker.showPicker();
    } else {
      document.getElementById("timePcker").click();
    }
  };

  return (
    <div
      className="nightClubBook_wrapper mt-5 container"
      style={{ position: "relative" }}
    >
      <div className="gradient-line"></div>
      <img src={rectangle2} alt="Book Now" className="centered-image" />
      <div className="overlay-text" style={{ fontFamily: "Inter" }}>
        STEP ONE: BOOK A DEMO
      </div>
      <div className="contact-form-wrapper">
        <form className="contact-form mb-5" style={{ margin: "0px 35px" }}>
          <div className="row mt-3">
            <div
              className="col-6 col-lg-6 d-flex flex-column align-items-center justify-content-between gap-4 mt-5"
              style={{ fontFamily: "Poppins", color: "#BABABA" }}
              id="contacttWrapper"
            >
              <div className="form-group ms-3" id="nightclubBook">
                <div className="input-with-icon">
                  <img
                    src={NameIcon}
                    alt="Name Icon"
                    className="input-icon"
                    height={31}
                    width={20}
                    style={{ left: '3%'}}
                  />
                  <input
                    type="text"
                    id="firstName"
                    name="firstName"
                    placeholder="First Name"
                    required
                  />
                </div>
              </div>

              <div className="form-group ms-3" id="nightclubBook">
                <div className="input-with-icon">
                  <img
                    src={ContactIcon}
                    alt="Name Icon"
                    className="input-icon"
                    height={26}
                    width={20}
                    style={{ left: '3%'}}
                  />
                  <input
                    type="number"
                    id="phoneNumber"
                    name="phoneNumber"
                    placeholder="Phone Number"
                    required
                  />
                </div>
              </div>

              <div className="form-group ms-3" id="nightclubBook">
                <div className="input-with-icon">
                  <img
                    src={CalendertIcon}
                    alt="Name Icon"
                    className="input-icon"
                    height={22}
                    width={20}
                    style={{ left: '3%'}}
                  />
                  <input
                    type="date"
                    id="datePicker"
                    required
                    onChange={handleDateChange} // Handle date change
                    onFocus={() =>
                      document.getElementById("datePicker").showPicker()
                    } // Open calendar when the field is focused
                    style={{ cursor: "pointer" }}
                  />
                </div>
              </div>
            </div>

            <div
              className="col-6 col-lg-6 d-flex flex-column align-items-center justify-content-between gap-4 mt-5"
              style={{ fontFamily: "Poppins", color: "#BABABA" }}
              id="contacttWrapper"
            >
              <div className="form-group ms-3" id="nightclubBook">
                <div className="input-with-icon">
                  <img
                    src={NameIcon}
                    alt="Name Icon"
                    className="input-icon"
                    height={31}
                    width={20}
                    style={{ left: '3%'}}
                  />
                  <input
                    type="text"
                    id="lastName"
                    name="lastName"
                    placeholder="Last Name"
                    required
                  />
                </div>
              </div>
              <div className="form-group ms-3" id="nightclubBook">
                <div className="input-with-icon">
                  <img
                    src={EmailtIcon}
                    alt="Name Icon"
                    className="input-icon"
                    height={24}
                    width={20}
                    style={{ left: '3%'}}
                  />
                  <input
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Email Address"
                    required
                  />
                </div>
              </div>

              <div className="form-group ms-3" id="nightclubBook">
                <div className="input-with-icon"  onClick={handleClick}>
                  <img
                    src={StopwatchIcon}
                    alt="Name Icon"
                    className="input-icon"
                    height={26}
                    width={20}
                    style={{ left: '3%'}}
                  />
                  <input
                    type="text"
                    placeholder="20:00"
                    value={time}
                    readOnly
                    style={{ cursor: "pointer" }} // Change cursor to pointer
                  />
                  <input
                    type="time"
                    id="timePicker"
                    value={time}
                    onChange={handleTimeChange}
                    style={{
                      position: "absolute",
                      left: 0,
                      top: 0,
                      opacity: 0,
                      cursor: "pointer",
                    }} // Hide the time input
                  />
                </div>
              </div>
            </div>

            <div
              className="col-12 col-lg-12 d-flex flex-column align-items-center justify-content-between gap-4 mt-4"
              style={{ fontFamily: "Poppins", color: "#BABABA" }}
              id="contacttWrapper"
            >
              <div
                className="form-group ms-3"
                id="locationInput"
                style={{ maxWidth: "100%" }}
              >
                <div className="input-with-icon">
                  <img
                    src={LocationtIcon}
                    alt="Name Icon"
                    className="input-icon"
                    height={25}
                    width={20}
                    style={{ left: '1.5%'}}
                  />
                  <input
                    type="text"
                    id="location"
                    name="location"
                    placeholder="Preferred Meeting Address"
                    required
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default NightClubBook;
