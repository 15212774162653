import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import SimpleReactValidator from "simple-react-validator";
import rectangle2 from "../../../../assets/images/site/Rectangle 15.png";
import NameIcon from "../../../../assets/images/site/NameIcon.png";
import ContactIcon from "../../../../assets/images/site/contactIcon.png";
import CalendertIcon from "../../../../assets/images/site/CalenderIcon.png";
import EmailtIcon from "../../../../assets/images/site/EmailIcon.png";
import StopwatchIcon from "../../../../assets/images/site/StopwatchIcon.png";
import LocationtIcon from "../../../../assets/images/site/LocationIcon.png";
import "./BookDemo.scss";
import { authCall } from "../../../../services";
import { handleNumericInput } from "../../../util/utils";

import uploadLogo from "../../../../assets/images/site/uploadLogo.png";
import MenuLogo1 from "../../../../assets/images/site/MenuLogo1.png";
import MenuLogo2 from "../../../../assets/images/site/MenuLogo2.png";
import MenuLogo3 from "../../../../assets/images/site/MenuLogo3.png";

const BookDemo = () => {
  const [, forceUpdate] = useState();
  const validator = useRef(new SimpleReactValidator());
  const initialField = {
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    demoDate: "",
    demoTime: "",
    meetingAddress: "",
    isZipPhotoUploaded: "",
    isMenuUploaded: "",
    listResto: "",
    isLightSpeed: "",
    sevenRoom: "",
  };
  const [values, setValues] = useState(initialField);
  const [loading, setLoading] = useState(false);
  const [uploadZipPhoto, setUploadZipPhoto] = useState(null);
  const [uploadMenu, setUploadMenu] = useState(null);

  const handleFileChange = (e) => {
    if (e.target.name === "uploadMenu") {
      setUploadZipPhoto(e.target.files[0]);
      setValues({ ...values, isMenuUploaded: "Yes" });
    } else if (e.target.name === "uploadZip") {
      setUploadMenu(e.target.files[0]);
      setValues({ ...values, isZipPhotoUploaded: "Yes" });
    }
  };

  const handleChangePhone = (e) => {
    const inputValue = handleNumericInput(e.target.value);
    if (inputValue !== null && inputValue.length < 11) {
      setValues({
        ...values,
        phoneNumber: inputValue,
      });
    }
  };

  const handleBookRequest = async (e) => {
    e.preventDefault();
    if (validator.current.allValid()) {
      console.log(values);
      setLoading(true);
      const formData = new FormData();
      formData.append("firstName", values.firstName);
      formData.append("lastName", values.lastName);
      formData.append("phoneNumber", values.phoneNumber);
      formData.append("email", values.email);
      formData.append("demoDate", values.demoDate);
      formData.append("demoTime", values.demoTime);
      formData.append("meetingAddress", values.meetingAddress);
      formData.append("listResto", values.listResto);
      formData.append("isLightSpeed", values.isLightSpeed);
      formData.append("sevenRoom", values.sevenRoom);
      formData.append("isZipPhotoUploaded", values.isZipPhotoUploaded);
      formData.append("isMenuUploaded", values.isMenuUploaded);
      formData.append("uploadZipPhoto", uploadZipPhoto);
      formData.append("uploadMenu", uploadMenu);

      // await axios
      //   .post(
      //     "http://localhost/touch-it/public/api/v1/sendMailDinningDemoRequest",
      //     formData,
      //     {
      //       headers: {
      //         "Content-Type": "multipart/form-data",
      //       },
      //     }
      //   )
      //   .then((response) => {
      //     setLoading(false);
      //     restForm();
      //     toast.dismiss();
      //     toast.success("Your demo request sent successfully!");
      //   })
      //   .catch((error) => error);

      const resp = await authCall("sendMailDinningDemoRequest", formData);
      if (resp?.type === "success") {
        setLoading(false);
        restForm();
        toast.dismiss();
        toast.success("Your demo request sent successfully!");
      }
    } else {
      setLoading(false);
      validator.current.showMessages();
      forceUpdate(1);
    }
  };

  const handleChangeValue = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const restForm = () => {
    setValues(initialField);
  };

  // -----------------------------------------------
  useEffect(() => {
    const datePicker = document.getElementById("datePicker");
    const formattedDateElement = document.getElementById("formattedDate");

    // Check if the elements exist before adding event listeners
    if (datePicker) {
      datePicker.addEventListener("change", function () {
        const selectedDate = new Date(this.value);
        const options = { day: "numeric", month: "long", year: "numeric" };
        const formattedDate = selectedDate.toLocaleDateString("en-GB", options);

        // Display the formatted date
        if (formattedDateElement) {
          formattedDateElement.textContent = formattedDate;
        }
      });
    }

    // Cleanup function to remove event listeners when component unmounts
    return () => {
      if (datePicker) {
        datePicker.removeEventListener("change", () => {});
      }
    };
  }, []); // Empty dependency array to run only once

  const [time, setTime] = useState("20:00");
  const timePickerRef = useRef(null);
  const [formattedDate, setFormattedDate] = useState("");

  const handleDateChange = (event) => {
    const selectedDate = new Date(event.target.value);
    const options = { day: "numeric", month: "long", year: "numeric" };
    const formattedDate = selectedDate.toLocaleDateString("en-GB", options); // Use 'formattedDate' here
    setFormattedDate(formattedDate); // Set the state with 'formattedDate'
  };

  const openCalendar = () => {
    document.getElementById("datePicker").focus(); // This triggers the date picker to open
  };

  const handleTimeChange = (event) => {
    setTime(event.target.value);
  };

  const handleClick = () => {
    const timePicker = document.getElementById("timePicker");
    if (timePicker) {
      timePicker.showPicker();
    } else {
      document.getElementById("timePcker").click();
    }
  };

  const [selectedOptionLightSpeed, setSelectedOptionLightSpeed] =
    useState(null);
  const [selectedOptionSevenRooms, setSelectedOptionSevenRooms] =
    useState(null);

  const handleImageChange = (e) => {
    console.log("vvvvvvvsss");
    const file = e.target.files[0];
    if (file) {
      setValues({ ...values, uploadMenu: file });
      // setImage(file);
      // setFileName(file.name);
    }
  };

  return (
    <>
      {console.log(uploadMenu)}
      {/* <form onSubmit={handleBookRequest}> */}
      <div
        className="bookDemo_wrapper mt-5 container"
        style={{ position: "relative" }}
      >
        <div className="gradient-line"></div>
        <img src={rectangle2} alt="Book Now" className="centered-image" />
        <div className="overlay-text" style={{ fontFamily: "Inter" }}>
          STEP ONE: BOOK A DEMO
        </div>
        <div className="contact-form-wrapper">
          <div
            className="contact-form mb-5"
            id="contactformrest"
            style={{ margin: "0px 35px" }}
          >
            <div className="row mt-3">
              <div
                className="col-6 col-lg-6 d-flex flex-column align-items-center justify-content-between gap-4 mt-5"
                style={{ fontFamily: "Poppins", color: "#BABABA" }}
                id="contacttWrapper"
              >
                <div className="form-group ms-3" id="restaurantform">
                  <div className="input-with-icon">
                    <img
                      src={NameIcon}
                      alt="Name Icon"
                      className="input-icon"
                      height={31}
                      width={20}
                      style={{ left: "3%" }}
                    />
                    <input
                      type="text"
                      id="firstNameInput"
                      name="firstName"
                      value={values.firstName}
                      onChange={handleChangeValue}
                      placeholder="First Name"
                    />
                  </div>
                  {validator.current.message(
                    "first name",
                    values.firstName,
                    "required"
                  )}
                </div>

                <div className="form-group ms-3" id="restaurantform">
                  <div className="input-with-icon">
                    <img
                      src={ContactIcon}
                      alt="Name Icon"
                      className="input-icon"
                      height={24}
                      width={20}
                      style={{ left: "3%" }}
                    />
                    <input
                      type="text"
                      id="phoneNumberInput"
                      name="phoneNumber"
                      value={values.phoneNumber}
                      onChange={handleChangePhone}
                      placeholder="Phone Number"
                    />
                  </div>
                  {validator.current.message(
                    "phone number",
                    values.phoneNumber,
                    "required"
                  )}
                </div>

                <div className="form-group ms-3" id="restaurantform">
                  <div className="input-with-icon">
                    <img
                      src={CalendertIcon}
                      alt="Name Icon"
                      className="input-icon"
                      height={22}
                      width={20}
                      style={{ left: "3%" }}
                    />
                    <input
                      type="date"
                      id="datePicker"
                      name="demoDate"
                      value={values.demoDate}
                      onChange={handleChangeValue}
                      onFocus={() =>
                        document.getElementById("datePicker").showPicker()
                      } // Open calendar when the field is focused
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                  {validator.current.message(
                    "date",
                    values.demoDate,
                    "required"
                  )}
                </div>
              </div>

              <div
                className="col-6 col-lg-6 d-flex flex-column align-items-center justify-content-between gap-4 mt-5"
                style={{ fontFamily: "Poppins", color: "#BABABA" }}
                id="contacttWrapper"
              >
                <div className="form-group ms-3" id="restaurantform">
                  <div className="input-with-icon">
                    <img
                      src={NameIcon}
                      alt="Name Icon"
                      className="input-icon"
                      height={31}
                      width={20}
                      style={{ left: "3%" }}
                    />
                    <input
                      type="text"
                      id="lastNameInput"
                      name="lastName"
                      placeholder="Last Name"
                      value={values.lastName}
                      onChange={handleChangeValue}
                    />
                  </div>
                  {validator.current.message(
                    "last name",
                    values.lastName,
                    "required"
                  )}
                </div>

                <div className="form-group ms-3" id="restaurantform">
                  <div className="input-with-icon">
                    <img
                      src={EmailtIcon}
                      alt="Name Icon"
                      className="input-icon"
                      height={20}
                      width={20}
                      style={{ left: "3%" }}
                    />
                    <input
                      type="email"
                      id="emailInput"
                      name="email"
                      placeholder="Email Address"
                      value={values.email}
                      onChange={handleChangeValue}
                    />
                  </div>
                  {validator.current.message(
                    "email",
                    values.email,
                    "required|email"
                  )}
                </div>
                <div className="form-group ms-3" id="restaurantform">
                  <div className="input-with-icon" onClick={handleClick}>
                    <img
                      src={StopwatchIcon}
                      alt="Name Icon"
                      className="input-icon"
                      height={26}
                      width={20}
                      style={{ left: "3%" }}
                    />
                    <input
                      type="text"
                      placeholder="hh:mm"
                      value={values.demoTime}
                      readOnly
                      style={{ cursor: "pointer" }} // Change cursor to pointer
                    />
                    <input
                      type="time"
                      id="timePicker"
                      name="demoTime"
                      value={values.demoTime}
                      onChange={handleChangeValue}
                      style={{
                        position: "absolute",
                        left: 0,
                        top: 0,
                        opacity: 0,
                        cursor: "pointer",
                      }} // Hide the time input
                    />
                  </div>

                  {validator.current.message(
                    "time",
                    values.demoTime,
                    "required"
                  )}
                </div>
              </div>

              <div
                className="col-12 col-lg-12 d-flex flex-column align-items-center justify-content-between gap-4 mt-4"
                style={{ fontFamily: "Poppins", color: "#BABABA" }}
                id="contacttWrapper"
              >
                <div
                  className="form-group ms-3"
                  id="locationInput"
                  style={{ maxWidth: "100%" }}
                >
                  <div className="input-with-icon">
                    <img
                      src={LocationtIcon}
                      alt="Name Icon"
                      className="input-icon"
                      height={25}
                      width={20}
                      style={{ left: "1.5%" }}
                    />
                    <input
                      type="text"
                      id="locationInput2"
                      name="meetingAddress"
                      placeholder="Preferred Meeting Address"
                      value={values.meetingAddress}
                      onChange={handleChangeValue}
                    />
                  </div>
                  {validator.current.message(
                    "meeting address",
                    values.meetingAddress,
                    "required"
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="menuSection_wrapper container"
        style={{ position: "relative" }}
      >
        <div className="gradient-line"></div>
        <img src={rectangle2} alt="Book Now" className="centered-image" />
        <div className="overlay-text" style={{ fontFamily: "Inter" }}>
          MENU SECTION
        </div>

        <div className="contact-form-wrapper">
          <div className="contact-form mb-5 mt-3">
            <div
              className="form-group mt-5"
              id="menuform"
              style={{ maxWidth: "100%", width: "100%" }}
            >
              <div className="input-with-icon" style={{ position: "relative" }}>
                <img
                  src={MenuLogo1}
                  alt="MenuLogo"
                  style={{
                    position: "absolute",
                    left: "10px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    width: "26px", // Adjust the size as needed
                    height: "26px",
                    zIndex: 3,
                  }}
                />
                {/* Placeholder Text */}
                <span
                  className="placeholder-text"
                  style={{
                    position: "absolute",
                    left: "55px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    color: "#BABABA",
                    pointerEvents: "none", // Make sure the text doesn't interfere with clicking
                    fontSize: "17px",
                  }}
                >
                  Upload a zipped file containing a photo of every dish
                </span>

                {/* Hidden File Input */}
                <input
                  type="file"
                  id="fileUpload"
                  name="uploadZip"
                  onChange={handleFileChange}
                  className="upload-input styled-input"
                  style={{
                    width: "100%",
                    opacity: 0,
                    cursor: "pointer",
                    zIndex: 2,
                    borderRadius: "4px",
                    border: "1px solid #282828",
                    borderColor: "#191919",
                  }}
                  required
                />

                {/* Custom Button with Icon */}
                <button
                  type="button"
                  className="custom-upload-btn"
                  onClick={() => document.getElementById("fileUpload").click()}
                  style={{
                    position: "absolute",
                    right: "10px", // Align button to the right
                    top: "50%",
                    transform: "translateY(-50%)",
                    background: "none",
                    border: "none",
                    cursor: "pointer",
                    zIndex: 3,
                  }}
                >
                  <img
                    src={uploadLogo}
                    alt="Upload Icon"
                    style={{ width: "46px", height: "47px", margin: "2px" }}
                  />
                </button>
              </div>
            </div>

            <div
              className="form-group mt-3"
              id="menuform"
              style={{ maxWidth: "100%", width: "100%" }}
            >
              <div className="input-with-icon" style={{ position: "relative" }}>
                <img
                  src={MenuLogo2}
                  alt="MenuLogo"
                  style={{
                    position: "absolute",
                    left: "10px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    width: "26px", // Adjust the size as needed
                    height: "26px",
                    zIndex: 3,
                  }}
                />

                {/* Placeholder Text */}
                <span
                  className="placeholder-text"
                  style={{
                    position: "absolute",
                    left: "55px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    color: "#BABABA",
                    pointerEvents: "none", // Make sure the text doesn't interfere with clicking
                    fontSize: "17px",
                  }}
                >
                  Upload a copy of your menu
                </span>

                {/* Hidden File Input */}
                <input
                  type="file"
                  id="uploadMenuPhoto"
                  name="uploadMenu"
                  className="upload-input"
                  onChange={handleFileChange}
                  style={{
                    width: "100%",
                    opacity: 0,
                    cursor: "pointer",
                    zIndex: 2,
                  }}
                />

                {/* Custom Button with Icon */}
                <button
                  type="button"
                  className="custom-upload-btn"
                  onClick={() =>
                    document.getElementById("uploadMenuPhoto").click()
                  }
                  style={{
                    position: "absolute",
                    right: "10px", // Align button to the right
                    top: "50%",
                    transform: "translateY(-50%)",
                    background: "none",
                    border: "none",
                    cursor: "pointer",
                    zIndex: 3,
                  }}
                >
                  <img
                    src={uploadLogo}
                    alt="Upload Icon"
                    style={{ width: "46px", height: "47px", margin: "2px" }}
                  />
                </button>
              </div>
            </div>
            {validator.current.message(
              "upload menu",
              values.isMenuUploaded,
              "required"
            )}

            <div
              className="form-group mt-3"
              id="menuform"
              style={{ maxWidth: "100%", width: "100%" }}
            >
              <div className="input-with-icon">
                <img
                  src={MenuLogo3}
                  alt="Name Icon"
                  className="input-icon"
                  height={30}
                  width={26}
                  style={{ left: "1.2%" }}
                />
                <input
                  type="text"
                  id="listResto"
                  name="listResto"
                  value={values.listResto}
                  onChange={handleChangeValue}
                  placeholder="Enter the link to your Uber Eats or Door Dash listing"
                />
              </div>
            </div>

            {/* Radio Buttons Starts */}

            <div className="row text-center mt-3">
              <div
                className="col-6 col-lg-6 d-flex flex-column mt-3"
                id="radiohead"
                style={{ fontFamily: "Poppins", color: "#BABABA" }}
              >
                <div className="form-group text-start mt-3" id="radio-buttons">
                  <label
                    style={{
                      fontSize: "17px",
                      color: "#BABABA",
                      marginLeft: "55px",
                    }}
                  >
                    Do you use LightSpeed POS?
                  </label>
                </div>
                {validator.current.message(
                  "lightspeep pos",
                  values.isLightSpeed,
                  "required"
                )}
              </div>

              <div
                className="col-2 col-lg-2 d-flex flex-column align-items-center justify-content-center mt-2"
                id="radiobtn1"
              >
                <button
                  onClick={() => setValues({ ...values, isLightSpeed: "Yes" })}
                  style={{
                    backgroundColor:
                      values.isLightSpeed === "Yes" ? "#E36D4B" : "#555",
                    color: "#FFFFFF",
                    maxWidth: "100px",
                    width: "100%",
                    borderRadius: "10px",
                    border: "none",
                    padding: "8px",
                    cursor: "pointer",
                  }}
                >
                  Yes
                </button>
              </div>

              <div
                className="col-2 col-lg-2 d-flex flex-column align-items-center justify-content-center mt-2"
                id="radiobtn2"
              >
                <button
                  onClick={() => setValues({ ...values, isLightSpeed: "No" })}
                  style={{
                    backgroundColor:
                      values.isLightSpeed === "No" ? "#E36D4B" : "#555",
                    color: "#FFFFFF",
                    maxWidth: "100px",
                    width: "100%",
                    borderRadius: "10px",
                    border: "none",
                    padding: "8px",
                    cursor: "pointer",
                  }}
                >
                  No
                </button>
              </div>
            </div>

            <div className="row text-center mt-3">
              <div
                className="col-6 col-lg-6 d-flex flex-column mt-3"
                id="radiohead"
                style={{ fontFamily: "Poppins", color: "#BABABA" }}
              >
                <div className="form-group text-start mt-3" id="radio-buttons">
                  <label
                    style={{
                      fontSize: "17px",
                      color: "#BABABA",
                      marginLeft: "55px",
                    }}
                  >
                    Do you use SevenRooms for online reservations?
                  </label>
                </div>
                {validator.current.message(
                  "sevenroom online",
                  values.sevenRoom,
                  "required"
                )}
              </div>

              <div
                className="col-2 col-lg-2 d-flex flex-column align-items-center justify-content-center mt-2"
                id="radiobtn1"
              >
                <button
                  onClick={() => setValues({ ...values, sevenRoom: "Yes" })}
                  style={{
                    backgroundColor:
                      values.sevenRoom === "Yes" ? "#E36D4B" : "#555",
                    color: "#FFFFFF",
                    maxWidth: "100px",
                    width: "100%",
                    borderRadius: "10px",
                    border: "none",
                    padding: "8px",
                    cursor: "pointer",
                  }}
                >
                  Yes
                </button>
              </div>

              <div
                className="col-2 col-lg-2 d-flex flex-column align-items-center justify-content-center mt-2"
                id="radiobtn2"
              >
                <button
                  onClick={() => setValues({ ...values, sevenRoom: "No" })}
                  style={{
                    backgroundColor:
                      values.sevenRoom === "No" ? "#E36D4B" : "#555",
                    color: "#FFFFFF",
                    maxWidth: "100px",
                    width: "100%",
                    borderRadius: "10px",
                    border: "none",
                    padding: "8px",
                    cursor: "pointer",
                  }}
                >
                  No
                </button>
              </div>
            </div>

            {/* Radio Buttons Ends */}
          </div>
        </div>

        <div style={{ textAlign: "center" }}>
          <button
            type="submit"
            className="menuButton text-center mt-5"
            disabled={loading || false}
            onClick={handleBookRequest}
          >
            {loading ? "Loading..." : "BOOK NOW"}
          </button>
        </div>
      </div>
      {/* </form> */}
    </>
  );
};

export default BookDemo;
