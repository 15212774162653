import React, { useEffect, useState } from "react";

import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { authCall } from "../services";

const StripeCheckout = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [clientSecret, setClientSecret] = useState("");
  const [paymentIntentId, setPaymentIntentId] = useState("");

  useEffect(() => {
    async function fetchData() {
      // const res = await authCall("createPaymentIntent", {});
      const res = await authCall("createPaymentIntentNew", { amount: 120 });
      if (res?.type === "success") {
        setClientSecret(res?.payload?.client_secret);
        setPaymentIntentId(res?.payload?.id);
      }
    }
    fetchData();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);

    const { error, paymentIntent } = await stripe.confirmCardPayment(
      clientSecret,
      {
        payment_method: {
          card: cardElement,
        },
      }
    );

    if (error) {
      console.error(error);
    } else if (paymentIntent.status === "succeeded") {
      // Call the backend to handle the payment success
      // const res = await authCall("handlePaymentSuccess", {
      const res = await authCall("createTransfersNew", {
        payment_intent_id: paymentIntentId,
      });
      if (res?.type === "success") {
        console.log("Payment successful!");
      }
    }
  };

  return (
    <div className="company-type-page py-4">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-lg-12">
            <div className="common-info-card">
              <div className="heading-title">Checkout and Split Amount</div>
              <div className="details-wrapper">
                <div
                  className="bg-white rounded-4 px-3 py-4 p-md-5 mx-auto mt-3"
                  style={{ maxWidth: 500 }}
                >
                  <form onSubmit={handleSubmit}>
                    <CardElement />
                    <button
                      type="submit"
                      className="btn btn-success rounded-3 px-4 py-2 mt-4"
                      disabled={!stripe}
                    >
                      Pay
                    </button>

                    {/* <div className="btn-wrapper text-center pt-2">
                    <button
                      type="submit"
                      className="btn btn-custom"
                      onClick={handleSubmit}
                      disabled={loading || false}
                    >
                      {loading ? "SUBMITTING..." : "SUBMIT"}
                    </button>
                  </div> */}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StripeCheckout;
