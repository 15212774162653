import React from "react";
import GoldCoastImg2 from "../../../../assets/images/site/GoldCoastImg2.png";
import "./GoldCoast2.scss";

const GoldCoast2 = () => {
  return (
    <div className="goldcoast_wrapper container mt-5">
      <div className="row mt-5 align-items-center" style={{ height: "100%" }}>
        <div className="col-12 col-lg-6 d-flex flex-column justify-content-between gap-4 mt-5 mt-lg-0">
          <img
            src={GoldCoastImg2}
            className="w"
            height={463}
            width={463}
            alt="GoldCoastImg"
          />
        </div>
        <div className="col-12 col-lg-6 d-flex flex-column align-items-start mt-5">
          <div
            className="heading text-start"
            id="goldCoast2heading"
            style={{
              fontFamily: "Arial Rounded MT Std",
              fontSize: "25px",
            }}
          >
            It will revolutionize how visitors experience our beautiful region
          </div>
          <div className="text-content mt-4">
            We understand that COVID has been hard on local business and the
            only way to see our beautiful region recover financially, is by
            working together. As a valued business in the community, we would
            like to extend an exclusive opportunity to be a part of this project
            from the ground level. Our kiosks are set to be installed in key
            locations in early 2025, and we need local businesses to REGISTER
            NOW
          </div>
        </div>
      </div>
    </div>
  );
};

export default GoldCoast2;
