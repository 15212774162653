import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AppConstants from "../../appConstants";

const NightLifeSidebar = () => {
  const [activityDetails, setActivityDetails] = useState({});
  const [progress, setProgress] = useState(12);

  useEffect(() => {
    const storedData = localStorage.getItem("productDetails");
    if (storedData) {
      setActivityDetails(JSON.parse(storedData));
      // setProgress(12.5);
    }
  }, []);

  return (
    <>
      <div className="progrss-bar-sec p-4">
        <div className="progress-label">10% Progress</div>
        <div className="progress-bar-cover mb-3">
          <div className="progress">
            <div
              className="progress-bar"
              role="progressbar"
              aria-valuenow="10"
              aria-valuemin="0"
              aria-valuemax="100"
              style={{ width: "10%" }}
            ></div>
          </div>
        </div>
        <div className="menu-wrapper mb-3">
          <ul>
            <li>
              <Link to={AppConstants.routes.nightLife.category} className="btn-link active">
                Venue Category
              </Link>
            </li>
            <li>
              <Link to={AppConstants.routes.nightLife.vanueDetails} className="btn-link">
                Venue Details
              </Link>
            </li>
            <li>
              <Link to={AppConstants.routes.nightLife.eventDetails} className="btn-link">
                Event Details
              </Link>
            </li>
            <li>
              <Link to={AppConstants.routes.nightLife.payments} className="btn-link">
                Billing
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default NightLifeSidebar;
