import React, { useState } from "react";
import RestaurantSidebar from "../layouts/RestaurantSidebar";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setCompanyDetails } from "../../redux/reducers/companyDetailsSlice";
import webServices from "../../services/webServices";
import AppConstants from "../../appConstants";
import RestaurantCategoryComp from "../common/RestaurantCategoryComp";

function RestaurantCategoryPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const companyDetails = useSelector((state) => state.companyDetails);
  const [loading, setLoading] = useState(false);

  const buttons = [
    {
      id: 1,
      label: "Submit",
    },
  ];

  const handleSubmitForm = async (data) => {
    data.profileLavel =
      companyDetails?.progress < 40
        ? AppConstants.routes.restaurant.restaurantCategory
        : "";
    data.progress = companyDetails?.progress < 40 ? 40 : "";
    setLoading(true);
    const resp = await webServices.createRestaurant({
      ...data,
      restaurantId: companyDetails?.id,
    });
    if (resp?.type === "success") {
      setLoading(false);
      dispatch(setCompanyDetails(resp?.payload));
      navigate(AppConstants.routes.restaurant.restaurantDetails);
    }
  };

  return (
    <>
      <RestaurantCategoryComp
        onSubmit={handleSubmitForm}
        loading={loading}
        details={companyDetails}
        heading="Please Enter Your Restaurant Details"
        Sidebar={RestaurantSidebar}
        buttons={buttons}
      />
    </>
  );
}

export default RestaurantCategoryPage;
