import React from "react";
import visionImg from "../../../../assets/images/site/visionImage.png";
import Touchit from "../../../../assets/images/logo-landing-advertising.png"
import TouchitBooking from "../../../../assets/images/logo-landing-booking.png";
import TouchitDining from "../../../../assets/images/logo-landing-dining.png";
import TouchitNightlife from "../../../../assets/images/logo-landing-nightlife.png";
import "./Vision.scss";

const Vision = () => {
  return (
    <div className="vision_wrapper mt-5 container">
      <div className="row mt-5 align-items-center" style={{ height: "100%" }}>
        <div className="col-12 col-lg-6 d-flex flex-column align-items-start mt-5">
          <div className="heading" style={{ fontFamily: "Arial Rounded MT Std", fontSize: "25px" }} id="visionheading">OUR VISION</div>
          <div className="text-content mt-2">
            Our goal is to enhance the visibility of local businesses <br /> while
            driving more revenue to the Gold Coast. We strive <br /> to offer visitors
            a seamless and easy way to book <br /> experiences, reserve restaurants,
            explore nightlife events, <br /> and discover everything happening in the
            local area.
          </div>
        </div>
        <div className="col-12 col-lg-6 d-flex flex-column justify-content-between gap-4 mt-5 mt-lg-0">
          <img src={visionImg} className="w-100" height={367} alt="Vision" />
        </div>
      </div>

      {/* //Department Starts */}

      <div className="row mt-5 align-items-center" style={{ height: "100%" }}>
        <div className="heading mt-5" id="visionheading">OUR DEPARTMENTS</div>
        <div className="col-6 col-lg-3 d-flex flex-column align-items-start mt-5">
          <div className="d-flex align-items-center py-4 px-4 feature-wrapper" style={{height:"110px"}}>
            <div className="icon-wrapper">
              <img src={Touchit} height={62} width={192} />
            </div>
          </div>
        </div>

        <div className="col-6 col-lg-3 d-flex flex-column align-items-start mt-5">
          <div className="d-flex align-items-center py-4 px-4 feature-wrapper" style={{height:"110px"}}>
            <div className="icon-wrapper">
              <img src={TouchitBooking} height={62} width={192} />
            </div>
          </div>
        </div>

        <div className="col-6 col-lg-3 d-flex flex-column align-items-start mt-5">
          <div className="d-flex align-items-center py-4 px-4 feature-wrapper" style={{height:"110px"}}>
            <div className="icon-wrapper">
              <img src={TouchitDining} height={62} width={192} />
            </div>
          </div>
        </div>

        <div className="col-6 col-lg-3 d-flex flex-column align-items-start mt-5">
          <div className="d-flex align-items-center py-4 px-4 feature-wrapper" style={{height:"110px"}}>
            <div className="icon-wrapper">
              <img src={TouchitNightlife} height={62} width={192} />
            </div>
          </div>
        </div>
      </div>
      <div className="footer-text text-center mt-3" style={{fontSize: "16px", color:"#323232"}}>copyright@2010-2024 Touch-iT.com All rights Reserved</div>
    </div>
  );
};

export default Vision;
