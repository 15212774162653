import React, { useEffect, useState } from "react";
import uploadIcon from "../../assets/images/upload-icon.svg";

const MultiVideoUploads = ({ videos, addedVideos, hideAddAnother }) => {
  const [videoField, setVideoField] = useState([
    { id: 1, value: "", videoFile: "", isNew: true },
  ]);

  const [isBanner, setIsBanner] = useState(0);

  useEffect(() => {
    if (addedVideos && addedVideos.length > 0) {
      setVideoField(addedVideos);
    }
  }, [addedVideos]);

  const handleBrowseClick = (id) => {
    document.getElementById(`fileInput-${id}`).click();
    setIsBanner(id);
  };

  const onChange = (id, e) => {
    e.preventDefault();
    const files = e.target.files[0];

    if (id) {
      setIsBanner(id);
    }

    const reader = new FileReader();
    reader.readAsDataURL(files);

    reader.onload = () => {
      const base64Video = reader.result.split(",")[1];
      const newVideos = videoField.map((field) =>
        field.id === id
          ? { ...field, value: base64Video, videoFile: files, isNew: true }
          : field
      );
      console.log("newVideosnewVideos", isBanner, id);
      setVideoField(newVideos);
      videos(newVideos);
    };
  };

  const removeBannerImage = (index) => {
    const updatedFiles = videoField.filter((_, i) => i !== index);
    setVideoField(updatedFiles);
    videos(updatedFiles);
  };

  const addBannerField = () => {
    const newFields = [];
    const newField = { id: videoField.length + 1, value: "", isNew: true };
    newFields.push(newField);
    setVideoField([...videoField, ...newFields]);
  };

  return (
    <>
      <div className="banner-cover mb-2">
        <div className="wrapper">
          {videoField.map((el, index) => (
            <div key={el.id}>
              {el.value === "" && (
                <div className="custom-input-file mb-2">
                  <input
                    type="file"
                    id={`filee-${el.id}`}
                    className="inputfile"
                    accept="video/*"
                    onChange={(e) => onChange(el.id, e)}
                  />
                  <label htmlFor={`filee-${el.id}`} className="m-0">
                    <span></span>
                    <div className="icon">
                      <img src={uploadIcon} alt="upload icon" />
                    </div>
                  </label>
                </div>
              )}
              {el.value !== "" && (
                <>
                  <div style={{ width: "120px" }} className="img-wrapper">
                    <video width="200" controls>
                      <source
                        src={URL.createObjectURL(el.videoFile)}
                        type="video/mp4"
                      />
                      Your browser does not support the video tag.
                    </video>
                    <input
                      type="file"
                      id={`fileInput-${el.id}`}
                      style={{ display: "none" }}
                      accept="video/*"
                      onChange={(e) => onChange(el.id, e)}
                    />
                  </div>

                  <div className="d-flex flex-wrap align-items-center gap-2 mt-2 mb-3">
                    <button
                      type="button"
                      className="btn btn-custom btn-custom-sm"
                      onClick={() => handleBrowseClick(el.id)}
                    >
                      Change
                    </button>
                    {!hideAddAnother && (
                      <button
                        type="button"
                        className="btn btn-sm btn-danger"
                        onClick={() => removeBannerImage(index)}
                      >
                        <i className="bi bi-trash3-fill"></i> Remove
                      </button>
                    )}
                  </div>
                </>
              )}
            </div>
          ))}
        </div>
        {!hideAddAnother && (
          <div
            className="add-another fw-medium text-end mt-2"
            onClick={addBannerField}
          >
            <span
              className="glyphicon glyphicon-plus"
              aria-hidden="true"
            ></span>{" "}
            Add Another Video
          </div>
        )}
      </div>
    </>
  );
};

export default MultiVideoUploads;
