import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import webServices from "../../../services/webServices";
import AdminSidebar from "../../layouts/AdminSidebar";
import RestaurantDetailPage from "../../common/RestaurantDetailPage";
import { authCall } from "../../../services";
import AppConstants from "../../../appConstants";

function UpdateDesireServices() {
  const location = useLocation();
  const navigate = useNavigate();
  const { restaurantId } = location.state || {};
  const [loading, setLoading] = useState(false);
  const [companyDetails, setCompanyDetails] = useState(false);

  const buttons = [{ id: 1, label: "Update", type: "breakFast" }];

  useEffect(() => {
    if (!restaurantId) {
      navigate(AppConstants.routes.admin.restaurantEditListing);
    }

    async function fetchData() {
      const resp = await authCall("getCompanyDetails", {
        companyId: restaurantId,
        companyType: "Restaurant",
      });
      if (resp?.type === "success") {
        setCompanyDetails(resp?.payload);
      }
    }
    fetchData();
  }, []);

  const handleSubmit = async (data, val) => {
    setLoading(true);
    const resp = await webServices.createRestaurant(data);
    if (resp?.type === "success") {
      setLoading(false);
      toast.dismiss();
      navigate(AppConstants.routes.admin.restaurantEditListing);
      toast.success("Updated successfully.");
      window.scrollTo(0, 0);
    }
  };

  return (
    <>
      <RestaurantDetailPage
        onSubmit={handleSubmit}
        loading={loading}
        details={companyDetails}
        heading="Update Restaurant Details"
        Sidebar={AdminSidebar}
        buttons={buttons}
      />
    </>
  );
}

export default UpdateDesireServices;
