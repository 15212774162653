import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setUser } from "../redux/actions/user.actions";
import AppConstants from "../appConstants";
import { authCall } from "../services";
import authService from "../services/authServices";
// import { setCompanyDetails } from "../redux/actions/company.actions";
import { setCompanyDetails } from "../redux/reducers/companyDetailsSlice";

function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    email: "",
    password: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const resp = await authCall("login", values);
    if (resp?.type === "success") {
      setLoading(false);
      authService.setToken(resp?.payload?.token);
      dispatch(setUser(resp?.payload?.user));
      dispatch(setCompanyDetails(resp?.payload?.companyDetails));
      if (resp?.payload?.user?.user_type === "Restaurant") {
        if (resp?.payload?.totalCompany > 1) {
          navigate(AppConstants.routes.admin.dashboard);
        } else {
          navigate(resp?.payload?.companyDetails?.profile_lavel);
        }
      } else if (resp?.payload?.user?.user_type === "Activity") {
        if (resp?.payload?.companyDetails?.is_profile_completed === "YES") {
          navigate(AppConstants.routes.admin.dashboard);
        } else {
          if (resp?.payload?.totalCompany > 1) {
            // navigate(AppConstants.routes.admin.actiityCreateCompany, {
            //   state: { isShowCompleteListing: true },
            // });
            navigate(AppConstants.routes.admin.dashboard);
          } else {
            navigate(AppConstants.routes.tour.createCompany);
          }
        }
      } else if (
        resp?.payload?.user?.user_type === "Admin" ||
        resp?.payload?.user?.user_type === "Super Admin"
      ) {
        navigate(AppConstants.routes.admin.dashboard);
      } else {
        navigate(AppConstants.routes.companyType);
      }
    } else {
      setLoading(false);
      toast.dismiss();
      toast.error(resp?.errormessage);
    }
  };

  return (
    <>
      <div className="login-page pt-5 pb-4">
        <div className="container">
          <div className="form-wrapper">
            <div className="heading text-center">LOG IN</div>
            <form onSubmit={handleSubmit} className="custom-field-wrapper">
              <div className="form-group">
                <label htmlFor="userEmail">Enter email or user name</label>
                <input
                  type="text"
                  className="form-control"
                  id="userEmail"
                  autoComplete="off"
                  required
                  onChange={(e) =>
                    setValues({ ...values, email: e.target.value })
                  }
                />
              </div>
              <div className="form-group">
                <label htmlFor="userPassword">Password</label>
                <input
                  type="password"
                  className="form-control"
                  id="userPassword"
                  autoComplete="off"
                  required
                  onChange={(e) =>
                    setValues({ ...values, password: e.target.value })
                  }
                />
              </div>
              {/* <div className="form-group d-flex align-items-center gap-2 mt-4">
                <input
                  type="checkbox"
                  className="custom-checkbox m-0"
                  id="rememberPass"
                />
                <label className="ms-2 mb-0" htmlFor="rememberPass">
                  Remember me
                </label>
              </div> */}
              <div className="btn-wrapper text-center mt-4 pt-2">
                <button
                  type="submit"
                  className="btn btn-custom"
                  disabled={loading || false}
                >
                  {loading ? "Logging..." : "Login"}
                </button>
              </div>
              <div
                className="text-center pt-3"
                style={{ fontSize: 22, marginTop: 12 }}
              >
                Don't have an account?{" "}
                <span
                  className="add-another"
                  onClick={() => navigate(AppConstants.routes.register)}
                >
                  Register
                </span>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
