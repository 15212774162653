import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setCompanyDetails } from "../../../redux/reducers/companyDetailsSlice";
import RestaurantDesireServicesComp from "../../common/RestaurantDesireServicesComp";
import RestaurantCategoryComp from "../../common/RestaurantCategoryComp";
import RestaurantDetailPage from "../../common/RestaurantDetailPage";
import MenuDetailPage from "../../common/MenuDetailPage";
import RestaurantSidebar from "../../layouts/RestaurantSidebar";
import webServices from "../../../services/webServices";
import DesiredServicesPage from "../../Restaurants/DesiredServicesPage";
import RestaurantCategoryPage from "../../Restaurants/RestaurantCategoryPage";
import RestaurantDetailsPage from "../../Restaurants/RestaurantDetailsPage";
import RestaurantMenuPage from "../../Restaurants/RestaurantMenuPage";

// import DesiredServices from "../../restaurant/DesiredServices";
// import RestaurantCategory from "../../restaurant/RestaurantCategory";
// import RestaurantDetails from "../../restaurant/RestaurantDetails";
import RestaurantMenu from "../../restaurant/RestaurantMenu";
import PageLoader from "../../util/PageLoader";
import AppConstants from "../../../appConstants";

const RestaurantActionAdmin = ({ handleClose }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { current } = useSelector((state) => state.user);
  const [companyDetails, setCompanyDetailss] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingDetails, setLoadingDetails] = useState(false);
  const [step, setStep] = useState(1);

  const buttons = [
    {
      id: 1,
      label: "Submit",
    },
  ];

  const buttonsMenu = [
    {
      id: 1,
      label: "Add Anonter Menu",
      type: "another",
    },
    {
      id: 3,
      label: "Done",
      type: "done",
    },
  ];

  const fetchRestaurantData = async () => {
    setLoadingDetails(true);
    const resp2 = await webServices.getCompnayDetails({
      companyType: "Restaurant",
      userId: current?.id,
    });
    if (resp2?.type === "success") {
      setCompanyDetailss(resp2?.payload);
      setLoadingDetails(false);
      // redirectPage(resp2?.payload?.profile_lavel);
      // setStep(resp2?.payload?.profile_lavel);
      dispatch(setCompanyDetails(resp2?.payload));
    }
  };

  useEffect(() => {
    fetchRestaurantData();
  }, []);

  const redirectPage = (profileLavel) => {
    let step = 1;
    if (profileLavel === AppConstants.routes.restaurant.desiredServices) {
      step = 2;
    } else if (
      profileLavel === AppConstants.routes.restaurant.restaurantCategory
    ) {
      step = 3;
    } else if (
      profileLavel === AppConstants.routes.restaurant.restaurantDetails
    ) {
      step = 4;
    } else {
      step = 1;
    }
    setStep(step);
    // return step;
  };

  const handleSubmitMenu = async (data, val, act) => {
    setLoading(true);
    const resp = await webServices.createMenu({
      ...data,
      menuType: val,
      userId: current?.id,
      restaurantId: companyDetails?.id,
    });

    if (resp.type === "success") {
      setLoading(false);
      // update in restaurant
      const updateData = {
        profileLavel:
          companyDetails?.progress < 100
            ? AppConstants.routes.admin.dashboard
            : "",
        progress: companyDetails?.progress < 100 ? 100 : "",
        restaurantId: companyDetails?.id,
        userId: current?.id,
      };
      const res = await webServices.createRestaurant(updateData);
      dispatch(setCompanyDetails(res?.payload));
      if (act.type === "done") {
        handleClose(false);
      }
    }
    setLoading(false);
  };

  const handleSubmitForm = async (data, type) => {
    setLoading(true);
    let level = "";
    let progress = "";
    if (type === "desireService") {
      level =
        companyDetails?.progress < 25
          ? AppConstants.routes.restaurant.desiredServices
          : "";
      progress = companyDetails?.progress < 25 ? 25 : "";
      setStep(2);
    } else if (type === "category") {
      level =
        companyDetails?.progress < 50
          ? AppConstants.routes.restaurant.restaurantCategory
          : "";
      progress = companyDetails?.progress < 50 ? 50 : "";
      setStep(3);
    } else if (type === "details") {
      level =
        companyDetails?.progress < 75
          ? AppConstants.routes.restaurant.restaurantDetails
          : "";
      progress = companyDetails?.progress < 75 ? 75 : "";
      setStep(4);
    }
    const resp = await webServices.createRestaurant({
      ...data,
      profileLavel: level,
      progress: progress,
      restaurantId: companyDetails?.id,
    });
    if (resp?.type === "success") {
      setLoading(false);
      dispatch(setCompanyDetails(resp?.payload));
      setCompanyDetailss(resp?.payload);
    }
  };

  const renderData = () => {
    switch (step) {
      case 1:
        return (
          <RestaurantDesireServicesComp
            onSubmit={(data) => handleSubmitForm(data, "desireService")}
            loading={loading}
            details={companyDetails}
            Sidebar={RestaurantSidebar}
          />
        );
      case 2:
        return (
          <RestaurantCategoryComp
            onSubmit={(data) => handleSubmitForm(data, "category")}
            loading={loading}
            details={companyDetails}
            Sidebar={RestaurantSidebar}
            buttons={buttons}
          />
        );
      case 3:
        return (
          <RestaurantDetailPage
            onSubmit={(data) => handleSubmitForm(data, "details")}
            loading={loading}
            details={companyDetails}
            heading="Please Enter Your Restaurant Details"
            Sidebar={RestaurantSidebar}
            buttons={buttons}
          />
        );
      case 4:
        return (
          <MenuDetailPage
            onSubmit={handleSubmitMenu}
            loading={loading}
            restaurantId={companyDetails?.id}
            heading="Please Create Your Menu"
            Sidebar={RestaurantSidebar}
            buttons={buttonsMenu}
            details={companyDetails}
            userId={current?.id}
          />
        );

      default:
        return <PageLoader />;
    }
  };

  return (
    <>
      {console.log(step)}
      {renderData()}
    </>
  );
};

export default RestaurantActionAdmin;
