import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

const CATEGORIES = [
  {
    id: 1,
    name: "africanCuisine",
    label: "African Cuisine",
  },
  {
    id: 2,
    name: "americanCuisine",
    label: "American Cuisine",
  },
  {
    id: 3,
    name: "arabCuisine",
    label: "Arab Cuisine",
  },
  {
    id: 4,
    name: "australiaCuisine",
    label: "Australia Cuisine",
  },
  {
    id: 5,
    name: "bowlingClub",
    label: "Bowling Club",
  },
  {
    id: 6,
    name: "burgerBar",
    label: "Burger Bar",
  },
  {
    id: 7,
    name: "chineseCuisine",
    label: "Chinese Cuisine",
  },
  {
    id: 8,
    name: "egyptianCuisine",
    label: "Egyptian Cuisine",
  },
  {
    id: 9,
    name: "fastFood",
    label: "Fast Food",
  },
  {
    id: 10,
    name: "frenchCuisine",
    label: "French Cuisine",
  },
  {
    id: 11,
    name: "germanCuisine",
    label: "German Cuisine",
  },
  {
    id: 12,
    name: "greekCuisine",
    label: "Greek Cuisine",
  },
  {
    id: 13,
    name: "indianCuisine",
    label: "Indian Cuisine",
  },
  {
    id: 14,
    name: "indonesianCuisine",
    label: "Indonesian Cuisine",
  },
  {
    id: 15,
    name: "irishBar",
    label: "Irish Bar",
  },
  {
    id: 16,
    name: "italianCuisine",
    label: "Italian Cuisine",
  },
  {
    id: 17,
    name: "japaneseCuisine",
    label: "Japanese Cuisine",
  },
  {
    id: 18,
    name: "koreanCuisine",
    label: "Korean Cuisine",
  },
  {
    id: 19,
    name: "mexicanCuisine",
    label: "Mexican Cuisine",
  },
  {
    id: 20,
    name: "rsl",
    label: "RSL",
  },
  {
    id: 21,
    name: "seafood",
    label: "Seafood",
  },
  {
    id: 22,
    name: "spanishCuisine",
    label: "Spanish Cuisine",
  },
  {
    id: 23,
    name: "sportsBar",
    label: "Sports Bar",
  },
  {
    id: 24,
    name: "surfClub",
    label: "Surf Club",
  },
  {
    id: 25,
    name: "sushiBar",
    label: "Sushi Bar",
  },
  {
    id: 26,
    name: "taven",
    label: "Taven",
  },
  {
    id: 27,
    name: "thaiCuisine",
    label: "Thai Cuisine",
  },
  {
    id: 28,
    name: "theatreRestaurant",
    label: "Theatre Restaurant",
  },
  {
    id: 29,
    name: "turkishCuisine",
    label: "Turkish Cuisine",
  },
  {
    id: 30,
    name: "vitaminiseCuisine",
    label: "Vitaminise Cuisine",
  },
  {
    id: 31,
    name: "weddingVenue",
    label: "Wedding Venue",
  },
  {
    id: 37,
    name: "other",
    label: "Other",
  },
];

function RestaurantCategoryComp({
  onSubmit,
  loading,
  details,
  Sidebar,
  buttons,
}) {
  const [selectedCategories, setSelectedCategories] = useState("");
  const [otherValue, setOtherValue] = useState("");

  useEffect(() => {
    setSelectedCategories(details?.restaurant_category || "");
    setOtherValue(details?.restaurant_category_other || "");
  }, [details]);

  const handleCheckboxChange = (value) => {
    setSelectedCategories(value);
  };

  const handleSubmit = async () => {
    if (selectedCategories === "") {
      toast.dismiss();
      toast.error("Please select category.");
    } else {
      const data = {
        restaurantCategories: selectedCategories,
        restaurantCategoryOther: otherValue,
      };
      onSubmit(data);
    }
  };

  return (
    <>
      <div className="restaurant-category-page py-4">
        <div className="container">
          <div className="row">
            <div className="col-md-4 col-lg-3 mb-4">
              <Sidebar details={details} />
            </div>
            <div className="col-md-8 col-lg-9">
              <div className="common-info-card">
                <div className="heading-title">
                  Which Category Best Represents Your Restaurant?
                </div>
                <div className="details-wrapper">
                  <div className="fs-18 mb-4">
                    Please select the most relevant categories from the list
                    below:
                  </div>
                  <div action="">
                    <div className="row row-cols-sm-2 row-cols-lg-3">
                      {CATEGORIES.map((cat) => (
                        <div
                          key={cat.id}
                          className="form-group d-flex align-items-center gap-3"
                        >
                          <input
                            // type="checkbox"
                            type="radio"
                            className="custom-radio m-0 cursor-pointer"
                            // name={cat.name}
                            name="restaurantCategories"
                            id={cat.name}
                            value={cat.label}
                            checked={selectedCategories === cat.label}
                            onChange={(e) =>
                              handleCheckboxChange(e.target.value)
                            }
                          />
                          <label
                            // className="form-check-label m-0"
                            className="m-0"
                            htmlFor={cat.name}
                          >
                            {cat.label}
                          </label>
                        </div>
                      ))}
                    </div>
                    {selectedCategories === "Other" && (
                      <input
                        type="text"
                        className="form-control form-group d-flex align-items-center gap-3"
                        style={{ width: 250 }}
                        id="categoryName"
                        name="categoryName"
                        placeholder="Add Category..."
                        autoComplete="off"
                        value={otherValue}
                        onChange={(e) => setOtherValue(e.target.value)}
                      />
                    )}

                    <div className="btn-wrapper text-center my-2">
                      {buttons.map((button) => (
                        <button
                          key={button.id}
                          onClick={handleSubmit}
                          className="btn btn-custom"
                          disabled={loading || false}
                        >
                          {loading ? "Loading..." : button.label}
                        </button>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default RestaurantCategoryComp;
