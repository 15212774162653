// Footer.jsx
import React from "react";
import { Link } from "react-router-dom";

const FooterSocials = () => {
  return (
    <>
      <div className="mb-4">&copy; Copyright 2023 | All Rights Reserved.</div>
      <div className="social-media-links d-flex flex-wrap gap-4">
        <Link
          to="https://www.facebook.com/profile.php?id=61567177491669"
          target="_blank"
          rel="noopener noreferrer"
          className="icon d-flex align-items-center justify-content-center rounded-circle text-decoration-none"
        >
          <i className="fa fa-facebook"></i>
        </Link>
        <Link
          to="https://www.youtube.com/@Touch-It-Co"
          target="_blank"
          rel="noopener noreferrer"
          className="icon d-flex align-items-center justify-content-center rounded-circle text-decoration-none"
        >
          <i className="fa fa-youtube"></i>
        </Link>
        <Link
          to="https://www.instagram.com/touchitco"
          target="_blank"
          rel="noopener noreferrer"
          className="icon d-flex align-items-center justify-content-center rounded-circle text-decoration-none"
        >
          <i className="fa fa-instagram"></i>
        </Link>
        <Link
          to="https://x.com/TouchItCo"
          target="_blank"
          rel="noopener noreferrer"
          className="icon d-flex align-items-center justify-content-center rounded-circle text-decoration-none"
        >
          <i className="fa fa-twitter"></i>
        </Link>
        <Link
          to="https://www.linkedin.com/company/touch-it-co"
          target="_blank"
          rel="noopener noreferrer"
          className="icon d-flex align-items-center justify-content-center rounded-circle text-decoration-none"
        >
          <i className="fa fa-linkedin"></i>
        </Link>
      </div>
    </>
  );
};

export default FooterSocials;
