import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setCompanyDetails } from "../../../redux/actions/company.actions";
import ActivitySidebar from "../../layouts/TourSidebar";
import stipeLogo from "../../../assets/images/stripe-grey.svg";
import { authCall } from "../../../services";
import webServices from "../../../services/webServices";
import AppConstants from "../../../appConstants";

function Payment({ current, setStep }) {
  const dispatch = useDispatch();
  const companyDetails = useSelector((state) => state.companyDetails);
  const [loading, setLoading] = useState(false);
  const [variationLoader, setVariationLoader] = useState(false);
  const [activityLoader, setActivityLoader] = useState(false);
  const [companytype] = useState(companyDetails?.company_type || "");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleClickAddVariations = async () => {
    setVariationLoader(true);
    const obj = {
      userId: companyDetails.user_id,
      variationParentId: companyDetails?.variation_parent_id
        ? companyDetails?.variation_parent_id
        : companyDetails.id,
      activityCategory: companyDetails.activity_category,
      bookingCalendar: companyDetails.booking_calendar,
      companyPhoneNumber: companyDetails.company_phone,
      companyType: companyDetails.company_type,
      duration: companyDetails.duration,
      durationHours: companyDetails.duration_hours,
      durationMinutes: companyDetails.duration_minutes,
      exclusion: companyDetails.highlight_excludes,
      highlight: companyDetails.highlight_includes,
      intensity: companyDetails.intensity,
      address: companyDetails.location,
      locationDescription: companyDetails.location_description,
      overview: companyDetails.overview,
      profileLavel: 3,
      whatToBring: companyDetails.what_to_bring,
      oldMenu: companyDetails.menu,
      oldProfileImage: companyDetails.profile_photo,
      oldBannerImages: companyDetails.banners,
    };

    const resp = await webServices.createTour(obj);
    if (resp?.type === "success") {
      setVariationLoader(false);
      dispatch(setCompanyDetails(resp?.payload));
      setStep(3);
    }
  };

  const handleClickDone = async () => {
    setActivityLoader(true);
    const obj = {
      userId: companyDetails.user_id,
      bookingCalendar: companyDetails.booking_calendar,
      profileLavel: 1,
    };

    const resp = await webServices.createTour(obj);
    if (resp?.type === "success") {
      setActivityLoader(false);
      dispatch(setCompanyDetails(resp?.payload));
      setStep(1);
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    const resp = await authCall("create-account-link", {
      userId: current?.id,
      email: current?.email,
      metaData: { details: "Activity provider stripe custom account" },
      returnUrl: `${process.env.REACT_APP_SITE_URL}${AppConstants.routes.tour.onboardStatus}`,
      refreshUrl: `${process.env.REACT_APP_SITE_URL}${AppConstants.routes.tour.onboardStatus}`,
    });
    if (resp?.type === "success") {
      // setLoading(false);
      window.location.href = resp?.payload?.accountLink;
    }
    // setLoading(false);
  };

  return (
    <>
      {loading && <div className="loader">Loading Stripe onboarding...</div>}
      {!loading && (
        <div className="company-payment-page py-4">
          <div className="container">
            <div className="row">
              <div className="col-sm-4 col-lg-3 mb-4">
                <ActivitySidebar setStep={setStep} />
              </div>
              <div className="col-sm-8 col-lg-9">
                <div className="common-info-card">
                  <div className="heading-title">
                    Please enter your payment details
                  </div>
                  <div className="details-wrapper">
                    <div className="stripe-info">
                      <p>
                        We use Stripe to make sure you get paid on time and to
                        keep your personal bank and details secure. Click{" "}
                        <strong>
                          <i>Payment</i>
                        </strong>{" "}
                        button to set up your payments on Stripe.
                      </p>
                      <img src={stipeLogo} alt="Stripe Logo" />
                    </div>

                    <div className="d-flex flex-wrap justify-content-center gap-3 gap-md-5 pt-4">
                      <button
                        type="submit"
                        className="btn btn-custom"
                        onClick={handleSubmit}
                        disabled={loading || false}
                      >
                        {loading ? "Loading..." : "Payment"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Payment;
