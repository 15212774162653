import React, { useEffect, useState } from "react";
import AdminSidebar from "../../layouts/AdminSidebar";
import { useSelector } from "react-redux";
import moment from "moment";
import { toast } from "react-toastify";
import { Badge, Row, Col, Button } from "react-bootstrap";
import Select from "react-select";
import { PencilSquare, Trash, Plus } from "react-bootstrap-icons";
import { useNavigate, useLocation } from "react-router-dom";
import { authCall } from "../../../services";
import AppConstants from "../../../appConstants";

function MenuListing() {
  const navigate = useNavigate();
  const companyDetails = useSelector((state) => state.companyDetails);
  const location = useLocation();
  const { type } = location.state || {};
  const [loading, setLoading] = useState(false);
  const [menus, setMenus] = useState([]);
  const [menuList, setMenuList] = useState([]);
  const [menuNameList, setMenuNameList] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);

  const getMenuFilter = (menus, menuName) => {
    const filteredData = menus.filter((item) => item.menu_name === menuName);
    return filteredData;
  };

  const menuListFun = async () => {
    const req = {
      type,
      restaurantId: companyDetails?.id,
    };
    const resp = await authCall("getMenu", req);
    if (resp?.type === "success") {
      const { menu, menuName } = resp?.payload;
      setMenuNameList(menuName);
      setMenus(menu);
      setMenuList(getMenuFilter(menu, menuName[0]?.value));
      setSelectedOption({
        label: menuName[0]?.label,
        value: menuName[0]?.value,
      });
      setLoading(false);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!type) {
      navigate(AppConstants.routes.admin.dashboard);
    }
    setLoading(true);
    menuListFun();
  }, [type]);

  const handleChangeStatus = async (id, status) => {
    const resp = await authCall("changeMenuStatus", { id, status });
    if (resp?.type === "success") {
      menuListFun();
    }
  };

  const handleChangeMenuName = (selected) => {
    setSelectedOption(selected);
    setMenuList(getMenuFilter(menus, selected?.value));
  };

  const handleEdit = (item) => {
    navigate(AppConstants.routes.admin.updateMenuDetails, { state: { item } });
  };

  const handleAdd = () => {
    navigate(AppConstants.routes.admin.updateMenuDetails, { state: { type } });
  };

  const handleDelete = (el) => {
    toast.warning(
      <div>
        <p>Are you sure you want to delete?</p>
        <Button
          variant="success"
          className="btn-sm"
          onClick={() => deleteItem(el)}
        >
          Yes
        </Button>
        <Button
          variant="danger"
          className="btn-sm"
          style={{ marginLeft: "2px" }}
          onClick={closeToast}
        >
          No
        </Button>
      </div>,
      {
        position: "top-center",
        autoClose: false,
        closeOnClick: false,
        draggable: false,
        closeButton: false,
      }
    );
  };

  const deleteItem = async (item) => {
    const resp = await authCall("changeMenuStatus", {
      id: item.id,
      deletedAt: moment(),
    });
    if (resp?.type === "success") {
      closeToast();
      toast.success("Deleted successfully.");
      menuListFun();
    }
  };

  const closeToast = () => {
    toast.dismiss();
  };

  return (
    <>
      <div className="update-machine-page common-table-design py-4">
        <div className="container custom-container">
          <div className="row">
            <div className="col-sm-4 col-lg-3 mb-4">
              <AdminSidebar />
            </div>
            <div className="col-sm-8 col-lg-9">
              <div className="common-info-card">
                <div className="heading-title">{type} Menu List:</div>
                <div className="details-wrapper">
                  <Row className="align-items-center mb-4">
                    <Col xs={6}>
                      <span className="fs-sm">Filter by Menu Name:</span>
                      <Select
                        className="text-body mt-1"
                        placeholder="Select Menu Name"
                        value={selectedOption}
                        onChange={handleChangeMenuName}
                        options={menuNameList}
                      />
                    </Col>
                    <Col xs={6} className="text-end">
                      <Button
                        className="btn btn-custom btn-custom-sm fs-16"
                        onClick={handleAdd}
                      >
                        <Plus />
                        Add Item
                      </Button>
                    </Col>
                  </Row>
                  <div className="table-responsive">
                    <table className="table theade-bg table-bordered table-custom m-0">
                      <thead>
                        <tr>
                          <th scope="col">#</th>
                          {/* <th scope="col">Menu Name</th> */}
                          <th scope="col">Category</th>
                          <th scope="col">Item Name</th>
                          <th scope="col">Item Price</th>
                          {/* <th scope="col">Description</th> */}
                          {/* <th scope="col">Item Photo</th> */}
                          <th scope="col">Status</th>
                          <th scope="col">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!loading &&
                          (menuList.length > 0 ? (
                            menuList.map((el, index) => (
                              <tr key={index}>
                                <td scope="row">{el.id}</td>
                                {/* <td>{el.menu_name}</td> */}
                                <td>{el.item_category}</td>
                                <td>{el.item_name}</td>
                                <td>{el.item_price}</td>
                                {/* <td className="text-capitalize">{el.item_description}</td> */}
                                {/* <td>
                                  <imp
                                    src={el.item_image_path}
                                    alt={el.item_name}
                                  />
                                </td> */}
                                <td className="text-center">
                                  <Badge
                                    bg={
                                      el.status === "1" ? "success" : "danger"
                                    }
                                    className="cursor-pointer"
                                    onClick={() =>
                                      handleChangeStatus(
                                        el.id,
                                        el.status === "1" ? "0" : "1"
                                      )
                                    }
                                  >
                                    {el.status === "1" ? "Active" : "In-active"}
                                  </Badge>
                                </td>
                                <td>
                                  <div className="icon-container">
                                    <PencilSquare
                                      onClick={() => handleEdit(el)}
                                      className="cursor-pointer"
                                      style={{
                                        color: "#ddb12e",
                                      }}
                                    />
                                    <Trash
                                      onClick={() => handleDelete(el)}
                                      className="cursor-pointer"
                                      style={{
                                        color: "red",
                                      }}
                                    />
                                  </div>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan={9} className="text-center">
                                No record found
                              </td>
                            </tr>
                          ))}
                        {loading && (
                          <tr>
                            <td colSpan={9} className="text-center">
                              Loading...
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MenuListing;
