import { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import ActivityCheckoutForm from "./ActivityCheckoutForm";
import { authCall } from "../services";

const appearance = {
  theme: "night",
  variables: {
    fontSize: "12px",
    fontFamily: "Sohne, system-ui, sans-serif",
    fontWeightNormal: "500",
    borderRadius: "8px",
    colorBackground: "#0A2540",
    colorPrimary: "#EFC078",
    accessibleColorOnColorPrimary: "#1A1B25",
    colorText: "white",
    colorTextSecondary: "white",
    colorTextPlaceholder: "#ABB2BF",
    tabIconColor: "white",
    logoColor: "dark",
  },
  rules: {
    ".Input": {
      backgroundColor: "#212D63",
      border: "1px solid var(--colorPrimary)",
    },
  },
};
const options = {
  layout: {
    type: "tabs",
    defaultCollapsed: true,
  },
};

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISH_KEY);

function ActivityPayment({ payment, userDetails, parentCallBack, machines }) {
  const [clientSecret, setClientSecret] = useState("");

  useEffect(() => {
    async function fetchData() {
      // const res = await authCall("createIntent", { payment });
      const res = await authCall("createPaymentIntentNew", { amount: payment });
      if (res?.type === "success") {
        setClientSecret(res?.payload?.client_secret);
      }
    }
    fetchData();
  }, []);

  return (
    <>
      {console.log(clientSecret)}
      {clientSecret && stripePromise && (
        <Elements
          stripe={stripePromise}
          options={{ clientSecret, appearance, options }}
        >
          <ActivityCheckoutForm
            payment={payment}
            userDetails={userDetails}
            parentCallBack={parentCallBack}
            machines={machines}
          />
        </Elements>
      )}
    </>
  );
}

export default ActivityPayment;
