import { UserConstants } from '../constants';

export const setUser = (payload) => {
  window.localStorage.setItem('userDetails', JSON.stringify(payload || ''));
  return {
    type: UserConstants.SET_USER_DETAILS,
    payload
  };
};

export const unsetUser = () => ({
  type: UserConstants.UNSET_USER_DETAILS
});