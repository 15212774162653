// FooterUser.jsx
import React from "react";
import LogoLanding from "../../../assets/images/logo-landing.png";
import kangaroo2 from "../../../assets/images/kangaroo2.png";
import FooterSocials from "../FooterSocials";
import { Link } from "react-router-dom";

const FooterUser = () => {
  return (
    <footer className="mt-4 mt-md-5">
      <img src={kangaroo2} className="img-fluid maskot-footer mx-auto" alt="kangaroo" />
      <div className="container-fluid mt-4">
        <div className="footer-logo mb-4">
          <Link to="#">
            <img src={LogoLanding} className="img-fluid" alt="logo" />
          </Link>
        </div>
        <div className="footer-menu">
          <FooterSocials />
        </div>
      </div>
    </footer>
  );
};

export default FooterUser;
