import React, { useEffect, useState, useRef } from "react";
import SimpleReactValidator from "simple-react-validator";
import ReactQuill from "react-quill";
import { useLocation, useNavigate } from "react-router-dom";
import "react-quill/dist/quill.snow.css"; // Quill's CSS
import checkIcon from "../../assets/images/admin/check-icon.png";
import BannerImagesUploads from "../util/BannerImagesUploads";
import ImageUploads from "../util/ImageUploads";
import { handleNumericInput } from "../util/utils";
import BookingTextMessagePreviewPopUp from "../pages/activities/BookingTextMessagePreviewPopUp";
import { QuestionCircle } from "react-bootstrap-icons";
import VideoTutorialPopUp from "../admin/VideoTutorialPopUp";
import AppConstants from "../../appConstants";

const HOURS = Array.from({ length: 24 });
const MINUTES = [0, 15, 30, 45];
const CompanyDetailsVideo = AppConstants.tutorial.companyDetails;

const modules = {
  toolbar: [
    ["bold", "italic", "underline"], // other formatting options
    [{ list: "ordered" }, { list: "bullet" }], // lists
    // ["link", "image"], // link and image options
    ["clean"], // remove formatting button
  ],
};

function ActivityDetailsPage({
  details,
  handleSubmit,
  loading,
  stepValue,
  setStep,
  SideBar,
  btnLabel,
}) {
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;
  const validator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState();
  const [isChecked, setIsChecked] = useState(true);
  const [profileImage, setProfileImage] = useState("");
  const [menuImage, setMenuImage] = useState(details?.menu_path || "");
  const [bannerImages, setBannerImages] = useState([]);
  const [isProfileImageChange, setIsProfileImageChange] = useState(false);
  const [isMenuImageChange, setIsMenuImageChange] = useState(false);
  const [isBannerImageChange, setIsBannerImageChange] = useState(false);
  const [highlights, setHighlights] = useState([
    { id: 1, value: "" },
    { id: 2, value: "" },
    { id: 3, value: "" },
    { id: 4, value: "" },
    { id: 5, value: "" },
    { id: 6, value: "" },
  ]);
  const [exclusions, setExclusions] = useState([
    { id: 1, value: "" },
    { id: 2, value: "" },
    { id: 3, value: "" },
  ]);

  const [show, setShow] = useState(false);
  const [videoURL, setVideoURL] = useState("");
  const showTutorialVideo = [
    AppConstants.routes.admin.updateCompanyDetails,
    AppConstants.routes.admin.actiityCreateCompany,
    AppConstants.routes.tour.createCompany,
  ].includes(pathname);

  const showPreview = [AppConstants.routes.admin.actiityCreateCompany].includes(
    pathname
  );

  const [values, setValues] = useState({
    // durationHours: 0,
    // durationMinutes: 0,
    companyName: details?.company_name || "",
    activityName: details?.activity_name || "",
    duration: "",
    durationHours: details?.duration_hours || "",
    durationMinutes:
      details?.duration_minutes || details?.duration_minutes === 0
        ? details?.duration_minutes
        : "",
    intensity: details?.intensity || "",
    menuFile: "",
    profileImage: "",
    bannerImages: [],
    overview: details?.overview || "",
    highlight: [],
    exclusion: [],
    whatToBring: details?.what_to_bring || "",
    whatToExpect: details?.what_to_expect || "",
    companyPhoneNumber: details?.company_phone || "",
    bannerImageUploaded: details?.banners_path || "",
    isBookingCancel: details?.is_booking_cancel === "0" ? false : true,
  });

  useEffect(() => {
    // async function fetchData() {
    setValues({
      ...values,
      companyName: details?.company_name || "",
      activityName: details?.activity_name || "",
      durationHours: details?.duration_hours || "",
      durationMinutes:
        details?.duration_minutes || details?.duration_minutes === 0
          ? details?.duration_minutes
          : "",
      intensity: details?.intensity || "",
      menuFile: "",
      profileImage: "",
      bannerImages: [],
      overview: details?.overview || "",
      whatToBring: details?.what_to_bring || "",
      whatToExpect: details?.what_to_expect || "",
      companyPhoneNumber: details?.company_phone || "",
      bannerImageUploaded: details?.banners_path || "",
      isBookingCancel: details?.is_booking_cancel === "0" ? false : true,
    });
    setHighlights(details?.highlight_includes || highlights);
    setExclusions(details?.highlight_excludes || exclusions);
    setProfileImage(details?.profile_photo_path || "");
    setMenuImage(details?.menu_path || "");
    setBannerImages(details?.banners_path || bannerImages);
    setIsChecked(details?.duration !== "0:0" || false);
    // }
    // fetchData();
    window.scrollTo(0, 0);
  }, [details]);

  const handleChange = (id, value) => {
    setHighlights(
      highlights.map((field) =>
        field.id === id ? { ...field, value: value } : field
      )
    );
  };

  const handleChangeExclusion = (id, value) => {
    setExclusions(
      exclusions.map((field) =>
        field.id === id ? { ...field, value: value } : field
      )
    );
  };

  const handleSubmitForm = async () => {
    if (validator.current.allValid()) {
      values.bookingId = details.id;
      values.highlight = highlights;
      values.exclusion = exclusions;
      values.duration = !isChecked
        ? ""
        : `${values.durationHours}:${values.durationMinutes}`;
      values.durationHours = !isChecked ? "" : values?.durationHours;
      values.durationMinutes = !isChecked ? "" : values?.durationMinutes;
      values.menuFile = isMenuImageChange ? menuImage : "";
      values.profileImage = isProfileImageChange ? profileImage : "";
      values.bannerImages = isBannerImageChange ? bannerImages : "";
      values.isBookingCancel = values?.isBookingCancel === 0 ? "0" : "1";

      if (stepValue) {
        values.profileLavel = stepValue;
      }

      handleSubmit(values, stepValue);
      setIsProfileImageChange(false);
      setIsMenuImageChange(false);
      setIsBannerImageChange(false);
    } else {
      validator.current.showMessages();
      forceUpdate(1);
      window.scrollTo(0, 0);
    }
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
    if (!isChecked) {
      setValues({ ...values, durationHours: "", durationMinutes: "" });
    }
  };

  const getBannerImages = (banners) => {
    setBannerImages(banners);
    setIsBannerImageChange(true);
    setValues({ ...values, bannerImageUploaded: "uploaded" });
  };

  const handleMenuImage = (image) => {
    setMenuImage(image);
    setIsMenuImageChange(true);
  };

  const handleProfileImage = (image) => {
    setProfileImage(image);
    setIsProfileImageChange(true);
  };

  const handleChangePhone = (e) => {
    const inputValue = handleNumericInput(e.target.value);
    if (inputValue !== null && inputValue.length < 11) {
      setValues({
        ...values,
        companyPhoneNumber: inputValue,
      });
    }
  };

  // For Bootstrap Model
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };

  const handleClickTutorial = () => {
    setVideoURL(CompanyDetailsVideo);
  };

  const tutoralClose = () => {
    setVideoURL("");
  };

  const handleShowActivityPreview = () => {
    navigate(AppConstants.routes.tour.home, {
      state: { activityId: details.id },
    });
  };

  return (
    <>
      <div className="complete-activity-page py-4">
        <div className="container">
          <div className="row">
            <div className="col-md-4 col-lg-3 mb-4">
              <SideBar setStep={setStep} />
            </div>
            <div className="col-md-8 col-lg-9">
              <div className="common-info-card">
                <div className="heading-title d-flex flex-wrap justify-content-between gap-2">
                  <span>Please Complete Your Company Details</span>
                  <div>
                    {showPreview && (
                      <span
                        className="fs-16 text-decoration-underline text-primary cursor-pointer mt-2"
                        style={{ marginRight: 10 }}
                        onClick={handleShowActivityPreview}
                      >
                        Preview
                      </span>
                    )}
                    {showTutorialVideo && (
                      <span
                        className="cursor-pointer"
                        onClick={handleClickTutorial}
                      >
                        <QuestionCircle />{" "}
                      </span>
                    )}
                  </div>
                </div>
                <div className="details-wrapper">
                  <div className="sub-title mb-3">
                    These details will appear on your activity listing
                  </div>
                  {details && (
                    <div className="custom-field-wrapperr">
                      <div className="field-wrapper">
                        <div className="info-cover mt-2">
                          <div className="row">
                            <div className="col-lg-6 mb-4 mb-lg-0">
                              <div className="info-cover">
                                <div className="main-heading2 mb-1">
                                  Company Name:
                                </div>
                                <div className="profile-cover mb-3">
                                  <p className="fs-12">
                                    <i>
                                      (Please enter your Company Name. Your
                                      Company Name will appear on your profile
                                      page)
                                    </i>
                                  </p>
                                  <input
                                    type="text"
                                    className="form-control mt-1"
                                    autoComplete="off"
                                    value={values.companyName}
                                    onChange={(e) =>
                                      setValues({
                                        ...values,
                                        companyName: e.target.value,
                                      })
                                    }
                                  />
                                  {validator.current.message(
                                    "company name",
                                    values.companyName,
                                    "required"
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6 mb-4 mb-lg-0">
                              <div className="info-cover">
                                <div className="main-heading2 mb-1">
                                  Company Phone Number:
                                </div>
                                <div className="profile-cover mb-3">
                                  <p className="fs-12">
                                    <i>
                                      (Please provide company phone number. It
                                      will be provided in booking confirmation
                                      text)
                                    </i>
                                  </p>
                                  <input
                                    type="text"
                                    className="form-control mt-1"
                                    autoComplete="off"
                                    value={values.companyPhoneNumber}
                                    onChange={handleChangePhone}
                                  />
                                  {validator.current.message(
                                    "phone number",
                                    values.companyPhoneNumber,
                                    "required"
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-lg-6 mb-4 mb-lg-0">
                            <div className="info-cover">
                              <div className="main-heading2 mb-1">
                                Activity Name:
                              </div>
                              <div className="profile-cover mb-3">
                                <input
                                  type="text"
                                  className="form-control mt-1"
                                  autoComplete="off"
                                  value={values.activityName}
                                  onChange={(e) =>
                                    setValues({
                                      ...values,
                                      activityName: e.target.value,
                                    })
                                  }
                                />
                                {validator.current.message(
                                  "activity name",
                                  values.activityName,
                                  "required"
                                )}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row mt-4">
                          <div className="col-lg-5">
                            <div className="main-heading2 mb-2">Duration:</div>
                            <div className="d-flex gap-3 align-items-center">
                              <div className="form-group">
                                <input
                                  type="checkbox"
                                  checked={isChecked}
                                  onChange={handleCheckboxChange}
                                />
                              </div>
                              {isChecked && (
                                <>
                                  <div className="form-group flex-fill">
                                    <select
                                      name=""
                                      className="form-select rounded-3"
                                      id="itemCategory"
                                      style={{ height: "37px" }}
                                      onChange={(e) =>
                                        setValues({
                                          ...values,
                                          durationHours: e.target.value,
                                        })
                                      }
                                    >
                                      <option value="" hidden>
                                        Hours
                                      </option>
                                      {HOURS.map((_, index) => (
                                        <option
                                          key={index}
                                          value={index}
                                          selected={
                                            index === values.durationHours
                                              ? "selected"
                                              : ""
                                          }
                                        >
                                          {index}
                                        </option>
                                      ))}
                                    </select>
                                    {validator.current.message(
                                      "duration",
                                      isChecked && values.durationHours,
                                      "required"
                                    )}
                                  </div>

                                  <div className="form-group flex-fill">
                                    <select
                                      name=""
                                      className="form-select rounded-3"
                                      id="itemCategory"
                                      style={{ height: "37px" }}
                                      onChange={(e) =>
                                        setValues({
                                          ...values,
                                          durationMinutes: e.target.value,
                                        })
                                      }
                                    >
                                      <option value="" hidden>
                                        Minutes
                                      </option>

                                      {MINUTES.map((el, index) => (
                                        <option
                                          key={index}
                                          value={el}
                                          selected={
                                            el === values.durationMinutes
                                              ? "selected"
                                              : ""
                                          }
                                        >
                                          {el}
                                        </option>
                                      ))}
                                    </select>
                                    {validator.current.message(
                                      "minutes",
                                      isChecked && values.durationMinutes,
                                      "required"
                                    )}
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-7">
                            <div className="form-group">
                              <div className="main-heading2 mb-2">
                                Intensity:
                              </div>
                              <div className="intensity-level d-flex flex-wrap row-gap-2 column-gap-1 mb-2">
                                <div
                                  className={`count ${
                                    values.intensity === 1 ? "active" : ""
                                  }`}
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    setValues({ ...values, intensity: 1 })
                                  }
                                >
                                  1
                                </div>
                                <div
                                  className={`count ${
                                    values.intensity === 2 ? "active" : ""
                                  }`}
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    setValues({ ...values, intensity: 2 })
                                  }
                                >
                                  2
                                </div>
                                <div
                                  className={`count ${
                                    values.intensity === 3 ? "active" : ""
                                  }`}
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    setValues({ ...values, intensity: 3 })
                                  }
                                >
                                  3
                                </div>
                                <div
                                  className={`count ${
                                    values.intensity === 4 ? "active" : ""
                                  }`}
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    setValues({ ...values, intensity: 4 })
                                  }
                                >
                                  4
                                </div>
                                <div
                                  className={`count ${
                                    values.intensity === 5 ? "active" : ""
                                  }`}
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    setValues({ ...values, intensity: 5 })
                                  }
                                >
                                  5
                                </div>
                                <div
                                  className={`count ${
                                    values.intensity === 6 ? "active" : ""
                                  }`}
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    setValues({ ...values, intensity: 6 })
                                  }
                                >
                                  6
                                </div>
                                <div
                                  className={`count ${
                                    values.intensity === 7 ? "active" : ""
                                  }`}
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    setValues({ ...values, intensity: 7 })
                                  }
                                >
                                  7
                                </div>
                                <div
                                  className={`count ${
                                    values.intensity === 8 ? "active" : ""
                                  }`}
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    setValues({ ...values, intensity: 8 })
                                  }
                                >
                                  8
                                </div>
                                <div
                                  className={`count ${
                                    values.intensity === 9 ? "active" : ""
                                  }`}
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    setValues({ ...values, intensity: 9 })
                                  }
                                >
                                  9
                                </div>
                                <div
                                  className={`count ${
                                    values.intensity === 10 ? "active" : ""
                                  }`}
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    setValues({ ...values, intensity: 10 })
                                  }
                                >
                                  10
                                </div>
                              </div>
                              <div className="info">
                                <i>
                                  Level 1 is friendly, while level 10 is extreme
                                </i>
                              </div>
                              {validator.current.message(
                                "intensity",
                                values.intensity,
                                "required"
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="info-cover mt-2">
                          <div className="row">
                            <div className="col-lg-6 mb-4 mb-lg-0">
                              <div className="info-cover">
                                <div className="main-heading2 mb-1">
                                  Menu:{" "}
                                  <span className="fs-16 fw-normal text-white me-2">
                                    <i>(optional)</i>
                                  </span>
                                </div>
                                <div className="profile-cover mb-3">
                                  <p className="fs-12">
                                    <i>
                                      (If you provide food on the trip, please
                                      upload the menu below as an image)
                                    </i>
                                  </p>

                                  <ImageUploads
                                    getImage={handleMenuImage}
                                    uploadedImage={menuImage}
                                    index="1"
                                    aspectRation={1.5 / 1}
                                    croppedSize={{
                                      width: 870,
                                      height: 580,
                                      imageSmoothingQuality: "high",
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <hr className="divider my-4" />

                      <div className="field-wrapper mb-lg-2">
                        <div className="info-cover">
                          <div className="row">
                            <div className="col-12">
                              <div className="main-heading2 mb-1">Images:</div>
                            </div>
                            <div className="col-lg-6 mb-4 mb-lg-0">
                              <div className="info-cover">
                                <div className="profile-cover mb-3">
                                  <div className="main-heading2 text-white mb-1">
                                    Profile Image:
                                  </div>
                                  <p className="fs-12">
                                    <i>
                                      (This is the image shown on the search
                                      results page)
                                    </i>
                                  </p>
                                  <ImageUploads
                                    getImage={handleProfileImage}
                                    uploadedImage={profileImage}
                                    index="2"
                                    aspectRation={1.5 / 1}
                                    croppedSize={{
                                      width: 870,
                                      height: 580,
                                      imageSmoothingQuality: "high",
                                    }}
                                  />
                                  <div
                                    style={{
                                      marginTop: !profileImage ? "-5px" : 0,
                                    }}
                                  >
                                    {validator.current.message(
                                      "profile image",
                                      profileImage,
                                      "required"
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="info-cover">
                                <BannerImagesUploads
                                  bannerImages={getBannerImages}
                                  images={bannerImages}
                                />
                                {validator.current.message(
                                  "banner",
                                  values.bannerImageUploaded,
                                  "required"
                                ) && (
                                  <div
                                    style={{
                                      marginTop: "-38px",
                                    }}
                                  >
                                    {validator.current.message(
                                      "banner",
                                      values.bannerImageUploaded,
                                      "required"
                                    )}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="info-cover mb-4 pb-2">
                          <div className="main-heading2 text-white mb-2 pb-1">
                            Overview:
                          </div>
                          <div className="custom-quill">
                            <ReactQuill
                              value={values.overview}
                              modules={modules}
                              onChange={(e) =>
                                setValues({ ...values, overview: e })
                              }
                            />
                          </div>
                          {validator.current.message(
                            "overview",
                            values.overview,
                            "required"
                          )}
                        </div>

                        <div className="info-cover mb-4">
                          <div className="main-heading2 text-white mb-2 pb-1">
                            Highlight:
                          </div>
                          <div className="row">
                            {highlights.map((field, index) => (
                              <div key={field.id} className="col-sm-6 col-md-4">
                                <div className="form-group">
                                  <div className="d-flex align-items-center gap-2 mb-1">
                                    <div className="icon">
                                      <img
                                        src={checkIcon}
                                        className="img-responsive"
                                        alt={checkIcon}
                                      />
                                    </div>
                                    <input
                                      type="text"
                                      className="form-control rounded-pill"
                                      id=""
                                      placeholder="Hightlight"
                                      autoComplete="off"
                                      value={field.value}
                                      onChange={(e) =>
                                        handleChange(field.id, e.target.value)
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>

                        <div className="info-cover mb-4">
                          <div className="main-heading2 text-white mb-2 pb-1">
                            Exclusion:
                          </div>
                          <div className="row">
                            {exclusions.map((field) => (
                              <div key={field.id} className="col-sm-6 col-md-4">
                                <div className="form-group d-flex align-items-center gap-2">
                                  <div className="icon">
                                    <img
                                      src={checkIcon}
                                      className="img-responsive"
                                      alt={checkIcon}
                                    />
                                  </div>
                                  <input
                                    type="text"
                                    className="form-control rounded-pill"
                                    id=""
                                    placeholder="Exclusion"
                                    autoComplete="off"
                                    value={field.value}
                                    onChange={(e) =>
                                      handleChangeExclusion(
                                        field.id,
                                        e.target.value
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>

                        <div className="info-cover mb-3">
                          <div className="main-heading2 text-white mb-2 pb-1 d-flex justify-content-between">
                            Booking Confirmation Text:
                            <span
                              className="fs-14 text-decoration-underline text-primary cursor-pointer mt-2"
                              onClick={handleShow}
                            >
                              Preview
                            </span>
                          </div>

                          <textarea
                            id="overview"
                            rows="4"
                            className="form-control"
                            placeholder="Please make sure you..."
                            autoComplete="off"
                            value={values.whatToBring}
                            onChange={(e) =>
                              setValues({
                                ...values,
                                whatToBring: e.target.value,
                              })
                            }
                          ></textarea>
                          {validator.current.message(
                            "booking confirmation text",
                            values.whatToBring,
                            "required"
                          )}
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 mb-1">
                          <div className="form-group d-flex align-items-center gap-3 mt-4">
                            <input
                              type="checkbox"
                              name="isBookingCancel"
                              id="isBookingCancel"
                              checked={values.isBookingCancel}
                              onChange={(e) =>
                                setValues({
                                  ...values,
                                  isBookingCancel: e.target.checked ? 1 : 0,
                                })
                              }
                            />
                            <label className="m-0" htmlFor="isBookingCancel">
                              Include cancellation link?
                            </label>
                          </div>
                        </div>

                        {/* <div className="info-cover mb-4">
                          <div className="main-heading2 text-white mb-2 pb-1">
                            What to expect:
                          </div>
                          <textarea
                            id="overview"
                            rows="4"
                            className="form-control"
                            placeholder="Please provide a 75-to-150-word descripition of what to expect."
                            autoComplete="off"
                            value={values.whatToExpect}
                            onChange={(e) =>
                              setValues({
                                ...values,
                                whatToExpect: e.target.value,
                              })
                            }
                          ></textarea>
                        </div> */}

                        <div className="btn-wrapper text-center pt-2">
                          <button
                            type="submit"
                            className="btn btn-custom"
                            disabled={loading || false}
                            onClick={handleSubmitForm}
                          >
                            {loading ? "Loading..." : btnLabel}
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                  {!details && (
                    <div className="fs-18 text-center">Loading...</div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <BookingTextMessagePreviewPopUp
        show={show}
        handleClose={handleClose}
        details={{
          activity_name: details?.activity_name,
          company_name: values?.companyName,
          company_phone: values.companyPhoneNumber,
          location: details?.location,
          what_to_bring: values.whatToBring,
          locationDescription: details?.location_description,
          isBookingCancel: values?.isBookingCancel || false,
        }}
      />
      <VideoTutorialPopUp videoURL={videoURL} closeVideo={tutoralClose} />
    </>
  );
}

export default ActivityDetailsPage;
