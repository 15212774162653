import { postRequest, fetchData, putRequest } from "./index";

function register(req) {
  return postRequest("register", req);
}

function getSettings(req) {
  return postRequest("getSettings", req);
}

function getPlans(req) {
  return postRequest("plans", req);
}

function getCompnayDetails(req) {
  return postRequest("getCompanyDetails", req);
}

function updatePlan(req) {
  return postRequest("updatePlan", req);
}


function getActivities() {
  return fetchData("getActivities");
}

// Tour
function createTour(req) {
  return postRequest("tour", req);
}

function getTour(id) {
  return fetchData(`tour/${id}`);
}

function searchAvailability(req) {
  return postRequest("searchAvailability", req);
}

function searchActivity(req) {
  return postRequest("activityDetails", req);
}

function bookingDetails(req) {
  return postRequest("bookingDetails", req);
}

function picupLocations(req) {
  return postRequest("picupLocations", req);
}

// Restarant
function createRestaurant(req) {
  return postRequest("restaurant", req);
}

function getMenu(menuCode) {
  return fetchData(`menu/${menuCode}`);
}

function createMenu(req) {
  return postRequest("menu", req);
}

function restaurantDetails(id) {
  return fetchData(`restaurant/${id}`);
}

function getMenuCategory() {
  return fetchData("menuCategory");
}

// Night Life
function createNightLife(req) {
  return postRequest("nightLife", req);
}

function createEvents(req) {
  return postRequest("nightLifeEvent", req);
}

function nightLifeDetails(id) {
  return fetchData(`nightLife/${id}`);
}

function getNightLifeBackground() {
  return fetchData("nightLifeBackgroundImage");
}

function getNightLifeEvent(id) {
  return fetchData(`nightLifeEvent/${id}`);
}

// Admin
function updateDetails(userId, detailsToUpdate) {
  return putRequest(`update/${userId}`, detailsToUpdate);
}

function getPlayerGoals() {
  return fetchData("getPlayerGoals");
}

function getTouchCounts(type) {
  return fetchData(`touchCounts/${type}`);
}

function getMachineDetails() {
  return fetchData("getMachineDetails");
}

function getMachineLocations(req) {
  return postRequest("getMachineLocation", req);
}

const webServices = {
  register,
  getSettings,
  getPlans,
  getCompnayDetails,
  getActivities,
  createTour,
  getTour,
  searchAvailability,
  searchActivity,
  bookingDetails,
  picupLocations,
  createRestaurant,
  restaurantDetails,
  createNightLife,
  getNightLifeBackground,
  createEvents,
  nightLifeDetails,
  getNightLifeEvent,
  getMenu,
  createMenu,
  getMenuCategory,
  updateDetails,
  getPlayerGoals,
  getTouchCounts,
  getMachineDetails,
  updatePlan,
  getMachineLocations
};

export default webServices;
