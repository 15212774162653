import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "react-loading-skeleton/dist/skeleton.css";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { QuestionCircle } from "react-bootstrap-icons";
import AdminSidebar from "../../layouts/AdminSidebar";
import webServices from "../../../services/webServices";
import AppConstants from "../../../appConstants";
import { authCall } from "../../../services";
import searchImg from "../../../assets/images/admin/search.svg";
import VideoTutorialPopUp from "../VideoTutorialPopUp";

const SelectScreenVideo = AppConstants.tutorial.selectScreen;

function SelectScreen() {
  const navigate = useNavigate();
  const location = useLocation();
  const { adsDetails } = location.state || {};
  const { current } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const [loadingLocation, setLoadingLocation] = useState(false);
  const [checkedLocations, setCheckedLocations] = useState([]);
  const [uncheckedLocations, setUncheckedLocations] = useState([]);
  const [uncheckedOriginLocations, setUncheckedOriginLocations] = useState([]);
  const [videoURL, setVideoURL] = useState("");

  useEffect(() => {
    if (!adsDetails) {
      navigate(AppConstants.routes.admin.runAdd);
    }
  }, [adsDetails]);

  useEffect(() => {
    async function fetchData() {
      setLoadingLocation(true);
      const resp2 = await webServices.getMachineLocations({
        screens: adsDetails?.screens || [],
      });
      if (resp2?.type === "success") {
        setCheckedLocations(resp2?.payload?.checkedLocatons);
        setUncheckedLocations(resp2?.payload?.unCheckedLocatons);
        setUncheckedOriginLocations(resp2?.payload?.unCheckedLocatons);
        setLoadingLocation(false);
      }
    }
    fetchData();
  }, []);

  const handleCheckboxChange = (location, isChecked) => {
    if (isChecked) {
      setUncheckedLocations(
        uncheckedLocations.filter((item) => item.id !== location.id)
      );
      setCheckedLocations([...checkedLocations, location]);
    } else {
      setCheckedLocations(
        checkedLocations.filter((item) => item.id !== location.id)
      );
      setUncheckedLocations([...uncheckedLocations, location]);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    toast.dismiss();
    if (checkedLocations.length > 0) {
      setLoading(true);
      const values = {
        adsId: adsDetails?.id,
        machineIds: checkedLocations,
      };
      const resp = await authCall("createAdsScreens", values);
      if (resp.type === "success") {
        setLoading(false);
        toast.success(resp.message);
        handleMakePayment(checkedLocations);
      } else {
        toast.error(resp.errormessage);
        setLoading(false);
      }
    } else {
      toast.error("Please select location.");
      setLoading(false);
    }
  };

  const searchLocation = (searchTerm) => {
    if (!searchTerm) {
      setUncheckedLocations(uncheckedOriginLocations);
    } else {
      const filteredLocations = uncheckedOriginLocations.filter((location) =>
        location.vanue_name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setUncheckedLocations(filteredLocations);
    }
  };

  const generateRandomNumber = () => {
    return Math.floor(Math.random() * (50 - 30 + 1)) + 30;
  };

  const handleMakePayment = (checkedLocations) => {
    if (adsDetails?.skipPayment) {
      navigate(AppConstants.routes.admin.dashboard);
    } else if (adsDetails?.updateAds) {
      navigate(AppConstants.routes.admin.adsSubscription, {
        state: { adsDetails, selectedMachine: checkedLocations },
      });
    } else {
      navigate(AppConstants.routes.admin.uploadContent, {
        state: { adsDetails: adsDetails, selectedMachine: checkedLocations },
      });
    }
  };

  const handleClickTutorial = () => {
    setVideoURL(SelectScreenVideo);
  };

  const tutoralClose = () => {
    setVideoURL("");
  };

  return (
    <>
      <div className="select-your-screen py-4">
        <div className="container custom-container">
          <div className="row">
            <div className="col-sm-4 col-lg-3 mb-4">
              <AdminSidebar />
            </div>
            <div className="col-sm-8 col-lg-9">
              <div className="common-info-card">
                <div className="heading-title d-flex flex-wrap justify-content-between gap-2">
                  <span>Select Your Screen</span>
                  <span
                    className="cursor-pointer"
                    onClick={handleClickTutorial}
                  >
                    <QuestionCircle />
                  </span>
                </div>
                <div className="details-wrapper pb-lg-4">
                  <div className="info-cover mb-2">
                    <div className="main-heading2 mb-1">Select Kiosks:</div>
                    <p className="fs-md-18 fw-medium mb-4">
                      Based on your sales to date we have selected the kiosks on
                      the left. If you want to add more kiosks please seach in
                      the box on the right
                    </p>

                    <div className="row">
                      <div className="col-lg-6 mb-4 mb-lg-0">
                        <div
                          className="table-responsive rounded-3"
                          style={{ border: "2px solid #fff" }}
                        >
                          <table className="table theade-bg table-bordered m-0">
                            <thead>
                              <tr>
                                <td>Location</td>
                                <td>
                                  {current?.company_type === "Restaurant" &&
                                    "Clicks"}
                                  {current?.company_type === "NightLife" &&
                                    "Drink Codes"}
                                  {current?.company_type === "Activity" &&
                                    "Earnings"}
                                </td>
                                <td>Selected Location</td>
                              </tr>
                            </thead>
                            <tbody>
                              {!loadingLocation &&
                                (checkedLocations.length > 0 ? (
                                  checkedLocations.map((el, index) => (
                                    <tr key={index}>
                                      <td>
                                        <div className="name">
                                          {el?.vanue_name}
                                        </div>
                                        <span className="fs-12">
                                          <i>
                                            ({el?.street_address}, {el?.city},{" "}
                                            {el?.state} {el?.post_code})
                                          </i>
                                        </span>
                                      </td>
                                      <td>
                                        <div className="count">
                                          {el.total > 0
                                            ? el.total * 10
                                            : generateRandomNumber()}
                                        </div>
                                      </td>
                                      <td>
                                        <div className="check-wrapper">
                                          <input
                                            className="mt-2"
                                            type="checkbox"
                                            // name="location"
                                            // id={el?.machine_details?.vanue_name}
                                            checked={true}
                                            onChange={() =>
                                              handleCheckboxChange(el, false)
                                            }
                                          />
                                        </div>
                                      </td>
                                    </tr>
                                  ))
                                ) : (
                                  <tr>
                                    <td colSpan={3} className="text-center">
                                      No selected location found
                                    </td>
                                  </tr>
                                ))}

                              {loadingLocation && (
                                <tr>
                                  <td colSpan={3} className="text-center">
                                    Loading...
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="table-responsive rounded-3">
                          <div className="searchfield-wrapper m-2 m-sm-0">
                            <input
                              type="search"
                              className="form-control rounded-pill pe-4"
                              placeholder="Search"
                              autoComplete="off"
                              onChange={(e) => searchLocation(e.target.value)}
                            />
                            <img
                              src={searchImg}
                              className="img-responsive"
                              alt="Search Icon"
                            />
                          </div>
                          <table className="table table-borderless mt-1 mb-0">
                            <thead>
                              <tr>
                                <td className="fw-semibold">Location</td>
                                <td className="fw-semibold text-center">
                                  Add Location
                                </td>
                              </tr>
                            </thead>
                            <tbody>
                              {!loadingLocation &&
                                (uncheckedLocations.length > 0 ? (
                                  uncheckedLocations.map((el, index) => (
                                    <tr key={index}>
                                      <td>
                                        <div className="name">
                                          {el?.vanue_name}
                                        </div>
                                        <span className="fs-12">
                                          <i>
                                            ({el?.street_address}, {el?.city},{" "}
                                            {el?.state} {el?.post_code})
                                          </i>
                                        </span>
                                      </td>
                                      <td>
                                        <div className="check-wrapper text-center lh-1">
                                          <input
                                            type="checkbox"
                                            name="location"
                                            id={el?.machine_details?.vanue_name}
                                            checked={false}
                                            onChange={() =>
                                              handleCheckboxChange(el, true)
                                            }
                                          />
                                        </div>
                                      </td>
                                    </tr>
                                  ))
                                ) : (
                                  <tr>
                                    <td colSpan={2} className="text-center">
                                      No location found
                                    </td>
                                  </tr>
                                ))}
                              {loadingLocation && (
                                <tr>
                                  <td colSpan={2} className="text-center">
                                    Loading...
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                        <div className="btn-wrapper text-end mt-3">
                          <button
                            type="submit"
                            className="btn btn-custom"
                            onClick={handleSubmit}
                            disabled={loading || false}
                          >
                            {loading ? "Loading..." : "Next"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <VideoTutorialPopUp videoURL={videoURL} closeVideo={tutoralClose} />
    </>
  );
}

export default SelectScreen;
