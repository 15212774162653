import React from "react";
import MenuDetailPage from "../common/MenuDetailPage";
import RestaurantSidebar from "../layouts/RestaurantSidebar";

function DinnerMenu({ companyDetails, handleSubmit, loading, loadingDetails, setStep }) {
  const handleSubmitForm = async (data, val) => {
    data.menuType = "Dinner";
    const profileLavel =
      companyDetails?.profile_lavel > 6 ? companyDetails?.profile_lavel : 6;
    if (val === "breakFast") {
      handleSubmit(data, 4, profileLavel);
    } else if (val === "lunch") {
      handleSubmit(data, 5, profileLavel);
    } else {
      handleSubmit(data, 7, profileLavel);
    }
  };

  const buttons = [
    { id: 1, label: "Add Breakfast Menu", type: "breakFast" },
    { id: 2, label: "Add Lunch Menu", type: "lunch" },
    { id: 3, label: "Done", type: "done" },
  ];

  return (
    <>
      <MenuDetailPage
        admin={false}
        onSubmit={handleSubmitForm}
        loading={loading}
        restaurantId={companyDetails?.id}
        type="Lunch"
        heading="Please Create Your Dinner Menu"
        Sidebar={RestaurantSidebar}
        buttons={buttons}
        loadingDetails={loadingDetails}
        setStep={setStep}
      />
    </>
  );
}

export default DinnerMenu;
