import { combineReducers } from 'redux';
import bookingReducer, { initialState as bookingInitialState } from './booking.reducer';
import userReducer, { initialState as userInitialState } from './user.reducer';
// import companyReducer, { initialState as companyInitialState } from './company.reducer';
import companyDetailsReducer from './companyDetailsSlice'

const rootReducer = combineReducers({
  booking: bookingReducer,
  user: userReducer,
  // company: companyReducer
  companyDetails: companyDetailsReducer,
});

export const initialState = {
  booking: bookingInitialState,
  user: userInitialState,
  // company: companyDetails
};

export default rootReducer;
