import React, { useRef, useState } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { Modal } from "react-bootstrap"; // Assuming you're using Bootstrap

const CropperModal = ({ show, image, handleClose, sendCropedDataToParent }) => {
  // const [image, setImage] = useState(defaultSrc);
  // const [cropData, setCropData] = useState("#");
  const cropperRef = useRef(null);

  const getCropData = () => {
    if (typeof cropperRef.current?.cropper !== "undefined") {
      const cropedFile = cropperRef.current?.cropper
        .getCroppedCanvas()
        .toDataURL();
      // setCropData(cropedFile);
      sendCropedDataToParent(cropedFile);
      handleClose();
    }
  };
  return (
    <>
      {/* {console.log(cropData)} */}
      {/* Modal */}
      <Modal
        show={show}
        centered
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
        dialogClassName="custom-modal"
      >
        <Modal.Header className="justify-content-center">
          <Modal.Title className="fw-bold">Crop Image</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Cropper
            ref={cropperRef}
            style={{ height: 400, width: "100%" }}
            zoomTo={0.5}
            initialAspectRatio={1}
            preview=".img-preview"
            src={image}
            viewMode={1}
            minCropBoxHeight={10}
            minCropBoxWidth={10}
            background={false}
            responsive={true}
            autoCropArea={1}
            checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
            guides={true}
          />

          <div className="btn-wrapper d-flex flex-wrap justify-content-center gap-3 mt-4">
            <button
              type="submit"
              className="btn btn-custom"
              onClick={getCropData}
            >
              Crop Image
            </button>
            <button
              type="reset"
              className="btn btn-custom"
              onClick={handleClose}
            >
              Cancel
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CropperModal;
