import React from "react";
import BestPrice from "./../assets/images/icons/best-price.png";
import TrustedBy from "./../assets/images/icons/trusted-by.png";
import Australian from "./../assets/images/icons/australian.png";
import Unbeatable from "./../assets/images/icons/unbeatable.png";

function BannerSubComponent() {
  return (
    <>
      <section className="keypoints py-5">
        <div className="container">
          <div className="row g-4">
            <div className="col-6 col-lg-3">
              <div className="keypoints-container d-flex flex-column flex-sm-row align-items-center justify-content-center justify-content-xl-start row-gap-2 column-gap-3 text-center text-sm-start">
                <div className="image-container d-flex align-items-center justify-content-center rounded-4">
                  <img src={BestPrice} alt={BestPrice} />
                </div>
                <h4 className="m-0">
                  Best price <span className="d-sm-block">guaranteed</span>
                </h4>
              </div>
            </div>
            <div className="col-6 col-lg-3">
              <div className="keypoints-container d-flex flex-column flex-sm-row align-items-center justify-content-center justify-content-xl-start row-gap-2 column-gap-3 text-center text-sm-start">
                <div className="image-container d-flex align-items-center justify-content-center rounded-4">
                  <img src={TrustedBy} alt={TrustedBy} />
                </div>
                <h4 className="m-0">
                  Gold Coast <span className="d-sm-block">First</span>
                </h4>
              </div>
            </div>
            <div className="col-6 col-lg-3">
              <div className="keypoints-container d-flex flex-column flex-sm-row align-items-center justify-content-center justify-content-xl-start row-gap-2 column-gap-3 text-center text-sm-start">
                <div className="image-container d-flex align-items-center justify-content-center rounded-4">
                  <img src={Australian} alt={Australian} />
                </div>
                <h4 className="m-0">
                  Australian <span className="d-sm-block">owned</span>
                </h4>
              </div>
            </div>
            <div className="col-6 col-lg-3">
              <div className="keypoints-container d-flex flex-column flex-sm-row align-items-center justify-content-center justify-content-xl-start row-gap-2 column-gap-3 text-center text-sm-start">
                <div className="image-container d-flex align-items-center justify-content-center rounded-4">
                  <img src={Unbeatable} alt={Unbeatable} />
                </div>
                <h4 className="m-0">
                  Unbeatable <span className="d-sm-block">support</span>
                </h4>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default BannerSubComponent;
