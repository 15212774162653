import React, { useState } from "react";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { QuestionCircle } from "react-bootstrap-icons";
import ActivitySidebar from "../../layouts/TourSidebar";
import VideoTutorialPopUp from "../../admin/VideoTutorialPopUp";
import AppConstants from "../../../appConstants";

const CategorySelectionVideo = AppConstants.tutorial.categorySelection;

const CATEGORIES = [
  {
    id: 1,
    name: "4x4Tours",
    label: "4x4 Tours",
  },
  {
    id: 2,
    name: "axeThrowing",
    label: "Axe Throwing",
  },
  {
    id: 3,
    name: "beautyTreatment",
    label: "Beauty Treatment",
  },
  {
    id: 4,
    name: "bikeHire",
    label: "Bike Hire",
  },
  {
    id: 5,
    name: "bowling",
    label: "Bowling",
  },
  {
    id: 6,
    name: "bungeeJumping",
    label: "Bungee Jumping",
  },
  {
    id: 7,
    name: "cannoeOrKayakHire",
    label: "Cannoe/Kayak Hire",
  },
  {
    id: 8,
    name: "danceLessions",
    label: "Dance Lessions",
  },
  {
    id: 9,
    name: "divingSnorkling",
    label: "Diving & Snorkling",
  },
  {
    id: 10,
    name: "escapeRooms",
    label: "Escape Rooms",
  },
  {
    id: 11,
    name: "farmTour",
    label: "Farm Tour",
  },
  {
    id: 12,
    name: "fishCharter",
    label: "Fish Charter",
  },
  {
    id: 13,
    name: "handglyding",
    label: "Handglyding",
  },
  {
    id: 14,
    name: "harleyRides",
    label: "Harley Rides",
  },
  {
    id: 15,
    name: "hikingTour",
    label: "Hiking Tour",
  },
  {
    id: 16,
    name: "horseRiding",
    label: "Horse Riding",
  },
  {
    id: 17,
    name: "hotAirBallooning",
    label: "Hot Air Ballooning",
  },
  {
    id: 18,
    name: "jetBoating",
    label: "Jet Boating",
  },
  {
    id: 19,
    name: "jetSkiing",
    label: "Jet Skiing",
  },
  {
    id: 20,
    name: "joyflights",
    label: "Joy flights",
  },
  {
    id: 21,
    name: "massage",
    label: "Massage",
  },
  {
    id: 22,
    name: "miningTour",
    label: "Mining Tour",
  },
  {
    id: 23,
    name: "Parasalining",
    label: "Parasalining",
  },
  {
    id: 24,
    name: "reef&IslandCruses",
    label: "Reef & Island Cruses",
  },
  {
    id: 25,
    name: "sailing",
    label: "Sailing",
  },
  {
    id: 26,
    name: "skydiving",
    label: "Skydiving",
  },
  {
    id: 27,
    name: "surfLessons",
    label: "Surf Lessons",
  },
  {
    id: 28,
    name: "tattoo",
    label: "Tattoo",
  },
  {
    id: 29,
    name: "themeParks",
    label: "Theme Parks",
  },
  {
    id: 30,
    name: "waterSkiing",
    label: "Water Skiing",
  },
  {
    id: 31,
    name: "whaleWatching",
    label: "Whale Watching",
  },
  {
    id: 32,
    name: "whiteWaterRafting",
    label: "White Water Rafting",
  },
  {
    id: 33,
    name: "wildlifeParks",
    label: "Wildlife Parks",
  },
  {
    id: 34,
    name: "windSurfing",
    label: "Wind Surfing",
  },
  {
    id: 35,
    name: "wineries",
    label: "Wineries",
  },
  {
    id: 36,
    name: "zoo&Aquariums",
    label: "Zoo & Aquariums",
  },
  {
    id: 37,
    name: "other",
    label: "Other",
  },
];

function CompanyCategory({ handleSubmit, loading, stepValue, setStep }) {
  const companyDetails = useSelector((state) => state.companyDetails);
  const [videoURL, setVideoURL] = useState("");
  const [selectedCategories, setSelectedCategories] = useState(
    companyDetails?.activity_category || ""
  );
  const [otherValue, setOtherValue] = useState(
    companyDetails?.activity_category_other || ""
  );

  const handleCheckboxChange = (value) => {
    setSelectedCategories(value);
  };

  const handleSubmitForm = async (event) => {
    event.preventDefault();
    if (selectedCategories === "") {
      toast.dismiss();
      toast.error("Please select category.");
    } else {
      const data = {
        activityCategory: selectedCategories,
        profileLavel: stepValue,
        activityCategoryOther: otherValue,
      };
      handleSubmit(data, stepValue);
    }
  };

  const handleClickTutorial = () => {
    setVideoURL(CategorySelectionVideo);
  };

  const tutoralClose = () => {
    setVideoURL("");
  };

  return (
    <>
      <div className="restaurant-category-page py-4">
        <div className="container">
          <div className="row">
            <div className="col-md-4 col-lg-3 mb-4">
              <ActivitySidebar setStep={setStep} />
            </div>
            <div className="col-md-8 col-lg-9">
              <div className="common-info-card">
                <div className="heading-title d-flex flex-wrap justify-content-between gap-2">
                  <span>Which Category Best Represents Your Company?</span>
                  <span
                    className="cursor-pointer"
                    onClick={handleClickTutorial}
                  >
                    <QuestionCircle />
                  </span>
                </div>
                <div className="details-wrapper">
                  <div className="fs-18 mb-4">
                    Please select the most relevant categories from the list
                    below:
                  </div>
                  <div action="">
                    <div className="row row-cols-sm-2 row-cols-lg-3">
                      {CATEGORIES.map((cat) => {
                        return (
                          <div
                            key={cat.id}
                            className="form-group d-flex align-items-center gap-3"
                          >
                            <input
                              // type="checkbox"
                              type="radio"
                              className="custom-radio m-0 cursor-pointer"
                              // name={cat.name}
                              name="restaurantCategories"
                              id={cat.name}
                              value={cat.label}
                              checked={selectedCategories === cat.label}
                              onChange={(e) =>
                                handleCheckboxChange(e.target.value)
                              }
                            />
                            <label
                              // className="form-check-label m-0"
                              className="m-0"
                              htmlFor={cat.name}
                            >
                              {cat.label}
                            </label>
                          </div>
                        );
                      })}
                    </div>
                    {selectedCategories === "Other" && (
                      <input
                        type="text"
                        className="form-control form-group d-flex align-items-center gap-3"
                        style={{ width: 250 }}
                        id="companyName"
                        name="companyName"
                        placeholder="Add Category..."
                        autoComplete="off"
                        value={otherValue}
                        onChange={(e) => setOtherValue(e.target.value)}
                      />
                    )}
                    <div className="btn-wrapper text-center my-2">
                      <button
                        type="submit"
                        className="btn btn-custom"
                        onClick={handleSubmitForm}
                        disabled={loading || false}
                      >
                        {loading ? "Loading..." : "Submit"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <VideoTutorialPopUp videoURL={videoURL} closeVideo={tutoralClose} />
    </>
  );
}

export default CompanyCategory;
