import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setCompanyDetails } from "../../../redux/actions/company.actions";
import { useLocation } from "react-router-dom";
import ActivitySidebar from "../../layouts/TourSidebar";
import stipeLogo from "../../../assets/images/stripe-grey.svg";
import { authCall } from "../../../services";
import webServices from "../../../services/webServices";
import AppConstants from "../../../appConstants";

function ExtraVariations({ handleSubmit, loading, stepValue, setStep }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const { pathname } = location;
  const companyDetails = useSelector((state) => state.companyDetails);
  const [variationLoader, setVariationLoader] = useState(false);
  const [activityLoader, setActivityLoader] = useState(false);
  const [companytype] = useState(companyDetails?.company_type || "");

  const showPayment = [AppConstants.routes.tour.createCompany].includes(
    pathname
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleClickAddVariations = async () => {
    setVariationLoader(true);
    const obj = {
      userId: companyDetails.user_id,
      variationParentId: companyDetails?.variation_parent_id
        ? companyDetails?.variation_parent_id
        : companyDetails.id,
      activityCategory: companyDetails.activity_category,
      bookingCalendar: companyDetails.booking_calendar,
      companyPhoneNumber: companyDetails.company_phone,
      companyName: companyDetails.company_name,
      companyType: companyDetails.company_type,
      duration: companyDetails.duration,
      durationHours: companyDetails.duration_hours,
      durationMinutes: companyDetails.duration_minutes,
      exclusion: companyDetails.highlight_excludes,
      highlight: companyDetails.highlight_includes,
      intensity: companyDetails.intensity,
      address: companyDetails.location,
      locationDescription: companyDetails.location_description,
      overview: companyDetails.overview,
      profileLavel: 3,
      whatToBring: companyDetails.what_to_bring,
      oldMenu: companyDetails.menu,
      oldProfileImage: companyDetails.profile_photo,
      oldBannerImages: companyDetails.banners,
    };

    const resp = await webServices.createTour(obj);
    if (resp?.type === "success") {
      setVariationLoader(false);
      dispatch(setCompanyDetails(resp?.payload));
      setStep(3);
    }
  };

  const handleClickDone = async () => {
    setActivityLoader(true);
    const obj = {
      userId: companyDetails.user_id,
      bookingCalendar: companyDetails.booking_calendar,
      profileLavel: 1,
    };

    const resp = await webServices.createTour(obj);
    if (resp?.type === "success") {
      setActivityLoader(false);
      dispatch(setCompanyDetails(resp?.payload));
      setStep(1);
    }
  };

  const handleSubmitForm = async () => {
    const data = {
      profileLavel: stepValue,
    };
    handleSubmit(data, stepValue);
  };

  return (
    <>
      <div className="company-payment-page py-4">
        <div className="container">
          <div className="row">
            <div className="col-sm-4 col-lg-3 mb-4">
              <ActivitySidebar setStep={setStep} />
            </div>
            <div className="col-sm-8 col-lg-9">
              <div className="common-info-card">
                <div className="heading-title">
                  {companytype === "MultipleVariations"
                    ? "Add Extra Activities and Variations"
                    : "Add Extra Activities"}
                </div>
                <div className="details-wrapper">
                  {companytype === "MultipleVariations" ? (
                    <>
                      <div className="sub-title mb-3">
                        Before continuing to the payment section, please make
                        sure you have added all your activities, and variations.
                      </div>
                      <ol>
                        <li>
                          If your activity has different options, for example an
                          Escape Room with different rooms, or a Jetboat Tour
                          with different tour lengths, please click the{" "}
                          <strong>
                            <i>"Add Another Variation"</i>
                          </strong>{" "}
                          button to add all your variations. This will link all
                          your variations together in a single listing and allow
                          your customers to choose the option that is best for
                          them.
                        </li>
                        <li>
                          If you run different activities that are not related,
                          then click the{" "}
                          <strong>
                            <i>"Add Another Activity"</i>
                          </strong>{" "}
                          listing, and add all your activities.
                        </li>
                      </ol>
                      <div className="info-text">
                        Once you’re done, click the{" "}
                        <strong>
                          <i>"Proceed To Payment"</i>
                        </strong>
                        .
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="sub-title mb-3">
                        Before continuing to the payment section, please make
                        sure you have added all your activities.
                      </div>
                      <div className="info-text">
                        To add another activity please click the{" "}
                        <strong>
                          <i>"Add Another Activity"</i>
                        </strong>{" "}
                        button, and enter the new activities details.
                      </div>
                      <div className="info-text">
                        Once you have added all your activities click the{" "}
                        <strong>
                          <i>"Proceed To Payment"</i>
                        </strong>{" "}
                        option.
                      </div>
                    </>
                  )}

                  <div className="d-flex flex-wrap justify-content-center gap-2 gap-md-4 pt-4">
                    {companytype === "MultipleVariations" && (
                      <button
                        type="submit"
                        className="btn btn-custom"
                        style={{ fontSize: 22 }}
                        onClick={handleClickAddVariations}
                        disabled={variationLoader || false}
                      >
                        {variationLoader
                          ? "Loading..."
                          : "Add Another Variation"}
                      </button>
                    )}
                    <button
                      type="submit"
                      className="btn btn-custom"
                      style={{ fontSize: 22 }}
                      onClick={handleClickDone}
                      disabled={activityLoader || false}
                    >
                      {activityLoader ? "Loading..." : "Add Another Activity"}
                    </button>
                    {showPayment && (
                      <button
                        type="submit"
                        className="btn btn-custom"
                        onClick={handleSubmitForm}
                        disabled={loading || false}
                      >
                        {loading ? "Loading..." : "Proceed to Payment"}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ExtraVariations;
