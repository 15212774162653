import React, { useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import NightLifeSidebar from "../layouts/NightLifeSidebar";
import AppConstants from "../../appConstants";
import webServices from "../../services/webServices";

const CATEGORIES = [
  {
    name: "arcadeBar",
    label: "Arcade Bar",
  },
  {
    name: "bearGarden",
    label: "Bear Garden",
  },
  {
    name: "cigarBar",
    label: "Cigar Bar",
  },
  {
    name: "cocktailBars",
    label: "Cocktail Bars",
  },
  {
    name: "comedyClubs",
    label: "Comedy Clubs",
  },
  {
    name: "danceBar",
    label: "Dance Bar",
  },
  {
    name: "diveBar",
    label: "Dive Bar",
  },
  {
    name: "duelingInstruments",
    label: "Dueling Instruments",
  },
  {
    name: "hotelBar",
    label: "Hotel Bar",
  },
  {
    name: "irisBars",
    label: "Iris Bars",
  },
  {
    name: "karaokeBar",
    label: "Karaoke Bar",
  },
  {
    name: "liveMusicVenues",
    label: "Live Music Venues",
  },
  {
    name: "nightclub",
    label: "Nightclub",
  },
  {
    name: "nostalgiaBars",
    label: "Nostalgia Bars",
  },
  {
    name: "pubOrTaven",
    label: "Pub or Taven",
  },
  {
    name: "rsl",
    label: "RSL",
  },
  {
    name: "speakeasies",
    label: "Speakeasies",
  },
  {
    name: "specialityBar",
    label: "Speciality Bar",
  },
  {
    name: "sportsBar",
    label: "Sports Bar",
  },
  {
    name: "taven",
    label: "Taven",
  },
  {
    name: "tikiBars",
    label: "Tiki Bars",
  },
  {
    name: "wineBar",
    label: "Wine Bar",
  },
];

function Category() {
  const navigate = useNavigate();
  const [selectedCategories, setSelectedCategories] = useState("");
  const [loading, setLoading] = useState(false);

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    const selectedCount =
      Object.values(selectedCategories).filter(Boolean).length;
    if (selectedCount > 2 && checked) {
      toast.dismiss();
      toast.error("You can select most three categories.");
      return;
    }
    setSelectedCategories((prevData) => {
      if (!checked) {
        const newData = { ...prevData };
        delete newData[name];
        return newData;
      }
      return { ...prevData, [name]: checked };
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const selectedCount =
      Object.values(selectedCategories).filter(Boolean).length;

    if (selectedCount < 3) {
      toast.dismiss();
      toast.error("Please select at least three categories.");
    } else {
      setLoading(true);
      const resp = await webServices.createNightLife({
        categories: selectedCategories,
      });
      if (resp.type === "success") {
        setLoading(false);
        localStorage.setItem("nightLifeDetails", JSON.stringify(resp.payload));
        navigate(AppConstants.routes.nightLife.vanueDetails);
      }
    }
  };

  return (
    <>
      <div className="category-venue-page py-4">
        <div className="container">
          <div className="row">
            <div className="col-md-4 col-lg-3 mb-4">
              <NightLifeSidebar />
            </div>
            <div className="col-md-8 col-lg-9">
              <div className="common-info-card">
                <div className="heading-title">
                  Which Category Best Represents Your Venue?
                </div>
                <div className="details-wrapper">
                  <div className="sub-title mb-4">
                    Please select the three most relevant categories from the
                    list below:
                  </div>
                  <div action="">
                    <div className="row row-cols-sm-2 row-cols-lg-3">
                      {CATEGORIES.map((cat) => (
                        <div className="form-group d-flex align-items-center gap-3">
                          <input
                            type="checkbox"
                            id={cat.name}
                            name={cat.name}
                            checked={selectedCategories[cat.name] || false}
                            onChange={handleCheckboxChange}
                          />
                          <label
                            className="form-check-label m-0"
                            for={cat.name}
                          >
                            {cat.label}
                          </label>
                        </div>
                      ))}
                    </div>
                    <div className="btn-wrapper text-start mt-2 mb-lg-2">
                      <a href="venue-details">
                        <button
                          type="submit"
                          className="btn btn-custom"
                          onClick={handleSubmit}
                          disabled={loading || false}
                        >
                          {loading ? "SUBMITTING..." : "SUBMIT"}
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Category;
