import React from "react";
import RestaurantSidebar from "../layouts/RestaurantSidebar";
import RestaurantDetailPage from "../common/RestaurantDetailPage";

function RestaurantDetails({
  companyDetails,
  handleSubmit,
  loading,
  loadingDetails,
  setStep,
}) {
  const buttons = [
    { id: 1, label: "Submit", type: "breakFast" },
    // { id: 2, label: "Add Lunch Menu", type: "lunch" },
    // { id: 3, label: "Add Dinner Menu", type: "dinner" },
  ];

  const handleSubmitForm = async (data, val) => {
    data.profileLavel =
      companyDetails?.profile_lavel > 3 ? companyDetails?.profile_lavel : 3;
    handleSubmit(data, 4);
    // if (val === "breakFast") {
    //   handleSubmit(data, 4);
    // }
    // else if (val === "lunch") {
    //   handleSubmit(data, 5);
    // } else {
    //   handleSubmit(data, 6);
    // }
  };

  return (
    <>
      <RestaurantDetailPage
        onSubmit={handleSubmitForm}
        loading={loading}
        details={companyDetails}
        heading="Please Enter Your Restaurant Details"
        Sidebar={RestaurantSidebar}
        buttons={buttons}
        loadingDetails={loadingDetails}
        setStep={setStep}
      />
    </>
  );
}

export default RestaurantDetails;
