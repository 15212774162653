import authService from "../../services/authServices";
import { UserConstants } from "../constants";

export const initialState = {
  current: authService.getUserDetails(),
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case UserConstants.SET_USER_DETAILS:
      return { ...state, current: action.payload };
    case UserConstants.UNSET_USER_DETAILS:
      return { ...state, current: null };
    default:
      return { ...state };
  }
};

export default userReducer;
