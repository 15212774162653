import React from "react";
import Header from "../../homeLayout/Header";
import { Link } from "react-router-dom";
// import SampleVideo from "../../../../assets/videos/Sample.mp4";
// import frame from "../../../../../src/assets/images/icons/Frame.svg";
import "./VideoPage.scss";

const VideoPage = () => {
  return (
    <div>
      <Header />
      <div className="videoPage_wrapper container">
        <div className="content-container">
          <div
            className="heading"
            style={{
              fontFamily: "Arial Rounded MT Std",
              fontSize: "25px",
              marginTop: "150px",
            }}
          >
            HOW TO CONNECT YOUR STRIPE ACCOUNT :
          </div>
          <div className="video-container2 mt-4">
            <video width="100%" height="100%" controls>
              <source src="https://touch-it.com.au/tutorial_videos/dashboard.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>

          <Link to="/login" style={{ textDecoration: "none" }}>
            <button className="center-button2">CLICK TO ACCESS YOUR ACCOUNT</button>
          </Link>
        </div>
      </div>
      <div
        className="footer-text text-center mt-3 mb-5"
        style={{ fontSize: "16px", color: "#323232" }}
      >
        copyright@2010-2024 Touch-iT.com All rights Reserved
      </div>
    </div>
  );
};

export default VideoPage;
