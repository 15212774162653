// UserLayout.js
import React from "react";
import HeaderGuest from "./HeaderGuest";
import FooterGuest from "./FooterGuest";

function GuestLayout({ children }) {
  return (
    <div>
      <HeaderGuest />
      <div className="main-content">{children}</div>
      <FooterGuest />
    </div>
  );
}

export default GuestLayout;
