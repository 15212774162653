import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import RestaurantSidebar from "../layouts/RestaurantSidebar";
import Payment from "../../Stripe/Payment";
import AppConstants from "../../appConstants";
import webServices from "../../services/webServices";
import { authCall } from "../../services";

function RestaurantPayment({
  companyDetails,
  handleSubmit,
  loading,
  loadingDetails,
  current,
  setStep
}) {
  const navigate = useNavigate();
  const [perHotel, setPerHotel] = useState(0);
  const [maxPerMonth, setMaxPerMonth] = useState(0);
  const [onlineReservationPrice, setOnlineReservationPrice] = useState(0);
  const [isShowOnlineReservation] = useState(
    companyDetails?.desired_services?.onlineReservations || false
  );
  const [totalSubscribeAmount, setTotalSubscribeAmount] = useState(0);
  const [hotelPlan, setHotelPlan] = useState(0);
  const [payment] = useState(1);

  useEffect(() => {
    async function fetchData() {
      const resp = await webServices.getPlans({
        type: "restaurant",
      });
      if (resp?.type === "success") {
        setHotelPlan(resp?.payload?.["Gold Coast"].amount || 0);
        setPerHotel(resp?.payload?.["Per Hotel"].amount || 0);
        setMaxPerMonth(resp?.payload?.["Max Hotel Price"].amount || 0);
        setOnlineReservationPrice(
          resp?.payload?.["Online Reservation"].amount || 0
        );
      }
    }
    fetchData();
  }, []);

  const handleSubmitSubmit = async () => {
    navigate(AppConstants.routes.admin.dashboard);
  };

  // const subscriptions = () => {
  //   const hotelTotalAmount = companyDetails?.assigned_total_hotels * perHotel;
  //   const onlineReservationAmount = onlineReservationPrice;
  //   const totalAmount = hotelTotalAmount + onlineReservationAmount;
  // }

  const handleCallBack = async (resp) => {
    await webServices.createRestaurant({
      restaurantId: companyDetails?.id,
      profileLavel: 7,
    });

    // save transactions in our database
    await authCall("transactions", {
      userId: current?.id,
      paymentType: "Subscription",
      amount: resp?.amount / 100,
      paymentDetails: JSON.stringify(resp),
    });

    await authCall("createSubscription", {
      email: current?.email,
      name: current?.user_name,
      payment_method: resp?.payment_method,
      priceId: "price_1PVVSfA3kborvutgeOAPZgW3"
    });
  };

  return (
    <>
      <div className="company-payment-page py-4">
        <div className="container">
          <div className="row">
            <div className="col-sm-4 col-lg-3 mb-4">
              <RestaurantSidebar
                companyDetails={companyDetails}
                loadingDetails={loadingDetails}
                setStep={setStep}
              />
            </div>
            <div className="col-sm-8 col-lg-9">
              <div className="common-info-card">
                <div className="heading-title">Restaurant Billing</div>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="details-wrapper">
                      <div
                        style={{
                          color: "#00ffe7",
                          fontSize: 25,
                          fontWeight: 500,
                        }}
                      >
                        Get Listed For 30 Day For $1
                      </div>
                      <div className="fs-12">
                        Then pay only ${perHotel} per hotel per month,
                      </div>
                      <div className="fs-12">
                        Never pay more than ${maxPerMonth} per month.
                      </div>
                      <i>
                        <ul className="fs-12 mt-3">
                          <li>One Hotel - ${perHotel} per month</li>
                          <li>Five Hotels - ${perHotel * 5} per month</li>
                          <li>Thirteen Hotels - ${maxPerMonth} per month</li>
                          <li>
                            One Hundred Hotels - Still Only ${maxPerMonth} per
                            month!
                          </li>
                        </ul>
                      </i>
                      {isShowOnlineReservation && (
                        <div className="fs-14 mb-2">
                          Plus, SevenRoom Online Reservations $
                          {onlineReservationPrice} per month
                        </div>
                      )}
                      <div style={{ fontWeight: 700, fontSize: 12.8 }}>
                        We currently have {hotelPlan} hotels so current
                        subsciption is ${hotelPlan * perHotel} per month
                      </div>

                      {isShowOnlineReservation && (
                        <div
                          className="fs-12 text-right"
                          style={{ textAlign: "right" }}
                        >
                          <i>(Including SevenRoom integration)</i>
                        </div>
                      )}

                      <div className="fs-12 mt-4">
                        If you're not completely stisfied, cancel any time, no
                        questions asked.
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 details-wrapper">
                    <Payment
                      payment={payment}
                      userDetails={current}
                      parentCallBack={handleCallBack}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default RestaurantPayment;
