import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Select from "react-select";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { Badge, Row, Col, Button } from "react-bootstrap";
import { PencilSquare, Trash, Plus } from "react-bootstrap-icons";
import AdminSidebar from "../layouts/AdminSidebar";
import { authCall } from "../../services";
import AppConstants from "../../appConstants";

function MachineList() {
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;
  const { current } = useSelector((state) => state.user);
  const isArchivedList =
    AppConstants.routes.admin.archivedMachine.includes(pathname);
  const [machinesList, setMachinesList] = useState([]);
  const [filterMachinesList, setFilterMachinesList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingStatus, setLoadingStatus] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);
  const [licenceUserList, setLicenceUserList] = useState([]);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const resp = await authCall("getMachines", {
        archivedStatus: isArchivedList ? 1 : 0,
        licenceId: current?.licence_id || ""
      });
      if (resp?.type === "success") {
        setMachinesList(resp?.payload);
        setFilterMachinesList(resp?.payload);
        setLoading(false);
      }
    }
    fetchData();
  }, [isArchivedList]);

  useEffect(() => {
    async function fetchData() {
      const resp = await authCall("getLicenceUsers");
      if (resp?.type === "success") {
        setLicenceUserList(resp?.payload);
      }
    }
    if (!current?.licence_id) {
      fetchData();
    }
  }, []);

  const handleChangeStatus = async (id, status) => {
    setLoadingStatus(`loading${id}`);
    const resp = await authCall("machineArchiveStatus", { id, status });
    if (resp?.type === "success") {
      const updatedItems = filterMachinesList.filter((item) => item.id !== id);
      setFilterMachinesList(updatedItems);
      setLoadingStatus("");
    }
  };

  const handleEdit = (details) => {
    navigate(AppConstants.routes.admin.updateMachine, { state: { details } });
  };

  const handleDelete = (el) => {
    toast.warning(
      <div>
        <p>Are you sure you want to delete?</p>
        <Button
          variant="success"
          className="btn-sm"
          onClick={() => deleteItem(el)}
        >
          Yes
        </Button>
        <Button
          variant="danger"
          className="btn-sm"
          style={{ marginLeft: "2px" }}
          onClick={closeToast}
        >
          No
        </Button>
      </div>,
      {
        position: "top-center",
        autoClose: false,
        closeOnClick: false,
        draggable: false,
        closeButton: false,
      }
    );
  };

  const deleteItem = async (el) => {
    const resp = await authCall("deleteMachine", {
      machineId: el.id,
    });
    if (resp?.type === "success") {
      closeToast();
      const updatedItems = filterMachinesList.filter((item) => item.id !== el.id);
      setFilterMachinesList(updatedItems);
      toast.success("Deleted successfully.");
    }
  };

  const closeToast = () => {
    toast.dismiss();
  };

  const handleAdd = () => {
    navigate(AppConstants.routes.admin.addMachine);
  };

  const getListByFilter = (list, id) => {
    const filteredData = list.filter((item) => item.licence_id === id);
    return filteredData;
  };

  const handleChangeLicence = (selected) => {
    setSelectedOption(selected);
    setFilterMachinesList(getListByFilter(machinesList, selected?.value));
  };

  return (
    <>
      {/* {console.log(current?.licence_id)} */}
      <div className="update-machine-page common-table-design py-4">
        <div className="container custom-container">
          <div className="row">
            <div className="col-sm-4 col-lg-3 mb-4">
              <AdminSidebar />
            </div>
            <div className="col-sm-8 col-lg-9">
              <div className="common-info-card">
                <div className="heading-title">
                  {isArchivedList
                    ? "Archived Kiosk Machine List"
                    : "Kiosk Machine List"}
                  :
                </div>
                <div className="details-wrapper py-lg-4">
                  <Row className="align-items-center mb-4">
                    <Col xs={4}>
                      {!current?.licence_id && (
                        <>
                          <span className="fs-sm">Filter by Licencee ID:</span>
                          <Select
                            className="text-body mt-1"
                            placeholder="Select Licence"
                            value={selectedOption}
                            onChange={handleChangeLicence}
                            options={licenceUserList}
                          />
                        </>
                      )}
                    </Col>

                    {current?.licence_id && (
                      <Col xs={8} className="text-end">
                        <Button
                          className="btn btn-custom btn-custom-sm fs-16"
                          onClick={handleAdd}
                        >
                          <Plus />
                          Add Kiosk
                        </Button>
                      </Col>
                    )}
                  </Row>
                  <div className="table-responsive">
                    <table className="table table-borderless table-custom m-0">
                      <thead>
                        <tr>
                          <th scope="col">Id:</th>
                          {!current?.licence_id && (
                            <th scope="col">Licencee ID:</th>
                          )}
                          <th scope="col">Stripe Serial ID:</th>
                          <th scope="col">Kiosk Serial ID:</th>
                          <th scope="col">Venue Name:</th>
                          <th scope="col">Name:</th>
                          <th scope="col">Phone:</th>
                          <th scope="col">Email:</th>
                          <th scope="col">Action:</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!loading &&
                          (filterMachinesList.length > 0 ? (
                            filterMachinesList.map((el, index) => (
                              <tr key={index}>
                                <td scope="row">#{el.id}</td>
                                {!current?.licence_id && (
                                  <td>#{el.licence_id}</td>
                                )}
                                <td>{el.stripe_device_id}</td>
                                <td>{el.kiosk_serial_id}</td>
                                <td>{el.vanue_name}</td>
                                <td>{el.contact_name}</td>
                                <td>{el.contact_number}</td>
                                <td>{el.contact_email}</td>
                                <td>
                                  <div className="icon-container">
                                    <Badge
                                      bg={isArchivedList ? "success" : "danger"}
                                      className="cursor-pointer"
                                      onClick={() =>
                                        handleChangeStatus(
                                          el.id,
                                          isArchivedList ? 0 : 1
                                        )
                                      }
                                      title={
                                        isArchivedList
                                          ? "Click to Remove Archive"
                                          : "Click to Archive"
                                      }
                                    >
                                      {loadingStatus === `loading${el.id}`
                                        ? "Loading..."
                                        : isArchivedList
                                        ? "Make Live"
                                        : "Archive"}
                                    </Badge>

                                    <PencilSquare
                                      onClick={() => handleEdit(el)}
                                      className="cursor-pointer"
                                      style={{
                                        color: "#ddb12e",
                                      }}
                                    />
                                    <Trash
                                      onClick={() => handleDelete(el)}
                                      className="cursor-pointer"
                                      style={{
                                        color: "red",
                                      }}
                                    />
                                  </div>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan={10} className="text-center">
                                No record found
                              </td>
                            </tr>
                          ))}
                        {loading && (
                          <tr>
                            <td colSpan={10} className="text-center">
                              Loading...
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MachineList;
